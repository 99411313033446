<template>
  <div>
    <vue-loader :show="loading.state" :message="loading.message" :overlay="true"></vue-loader>

    <template v-if="!finished">
      <div v-if="event" class="row">
        <div class="page-title">
          {{ event.name }}
        </div>
        <h3 v-if="event.starts_at" class="event-h3-datetime">
          {{ returnDate(event.starts_at,'short') }}
          {{ (event.ends_at && (event.ends_at !== event.starts_at) ? 'to ' + returnDate(event.ends_at,'short') : '' ) }}
        </h3>

        <div class="col-md-12">
          <div class="event-title-actions">
            <button v-if="configuration.closeEvent" @click.prevent="configuration.closeEvent()" class="btn btn-primary">{{ 'Back to Events' | translate }}</button>
            <a v-else href="/event" class="btn btn-primary">{{ 'Back to Events' | translate }}</a>
          </div>
        </div>

        <div class="col-md-12"><hr></div>
      </div>

      <template v-if="event && isRegistrationOpen">
        <event-contact-form v-if="event" :contact-form="Contact" :configuration="configuration" />
        <event-cart-form v-if="event" :cart-form="Cart" :event="event" :actions="{submit:submit,canProceed:canProceed}" :member="member" />
      </template>
    </template>

    <div v-if="finished && event" class="finished">
      <p class="thank-you">{{ 'Thank you for registering for the'}}</p>
      <h1>{{ event.name }}</h1>
      <p class="date-location">
        {{ returnDate(event.starts_at,'short') }}
        {{ (event.ends_at && (event.ends_at !== event.starts_at) ? 'to ' + returnDate(event.ends_at,'short') : '' ) }}
        <br>
        {{ event.venue.name }}
      </p>
      <div class="actions">
        <button v-if="configuration.closeEvent" @click.prevent="configuration.closeEvent()" class="btn btn-primary">{{ 'Back to Events' | translate }}</button>
        <a v-else href="/event" class="btn btn-primary">{{ 'Back to Events' | translate }}</a>
      </div>
    </div>
  </div>
</template>
<script>
/**
<div v-if="event" class="row">
  <div class="col-md-12 text-center">
    <button @click.prevent="submit" class="btn btn-primary">{{ 'Submit Registration' | translate }}</button>
  </div>
</div>
*/
import Contact from './classes/contact.js' // Call the framework well be using to organize all the sub-components
import Cart from './classes/cart.js' // Call the framework well be using to organize all the sub-components
export default{
  props: ['configuration'],
  data(){
      return{
        Contact: new Contact(),
        Cart: new Cart(),
        event: null,
        loading: {
          state: false,
          message: 'Loading Event',
        },
        typeId: null,
        member: false,
        finished: false
      }
  },
  created() {

  },
  mounted(){
    this.fetchEvent();
  },
  computed:{
    canProceed: function(){
      let vm = this;
      let proceed = true;

      if(!vm.configuration.member){
        let ci = vm.Contact.form;
        if( !ci.city.value || !ci.email.value || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(ci.email.value))
                || !ci.firstName.value || !ci.lastName.value
                || !ci.phone.value || !(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(ci.phone.value))
                || !ci.email.value || !ci.state.value || !ci.street1.value || !ci.zip.value){
          proceed = false;
        }
      }

      if(!vm.Cart.paymentMethod.billing.value){
        let bi = vm.Cart.paymentMethod.billing.address;
        if( !bi.city.value || !bi.phone.value || !(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(bi.phone.value)) || !bi.state.value || !bi.street1.value || !bi.zip.value){
          proceed = false;
        }
      }

      if(vm.Cart && vm.Cart.paymentMethod && vm.Cart.paymentMethod.value){
        if(vm.Cart.paymentMethod.value.id == 1){
          if(!vm.Cart.paymentMethod.stripe.cardName){
            proceed = false;
          }
        }
      }

      return proceed;
    },
    /**
    * is Registration open?
    */
    isRegistrationOpen: function(){
      let vm = this;
      return (Moment().isSameOrBefore(vm.registration_closes_at) && !vm.event.is_sold_out ? true : false);
    },
    displayYearMonth(){
      return ( Moment(this.event.starts_at).isValid() ? Moment(this.event.starts_at).format('MMM YYYY') : '');
    },
    displayDay(){
      return ( Moment(this.event.starts_at).isValid() ? Moment(this.event.starts_at).format('Do') : '')
    },
    displayEventStart(){
      return ( Moment(this.event.starts_at).isValid() ? Moment(this.event.starts_at).format('MMM Do YYYY - h:mm a') : '');
    },
    displayEventEnd(){
      return ( Moment(this.event.ends_at).isValid() ? Moment(this.event.ends_at).format('MMM Do YYYY - h:mm a ') : '');
    },
    displayPrice(){
      let vm = this;
      let thisPrice = null;

      if( vm.event.registration_types && (vm.event.registration_types.length > 0) ){
        if( vm.typeId ){
          let thisType = vm.event.registration_types.find(function (obj) { return obj.id == vm.typeId });

          //console.log(thisType);

          if( thisType && thisType.pivot ){
            thisPrice = parseFloat(thisType.pivot.price);
          }
        }else{
          let thisType = vm.event.registration_types[0];

          if( thisType && thisType.pivot ){
            thisPrice = parseFloat(thisType.pivot.price);
          }
        }
        //Do something
      }

      return ( thisPrice > 0 ? '$' + thisPrice.toFixed(2).replace(/[^\d\.]/g, "") : 'FREE' );
    }
  },
  watch:{

  },
  methods:{
    scrollToTop(){
      $('html,body').stop().animate({
        scrollTop: 0
      }, 'slow', 'swing');
    },
    /**
    * Get Event
    */
    fetchEvent: _.throttle( function (eventId) {
      let vm = this

      vm.$set(vm.loading,'state', true);

      // Get Actual data via axios
      vm.axios.get('/event/show-ajax/' + vm.configuration.eventId)
      .then( response => {
        //console.log(response.data);

        vm.event = response.data;
        vm.scrollToTop();
        vm.$set(vm.loading,'state', false);
        vm.initializeCart();
      }).catch( error => {
        console.log('Error')
        vm.$set(vm.loading,'state', false);
        // Preform Error Function
      });
    },400 ),
    initializeCart(){
      let vm = this;

      /**
      * Initilize type
      */
      if(vm.configuration.member){
        vm.member = true;
      }

      vm.typeId = vm.event.registration_types[0].id;
    },

    handdleCartAddons(addonId){
      let vm = this;

      let key = vm.event.addons.indexOf( vm.event.addons.find(function (obj) { return obj.id == addonId }) )

      if(vm.event.addons[key].inCart){
        vm.$set(vm.event.addons[key], 'inCart', false);
      }else{
        vm.$set(vm.event.addons[key], 'inCart', true);
      }
    },

    returnDate(thisDate,calFormat){
      let vm = this;

      if( !calFormat ){
        return ( Moment(thisDate).isValid() ? Moment(thisDate).format('MMM Do YYYY - h:mm a') : '');
      }else if(calFormat == 'short'){
        return ( Moment(thisDate).isValid() ? Moment(thisDate).format('MMM Do, YYYY') : '');
      }else{
        const month = ( Moment(thisDate).isValid() ? Moment(thisDate).format('MMM YYYY') : '');
        const day = ( Moment(thisDate).isValid() ? Moment(thisDate).format('Do') : '');

        let calItem = `<div class="cal-item"><div class="cal-item-header">${month}</div><div class="cal-item-body">${day}</div></div>`;

        return calItem;
      }
    },

    /**
    * Submit Form
    */
    submit(){
      let vm = this;

      vm.$set(vm.loading,'state', true);

      let data = {
        eventId: vm.event.id,
        eventRegistrationTypeId: vm.Cart.type.value.id,
        eventPriceId: vm.Cart.price.value,
        selectedAddonIds: [],
        paymentType: vm.Cart.paymentMethod.value.id,
      }

      // User and Member Id's
      if(vm.configuration.userId){
          vm.$set(data,'userId',vm.configuration.userId);
      }
      if(vm.configuration.member){
          vm.$set(data,'memberId',vm.configuration.member.id);
      }

      if(vm.Cart.type.value.id == 3){
        vm.$set(data,'studentNumber',vm.Cart.student.id);
      }

      //Contact
      // Does member id exist
      if(!vm.configuration.member && !vm.configuration.member.id){
        vm.$set(data,'firstName',vm.Contact.form.firstName.value);
        vm.$set(data,'lastName',vm.Contact.form.lastName.value);
        vm.$set(data,'phoneNumber',vm.Contact.form.phone.value);
        vm.$set(data,'smsNumber',vm.Contact.form.phone.value);
        vm.$set(data,'email',vm.Contact.form.email.value);
        vm.$set(data,'alertBy',vm.Contact.method.value);
        vm.$set(data,'street1',vm.Contact.form.street1.value);
        vm.$set(data,'street2',null);
        vm.$set(data,'city',vm.Contact.form.city.value);
        vm.$set(data,'state',vm.Contact.form.state.value);
        vm.$set(data,'zip',vm.Contact.form.zip.value);
      }

      // Addons
      for (var i=0; i < vm.event.addons.length; i++) {
        const addon = vm.event.addons[i];

        if( addon.inCart && addon.registration_types && (addon.registration_types.length > 0) ){
          data.selectedAddonIds.push(addon.id);
        }
      }

      // Payment Method
      if(vm.Cart.paymentMethod.value.id == 1){
        vm.$set(data,'cardName',vm.Cart.paymentMethod.stripe.cardName);
        vm.$set(data,'stripeToken',vm.Cart.paymentMethod.stripe.token);
      }

      vm.$set(data,'billingUseContact',vm.Cart.paymentMethod.billing.value);
      // If false send billing form
      if(!vm.Cart.paymentMethod.billing.value){
        vm.$set(data,'billingPhoneNumber',vm.Cart.paymentMethod.billing.address.phone.value);
        vm.$set(data,'billingStreet1',vm.Cart.paymentMethod.billing.address.street1.value);
        vm.$set(data,'billingCity',vm.Cart.paymentMethod.billing.address.city.value);
        vm.$set(data,'billingState',vm.Cart.paymentMethod.billing.address.state.value);
        vm.$set(data,'billingZip',vm.Cart.paymentMethod.billing.address.zip.value);
      }

      //console.log(data);

      vm.axios.post('/event-registration/store', data)
      .then( response => {
        //window.location.replace('/event');
        //vm.$set(vm.submitLoader,'isloading', false);
        vm.scrollToTop();
        vm.finished = true;
        vm.$set(vm.loading,'state', false);
        //console.log(`%c Submit`,'color: green')
      }).catch( error => {
        vm.$set(vm.loading,'state', false);
        console.log(`%cFailure`,'color: red');
        vm.scrollToTop();
      });
    }
  },
  components:{
    'vue-loader':         require('@vuejs/components/loader/loader.vue').default,
    'event-addon':        require('./addon.vue').default,
    'event-contact-form': require('./form-components/contact.vue').default,
    'event-cart-form':    require('./form-components/cart.vue').default,
  }
}
</script>

<style lang="scss">
$break-small: 991px;
$break-medium: 992px;
.content-wrapper{
  @media screen and (max-width: $break-small) {
    padding: 30px 0;
  }
}
.full-label{
  width: 100%;
}
.finished {
  padding-top: 5em;
  text-align: center;
  .thank-you,h1,.date-location,.actions{
    text-align: center;
  }
  .thank-you{
    font-size: 2em;
  }
  h1 {
    margin-bottom: 0.5em;
    font-size: 4em;
  }
  .date-location{
    font-weight: bold;
    margin-bottom: 2em;
    font-size: 1.5em;
  }
}
.event-h3-datetime{
  text-align: center;
  text-transform: none;
}
.event-title-actions{
  text-align: center;
}
.standalone-label{
  padding-top: 0.7em;
}
.radio-inline{
  margin-right: 1em;
}
.cal-item{
  width: 100px;
  border: 1px solid #ddd;
  margin-top: 20px;
  margin-right: 1em;
  .cal-item-header {
    text-align: center;
    padding: 5px;
    color: #fff;
    background-color: #4bbca7;
  }
  .cal-item-body {
    text-align: center;
    padding: 10px 2px;
    font-size: 1.7em;
  }
}

.mb-30{
  margin-bottom: 30px;
}
</style>
