class Form {

  constructor(){
    let scaffolding = this.formScaffolding();
    return scaffolding;
  }

  formScaffolding(){
    const scaffolding = {
      event:{
        edit: false
      },
      details: {
        name: '', // var Char
        description: '', // Text
        startDate: Moment(new Date()).set({ 'hour' : '01', 'minute'  : '00' }).format(),
        endDate: Moment(new Date()).set({ 'hour' : '01', 'minute'  : '00' }).format(), // Optional

        publishDate: Moment(new Date()).set({ 'hour' : '01', 'minute'  : '00' }).format(), // Date to publish event
        registerDateStart: Moment(new Date()).set({ 'hour' : '01', 'minute'  : '00' }).format(), // Should default in form to publish date
        registerDateEnd: Moment(new Date()).set({ 'hour' : '01', 'minute'  : '00' }).format(), // optional
        earlyBirdDate: Moment(new Date()).set({ 'hour' : '01', 'minute'  : '00' }).format(), // optional
        type: {
          options: [],
          value: [] // Object
        }, // possible values [use id's ? ] (Active members only, public, students, etc)
        price: {
          value: [],
          scaffolding: { // Sample object
            id: null, // id of Type
            name: '', // Name of Type
            price: 0, // base price [float]
            earlyBirdPrice: 0 // Early Bird price [float]
          },
        },
        venue: { // A object for form usage, but Id
          options: [],
          value: null // Object
        },
        ticketsTotal: 0,
        addons: [], // Array of objects tracked by ids?
        finished: false,
        preset: null,
      },
      // Venue Object Sample
      venue: {
        name: '', // Var char
        description: '', // text
        directionsUrl: '', // Link to driving directions ??? Just google maps display
        address: {
          street1: null,
          street2: null,
          city: null,
          state: null,
          country: {
            value: '',
            options: []
          },
          postalZip: null,
        }
      },
      //Price
      prices: {
        finished: false,
        scaffolding: {
          overview: {
            tempId: '',
            name: '',
            startsAt: Moment(new Date()).set({ 'hour' : '01', 'minute'  : '00' }).format(),
            endsAt: Moment(new Date()).set({ 'hour' : '01', 'minute'  : '00' }).format(), // Optional
            registrationTypeIds: [],
          },
          amount: {
            id: null, // id of Type
            name: '', // Name of Type
            price: 0, // base price [float]
            earlyBirdPrice: 0 // Early Bird price [float]
          }
        },
        value: [],
      },
      // Add-On Details (ex: Seminar/Course, Lunch, After Party, etc)
      // Able to select multiple add-ons and/or lock out one add-on if another is selected.
      // Example: User can select a single 1 day course, or select 2 ½ day courses
      addOns: {
        scaffolding: {
          overview:{
            tempId: '',
            name: '',
            type: '',
            description: '',
            startsAt: Moment(new Date()).set({ 'hour' : '01', 'minute'  : '00' }).format(),
            endsAt: Moment(new Date()).set({ 'hour' : '01', 'minute'  : '00' }).format(), // Optional
            registrationTypeIds: [],
            block: []
          },
          amount: { // Sample object
            id: null, // id of Type
            name: '', // Name of Type
            price: 0, // base price [float]
            earlyBirdPrice: 0 // Early Bird price [float]
          },
        },
        value: [],
        preset: null,
      }
    }

    return scaffolding;
  }

}

export default Form;
