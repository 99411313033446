<template>
  <div>
    <div class="row">
      <div class="form-group col-md-6">
        <label class="full-label" for="event-price">
          {{ 'Price Name' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <input v-model="price.name" name="event-price" type="text" class="form-control" placeholder="Price Name">
      </div>

      <div class="form-group col-md-6">

      </div>
    </div>

    <div class="form-group">
      <label class="full-label" for="price-start-date">
        {{ 'Start / End Date' | translate }}
        <small class="pull-right text-success">{{ '* required' | translate }}</small>
      </label>
      <div class="row">
        <div class="col-md-6">
          <date-time-combo v-model="price.startsAt" :after="details.startDate" />
        </div>
        <div class="col-md-6">
          <date-time-combo v-model="price.endsAt" :after="price.startsAt" />
        </div>
      </div>
    </div>

    <div class="price-bucket">
      <h4>{{ 'Pricing' | translate }}</h4>
      <ul class="items">
        <li v-for="(regPrice, index) in price.registrationTypeIds" :key="regPrice.tempId" :class="['item']">
          <span class="price-type-name">{{ regPrice.name }}</span>
          <span class="price-type-title">{{ 'Price' | translate }}</span>
          <div class="inline-block">
            <datamask-decimal v-model="regPrice.price" :decimal="2" :classes="'form-control'"></datamask-decimal>
          </div>
          <span class="price-type-title">{{ 'Early Bird Price' | translate }}</span>
          <div class="inline-block">
            <datamask-decimal v-model="regPrice.earlyBirdPrice" :decimal="2" :classes="'form-control inline-block'"></datamask-decimal>
          </div>
        </li>
      </ul>

    </div>

  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";

export default {
  name: 'event-price',
  props: ['price','details'],
  data () {
    return {
      editor: {
        customToolbar: [
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ],
        options: {
          bounds: '.form-group'
        }
      },
    }
  },
  created() {

  },
  mounted(){
    this.updatePriceFromType();
  },
  computed:{

  },
  watch:{

  },
  methods:{
    /**
    * Set Price Options on Type avalible Change
    * each time type is changed, price needs to be checked and rebuilt, saving any existing values
    */
    updatePriceFromType(){
      let vm = this;

      let tmpPriceArray = [];

      const scaffolding = { // Sample object
        id: null, // id of Type
        name: '', // Name of Type
        price: 0, // base price [float]
        earlyBirdPrice: 0 // Early Bird price [float]
      };

      //console.log( vm.price.registrationTypeIds );

      vm.details.type.value.forEach(function(type) {
        //console.log(`This ID: ${type.id}`);

        const tempEP = vm.price.registrationTypeIds.find(function (obj) { return obj.id == type.id }); // Temp existing Price Object

        let tempPrice = {};

        if( tempEP ){
          tempPrice = Object.assign({}, tempEP)
          //console.log(`${type.id} => ${tempEP}`);
        }else{
          tempPrice = Object.assign({}, scaffolding)
          const randomId = Math.floor(Date.now() / 1000) + '-' + Math.random().toString(36).substr(2, 9);

          vm.$set(tempPrice,'id',type.id);
          vm.$set(tempPrice,'name',type.name);
          vm.$set(tempPrice,'tempId',randomId);

          //console.log(`${type.id} => EMPTY`);
        }

        tmpPriceArray.push(tempPrice);
      });

      //console.log(tmpPriceArray);

      vm.$set(vm.price,'registrationTypeIds',[]);
      vm.price.registrationTypeIds.push.apply(vm.price.registrationTypeIds, tmpPriceArray);
    },
  },
  components:{
    VueEditor,
    DatamaskDecimal,
    'date-time-combo': require('./date-time-combo.vue').default,
  }
}
</script>
