<template>
  <div class="flex-wrapper">
    <div class="single-chart" :style="{width: displayWidth}">
      <svg viewBox="0 0 36 36" :class="['circular-chart',strokeColor]">
        <path class="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path v-if="calcPercentage > 0" class="circle"
          :stroke-dasharray="calcPercentage + ',100'"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" class="percentage">{{ calcPercentage }}%</text>
      </svg>
    </div>
  </div>
</template>

<script>
/**
* reference https://medium.com/@pppped/how-to-code-a-responsive-circular-percentage-chart-with-svg-and-css-3632f8cd7705
*/
export default{
  /**
  * color = orange,green,blue
  * width = include unit example: 30%
  * percentage = numeric only, will take precedence over total and count
  * total = total
  * count = value
  */
  props: ['config'],
  data(){
      return{
        event: null,
        loading: {
          state: false,
        }
      }
  },
  created() {

  },
  mounted(){

  },
  computed:{
    calcPercentage: function(){
      let vm = this;

      if(vm.config.percentage){
        return vm.config.percentage;
      }else if(vm.config.total && vm.config.count){
        let thisTotal = parseFloat(vm.config.total);
        let thisCount = parseFloat(vm.config.count);
        let thisPrecntage = Math.round((thisCount/thisTotal)*100);

        //console.log(`count: ${thisCount} total: ${thisTotal}`);
        //console.log(thisPrecntage);
        return (thisPrecntage);
      }else{
        return 0;
      }
    },
    displayWidth: function(){
      let vm = this;

      if(vm.config.width){
        return vm.config.width;
      }else{
        return '33%';
      }
    },
    strokeColor: function(){
      let vm = this;

      if(vm.config.color){
        switch (vm.config.color) {
          case 'orange':
            return 'orange';
            break;
          case 'blue':
            return 'blue';
            break;
          case 'green':
            return 'green';
            break;
          default:
            return 'green';
        }
      }else{
        return 'green';
      }
    },
  },
  watch:{

  },
  methods:{

  },
  components:{

  }
}
</script>

<style lang="scss">
.flex-wrapper {
  display: flex;
  flex-flow: row nowrap;
  .single-chart {
    width: 33%;
    justify-content: space-around ;
  }

  .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 250px;
  }

  .circle-bg {
    fill: none;
    stroke: #efefef;
    stroke-width: 3.8;
  }

  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .circular-chart{
    &.orange{
      .circle {
        stroke: #ff9f00;
      }
    }

    &.green{
      .circle {
        stroke: #61BBA7;
      }
    }

    &.blue{
      .circle {
        stroke: #3c9ee5;
      }
    }

    &.yellow{
      .circle {
        stroke: #fbaf3f;
      }
    }
  }

  .percentage {
    fill: #666;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
  }
}


</style>
