<template>
  <vue-loader :show="loading.isloading" :message="loading.message" :overlay="true"></vue-loader>
</template>

<style>

</style>

<script>
/**
* --- Notes ---
* Data Stored converted to form complex data.
**/
export default{
    template: '<event-edit-prep></event-edit-prep>',
    props: ['form','settings','moment'],
    data(){
        return{
          thisEvent: null,
          loading: {
            isloading: true,
            message: 'Preparing Event Data'
          }
        }
    },
    created(){

    },
    mounted(){
      this.fetchEvent()
    },
    computed:{

    },
    watch:{

    },
    methods:{
      /**
      * ----------------------------------------------------
      * Prep Event
      * ----------------------------------------------------
      **/
      fetchEvent: _.throttle( function () {
        let vm = this

        vm.$set(vm.loading,'isloading', true);

        // Get Actual data via axios
        vm.axios.get('/event/admin/show-ajax/' + vm.settings.eventId)
        .then( response => {
          vm.thisEvent = response.data;
          //console.log('Event Data');
          //console.log(response.data);

          /**
          * Begin Proccessing Components
          */
          vm.setDetails();
        }).catch( error => {
          console.log('Error Edit')
          //vm.$set(vm.form.event,'edit', false);
          //vm.$set(vm.loading,'isloading', false);
          // Preform Error Function
        });
      },400 ),
      setDetails: _.throttle( function () {
        let vm = this;

        vm.$set(vm.loading,'message','Loading Details');

        vm.$set(vm.form.details,'name',vm.thisEvent.name);
        vm.$set(vm.form.details,'description',vm.thisEvent.description);
        vm.$set(vm.form.details,'ticketsTotal',vm.thisEvent.tickets_total);
        vm.$set(vm.form.details,'publishDate',vm.thisEvent.publish_at);
        vm.$set(vm.form.details,'startDate',vm.thisEvent.starts_at);
        vm.$set(vm.form.details,'endDate',vm.thisEvent.ends_at);
        vm.$set(vm.form.details,'registerDateStart',vm.thisEvent.registration_opens_at);
        vm.$set(vm.form.details,'registerDateEnd',vm.thisEvent.registration_closes_at);
        vm.$set(vm.form.details,'earlyBirdDate',vm.thisEvent.early_bird_ends_at);

        if(vm.thisEvent.venue_id){
          vm.$set(vm.form.details.venue,'value', vm.form.details.venue.options.find(function (obj) { return obj.id == vm.thisEvent.venue_id }) );
        }

        vm.setRegistrationTypes();
      },400 ),
      /**
      * Set Details
      */
      setRegistrationTypes: _.throttle( function () {
        let vm = this;

        vm.$set(vm.loading,'message','Loading Registration Types');
        // Registration Types should be it's own var, for now we will use the first price options
        vm.thisEvent.registration_types.forEach(function(thisRegType) {
          vm.form.details.type.value.push.apply(vm.form.details.type.value, _.filter(vm.form.details.type.options, function(obj) { return thisRegType.id == obj.id } ));
        });

        vm.setPrices();
      },400 ),
      /**
      * Set Prices
      */
      setPrices: _.throttle( function () {
        let vm = this;

        vm.$set(vm.loading,'message','Loading Prices');

        vm.thisEvent.prices.forEach(function(thisPrice) {
          const randomId = Math.floor(Date.now() / 1000) + '-' + Math.random().toString(36).substr(2, 9);
          let tempPrice = Object.assign({}, vm.form.prices.scaffolding.overview);

          vm.$set(tempPrice,'id',thisPrice.id);
          vm.$set(tempPrice,'name',thisPrice.name);
          vm.$set(tempPrice,'tempId',randomId);
          vm.$set(tempPrice,'startsAt',thisPrice.starts_at);
          vm.$set(tempPrice,'endsAt',thisPrice.ends_at);

          let tempTypes = [];
          thisPrice.registration_types.forEach(function(thisRegType) {
            const randomPriceId = Math.floor(Date.now() / 1000) + '-' + Math.random().toString(36).substr(2, 9);
            let tempRegType = Object.assign({}, vm.form.prices.scaffolding.amount);

            vm.$set(tempRegType,'id',thisRegType.id);
            vm.$set(tempRegType,'name',thisRegType.name);
            vm.$set(tempRegType,'price', parseFloat(thisRegType.pivot.price) );
            vm.$set(tempRegType,'earlyBirdPrice', parseFloat(thisRegType.pivot.early_bird_price) );
            vm.$set(tempRegType,'tempId',randomPriceId);

            tempTypes.push(tempRegType);
          });

          vm.$set(tempPrice,'registrationTypeIds',tempTypes);

          vm.form.prices.value.push(tempPrice);
        });

        vm.setAddons();
      },400 ),
      /**
      * Set Addons and Exit
      */
      setAddons: _.throttle( function () {
        let vm = this;

        vm.$set(vm.loading,'message','Loading Addons');

        vm.thisEvent.addons.forEach(function(thisAddon) {
          const randomId = Math.floor(Date.now() / 1000) + '-' + Math.random().toString(36).substr(2, 9);
          let tempAddon = Object.assign({}, vm.form.addOns.scaffolding.overview);

          vm.$set(tempAddon,'id',thisAddon.id);
          vm.$set(tempAddon,'tempId',randomId);
          vm.$set(tempAddon,'block',[]);
          vm.$set(tempAddon,'tmpBlock',thisAddon.blocked_addons);
          vm.$set(tempAddon,'name',thisAddon.name);
          vm.$set(tempAddon,'type',thisAddon.type);
          vm.$set(tempAddon,'description',thisAddon.description);
          vm.$set(tempAddon,'startsAt',thisAddon.starts_at);
          vm.$set(tempAddon,'endsAt',thisAddon.ends_at);
          vm.$set(tempAddon,'ticketsTotal',thisAddon.tickets_total);

          let tempTypes = [];
          thisAddon.registration_types.forEach(function(thisRegType) {
            const randomPriceId = Math.floor(Date.now() / 1000) + '-' + Math.random().toString(36).substr(2, 9);
            let tempRegType = Object.assign({}, vm.form.addOns.scaffolding.amount);

            vm.$set(tempRegType,'id',thisRegType.id);
            vm.$set(tempRegType,'name',thisRegType.name);
            vm.$set(tempRegType,'price', parseFloat(thisRegType.pivot.price) );
            vm.$set(tempRegType,'earlyBirdPrice', parseFloat(thisRegType.pivot.early_bird_price) );
            vm.$set(tempRegType,'tempId',randomPriceId);

            tempTypes.push(tempRegType);
          });

          vm.$set(tempAddon,'registrationTypeIds',tempTypes);
          vm.form.addOns.value.push(tempAddon);
        });

        //Set Editor based blocks
        vm.$set(vm.loading,'message','Processing Blocked Addons');
        vm.form.addOns.value.forEach(function(thisAddon) {
          if(thisAddon.tmpBlock.length){ // Check for Blocked items
            thisAddon.tmpBlock.forEach(function(thisBlockedItem) {
              //console.log(`${thisAddon.name} checking blocked: ${thisBlockedItem.id}`);
              let thisBA = vm.form.addOns.value.find(function (obj) { return obj.id == thisBlockedItem.id });
              if(thisBA){
                //console.log(`[${thisAddon.id} | ${thisAddon.name}] Blocked Item ID: ${thisBA.id}, tempId: ${thisBA.tempId}, name: ${thisBA.name}`)
                thisAddon.block.push({id:thisBA.id,tempId:thisBA.tempId,name:thisBA.name});
              }
            });
          }
        });

        vm.$set(vm.form.event,'edit', false);
        vm.$set(vm.loading,'isloading', false);
      },400 ),
    },
    components:{
      'vue-loader':                 require('@vuejs/components/loader/loader.vue').default,
    }
}
</script>
