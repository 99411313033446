<template>
  <table class="table table-striped table-bordered table-condensed dataTable no-footer" style="width: 100%;">
    <thead>
      <tr>
        <th>{{ 'ID' | translate }}</th>
        <th>{{ 'Name' | translate }}</th>
        <th>{{ 'Venue' | translate }}</th>
        <th>{{ 'Ticktes' | translate }}</th>
        <th>{{ 'Total' | translate }}</th>
        <th>{{ 'Start Date' | translate }}</th>
        <th class="text-right" style="width: 300px">{{ 'Actions' | translate }}</th>
      </tr>
    </thead>

    <tbody>
        <tr v-for="event in events">
          <td>{{ event.id }}</td>
          <td>{{ event.name }}</td>
          <td>
            <template v-if="event.venue.street1">
              <address>
                <strong>{{ event.venue.name }}</strong><br>
                {{ event.venue.street1 }}{{ (event.venue.street2 ? ', '+event.venue.street2 : '') }}<br>
                {{ event.venue.city }} {{ event.venue.state }}, {{ (event.venue.country ? event.venue.country.name : '' ) }} {{ event.venue.zip }}
              </address>
            </template>
            <template v-else>
              <strong>{{ event.venue.name}}</strong><br>
              hosted online
            </template>
          </td>
          <td>
            {{ ( event.tickets_total ? event.tickets_total : 'TBD' ) }}
            <span style="margin-left: 5px;" class="badge">{{ event.tickets_sold }}</span>
          </td>
          <td>
            {{ (event.total_ticket_value ? parseFloat(event.total_ticket_value).toFixed(2) : parseFloat(0).toFixed(2)) }}
          </td>
          <td>{{ event.starts_at }}</td>
          <td class="text-right" style="width: 300px">
            <a :href="'/event/admin/show/'+event.id">{{ 'View Details' | translate }}</a> |
            <a :href="'/event/admin/edit/'+event.id">{{ 'Edit' | translate }}</a> |
            <a :href="'#'" :data-toggle="'modal'" :data-target="'#deleteEvent'+event.id">{{ 'Delete' | translate }}</a>

            <div :id="'deleteEvent'+event.id" class="modal fade" role="dialog">
              <div class="modal-dialog">

                <!-- Modal content-->
                <div class="modal-content">
                  <form :action="'/event/admin/destroy'" method="post">
                    <input type="hidden" name="_token" :value="csrf">
                    <input type="hidden" name="eventId" :value="event.id" />
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal">&times;</button>
                      <h4 class="modal-title text-left">Delete {{ event.name }}</h4>
                    </div>
                    <div class="modal-body text-left text-danger">
                      <p>Are you sure you want to delete {{ event.name }}?</p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Cancel</button>
                      <button type="submit" class="btn btn-danger">Delete</button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </td>
        </tr>
    </tbody>
  </table>
</template>

<script>
/**
* VueJS min boot-strap Wrapper
* in blade, section: after-scripts-end
* load:
* {!! Html::script('/js/datatables.js') !!}
* {!! Html::script('https://cdn.datatables.net/buttons/1.5.1/js/dataTables.buttons.min.js') !!}
* {!! Html::script('https://cdn.datatables.net/buttons/1.5.1/js/buttons.html5.min.js') !!}
* {!! Html::script('https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js') !!}
* {!! Html::script('https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.38/pdfmake.js') !!}
* {!! Html::script('https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.38/vfs_fonts.js') !!}
* {!! Html::script('https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.22.2/moment.min.js') !!}
*/
export default{
  props: ['events','filters','csrf'],
  data(){
      return{
        dataTable: null,
        dateFormat: 'YYYY-MM-DD',
        refresh: {
          state: false,
          key: null,
          id: null
        },
        total: 0
      }
  },
  created() {

  },
  mounted(){
    let vm = this;

    /**
    * Wait for external JS components to load
    */
    //window.addEventListener('load', () => {
      vm.initilizeDataTable();
    //});
    let csrf = Laravel.csrfToken;
  },
  beforeDestroy() {
    const vm = this
    if (vm.dataTable) {
      vm.dataTable.destroy(true);
    }
    vm.dataTable = null
  },
  computed:{

  },
  watch:{
    'filters.startDate': function(){
      let vm = this;
      vm.dataTable.draw();
    },
    'filters.endDate': function(){
      let vm = this;
      vm.dataTable.draw();
    },
  },
  methods:{
    /**
    * Initilize DataTables
    */
    initilizeDataTable: function(){
      let vm = this;

      // Check if We have Registrations
      if(vm.events.length){
        vm.dataTable = $(this.$el).DataTable( {
          dom: '<"row"<"inline-block col-md-7 dt-select"<"inline-block"l><"inline-block"><"inline-block"f>><"inline-block text-right col-md-5"B">><"table-responsive"t><"row"<"col-md-6"i><"col-md-6"p>>',
          buttons: [
            {
              extend: 'pdfHtml5',
              className: 'btn btn-primary' ,
              exportOptions: {
                columns: [ 0, 1, 2, 3, 4, 5 ]
              },
              messageBottom: function(){
                return 'Total: $' + vm.calcTotal();
              }
            },
            {
              extend: 'excelHtml5',
              className: 'btn btn-primary' ,
              exportOptions: {
                columns: [ 0, 1, 2, 3, 4, 5 ]
              },
              messageBottom: function(){
                return 'Total: $' + vm.calcTotal();
              }
            },
            {
              extend: 'csvHtml5',
              className: 'btn btn-primary',
              exportOptions: {
                columns: [ 0, 1, 2, 3, 4, 5 ]
              },
              messageBottom: function(){
                return 'Total: $' + vm.calcTotal();
              }
            },
          ],
          columns: [
            { title: 'ID' },
            { title: 'Name' },
            { title: 'Venue'},
            { title: 'Ticktes'},
            { title: 'Total',render: vm.formatPrice},
            { title: 'Start Date',render: vm.formatDate},
            { title: 'Actions', 'searchable': false, 'orderable': false,}
          ],
          stateSave: true,
          language: {
            search: "_INPUT_",
            searchPlaceholder: "Search"
          },
          lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]]
        });

        /**
        * Add title to buttons
        */
        $('.dt-buttons').prepend('<span class="export">Export to </span>');

        // Extend dataTables search
        $.fn.dataTable.ext.search.push(
          function(settings, data, dataIndex) {
            let min = Moment(vm.filters.startDate);
            let max = Moment(vm.filters.endDate);
            let createdAt = data[5] || 0; // Our date column in the table
            let show = {
                  after: false,
                  before: false
                }

            //console.log(`min: ${(min ? Moment(min) : '')} max: ${(max ? Moment(max) : '')} created At: ${Moment(createdAt)}`)
            if(!vm.filters.startDate && !vm.filters.endDate){
              show.after = true;
              show.before = true;
            }else{
              if(vm.filters.startDate){
                if(Moment(createdAt).isSameOrAfter(min,'day')){
                  show.after = true;
                }else{
                  show.after = false;
                }
              }else{
                show.after = true;
              }

              if(vm.filters.endDate){
                if(Moment(createdAt).isSameOrBefore(max,'day')){
                  show.before = true;
                }else{
                  show.before = false;
                }
              }else{
                show.before = true;
              }
            }

            return (show.after && show.before ? true : false);
          }
        );
      }else{
        //Do Nothing;
      }
    },
    /**
    *
    */
    calcTotal: function(){
      let vm = this;
      let total = vm.dataTable.column(4, {search:'applied'}).data().reduce( function (a,b) {
                    var x = parseFloat(a) || 0;
                    var y = parseFloat(b) || 0;
                    return parseFloat(x + y).toFixed(2);
                  });
      //vm.total = total;
      //console.log(`This Total: ${total}`);
      return total;
    },
    /**
    * Format Price
    */
    formatPrice: function(data, type, row){
      let vm = this;

      if(type === 'display'){
        return '$'+data
      }else{
        return data
      }
    },
    /**
    * Format Date
    */
    formatDate: function(data, type, row){
      let vm = this;

      if(type === 'display'){
        switch(vm.dateFormat) {
          case 'RFC1036-MH':
            return Moment(data).format("dddd, Do MMMM YYYY")
            break;
          case 'YYYY-MM-DD':
            return Moment(data).format("YYYY-MM-DD")
            break;
          default:
            return Moment(data).format("YYYY-MM-DD")
        }
      }else{
        return data
      }
    },
    dualFullDateTime(firstDate,secondDate){
      let vm = this;
      let sameDay = false;
      // Check if both dates are same day
      //console.log(`Dual Full Datetime: ${firstDate} - ${secondDate}`);
      if( Moment(firstDate).isValid() && Moment(secondDate).isValid()){
          sameDay = ( Moment(firstDate).format('MM-DD-YYYY') == Moment(secondDate).format('MM-DD-YYYY') ? true : false);
      }

      if( sameDay ){
        let thisResult = ( Moment(firstDate).isValid() ? Moment(firstDate).format('MMMM D, YYYY | h:mm a') : '') + ( Moment(secondDate).isValid() ? ' - ' + Moment(secondDate).format('h:mm a') : '');
        return thisResult;
      }else{
        let thisResult = ( Moment(firstDate).isValid() ? Moment(firstDate).format('MMMM D, YYYY') : '')
                        + ( Moment(secondDate).isValid() ? ' - ' + Moment(secondDate).format('MMMM D, YYYY') : '')
                        + '<br>'
                        + ( Moment(firstDate).isValid() ? Moment(firstDate).format('h:mm a') : '')
                        + ( Moment(secondDate).isValid() ? ' - ' + Moment(secondDate).format('h:mm a') : '');
        return thisResult;
      }
    },
  },
  components:{

  }
}
</script>

<style lang="scss">
.hide-paid{
  display: none;
}
.dt-toolbar-filter{
  margin-bottom:0;
  margin-left: 4px;
  padding: 4px 12px;
  cursor: pointer;
}
  .export.mc-export { width: auto; }
  .inline-block { display: inline-block; }
  .inline-block.pr-10 { padding-right: 10px;}
  span.export {
      float: none;
      font-weight: 800;
      padding-top: 7px;
      padding-right: 10px;
      display: inline-block;
      vertical-align: top;
      width: inherit;
  }
  label.dtexternal {
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      padding-left: 5px;
  }
  .dtexternal select {
      margin-left: 5px;
  }
  .dataTables_filter {
      float: none;
  }
  .dt-select{
    padding-top: 1em;
  }
</style>
