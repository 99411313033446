<template>
  <div>
    <vue-loader :show="submitLoader.isloading" :message="submitLoader.message" :overlay="true"></vue-loader>

    <div v-if="!loading.isloading && !Form.event.edit" class="report-builder-wrapper">
      <form method="post" @keypress.13.prevent @submit.prevent="submit(false)">

        <transition name="slide-fade">
          <event-create-details v-if="!Form.details.finished" :details="Form.details" :prices="Form.prices" :venu-scaffolding="Form.venue" />
        </transition>

        <transition name="slide-fade">
          <event-prices v-if="Form.details.finished && !Form.prices.finished" :prices="Form.prices" :details="Form.details" />
        </transition>

        <transition name="slide-fade">
          <event-create-addons v-if="Form.details.finished && Form.prices.finished" :addons="Form.addOns" :details="Form.details" :submit-function="submit" />
        </transition>

        <!-- <button type="submit" :class="['btn btn-primary']">{{ 'Submit' | translate }}</button> -->
      </form>
    </div>

    <event-edit-prep v-if="Form.event.edit" :form="Form" :settings="settings" :moment="moment" />

  </div>
</template>

<script>
import Datepicker from '@vuejs/vendor/vue-datepicker/vuejs-datepicker.min.js';
import Form from './classes/form.js' // Call the framework well be using to organize all the sub-components
import moment from 'moment'

export default{
/**
* <vue-loader v-else :show="loading.isloading" :message="loading.message" :overlay="false"></vue-loader>
* Submit to event/store
*/
    template: '<event-admin-registration></event-admin-registration>',
    props: ['settings'],
    data(){
        return{
          Form: new Form(),
          moment: new moment(),
          options: {
            back: false
          },
          submitLoader: {
            isloading: false,
            message: 'Processing your Event',
          },
          loading: {
            isloading: false,
            message: 'Preparing Data'
          },
          tempEvent: {},
        }
    },
    created() {
      let vm = this;
      /**
      * Back History Control
      **/
      history.pushState(null, null, location.href),
      window.onpopstate = function (event) {
        if (event.state) {
          //console.log(`history changed because of pushState/replaceState`)
        } else {
          //console.log(`history changed because of a page load`)
          vm.$set(vm.options, 'back', true)
        }
      },
      Bus.$on('back', function() {
        vm.$set(vm.options, 'back', true)
      })
    },
    mounted(){
      window.addEventListener('load', () => {
        this.setHeader();
      });
      this.fetchVenues();
    },
    computed:{
    },
    watch:{
      'options.back': function(val, oldVal){
        let vm = this
        // Incase of double trigger of popstate
        if(val !== oldVal && val == true){
          vm.formBack()
        }
      },
    },
    methods:{
      setHeader: function(){
        let headerText = ( !this.settings ? 'Create an Event' : 'Edit an Event');
        $('.content-wrapper > .content-header > .page-title').text(headerText);
      },
      fetchVenues: _.throttle( function () {
        let vm = this;
        vm.$set(vm.loading,'isLoading',true);
        vm.$set(vm.Form.details.venue.options,'options',[]); //Clear Data
        vm.axios.get('/event-venue/admin/list')// Get Actual data via axios
        .then( response => {
          vm.Form.details.venue.options.push.apply(vm.Form.details.venue.options, response.data);
          //console.log(response.data)
        }).catch( error => {
          // Preform Error Function
        });

        vm.fetchType();
      },400 ),
      /**
      * Get Types
      */
      fetchType: _.throttle( function () {
        let vm = this;
        vm.$set(vm.loading,'isLoading',true);
        vm.$set(vm.Form.details.type,'options',[]); //Clear Data
        vm.axios.get('/registration-type/list') // Get Actual data via axios
        .then( response => {
          vm.Form.details.type.options.push.apply(vm.Form.details.type.options, response.data)
          //console.log(response.data)
        }).catch( error => {
          // Preform Error Function
        });

        vm.checkSettings();
      },400 ),
      /**
      * External Edit Check
      **/
      checkSettings(){
        let vm = this;
        if(vm.settings && vm.settings.edit){
          // Get Event to Edit
          vm.$set(vm.Form.event,'edit',true);
        }

        vm.$set(vm.loading,'isLoading',false);
      },
      submit(){
        let vm = this

        vm.$set(vm.submitLoader,'isloading', true);

        let postRoute = '/event/admin/store'

        let data = {
          name: vm.Form.details.name, //Max 256
          description: vm.Form.details.description,
          venueId: vm.Form.details.venue.value ? vm.Form.details.venue.value.id : '',
          registrationOpensAt: vm.Form.details.registerDateStart, // Date Time
          registrationClosesAt: vm.Form.details.registerDateEnd, // Date Time
          startsAt: vm.Form.details.startDate, // Date Time
          earlyBirdEndsAt: vm.Form.details.earlyBirdDate, // Date Time
          endsAt: vm.Form.details.endDate, // Date Time
          publishAt: vm.Form.details.publishDate, // Date Time
          registrationTypeIds: [], // Array
          //price: vm.Form.details.price,
          prices: vm.Form.prices.value,
          ticketsTotal: vm.Form.details.ticketsTotal,
          eventAddons: [],
        }

        // Check for Edit
        if( vm.settings ){
          vm.$set(data,'eventId',vm.settings.eventId);
          postRoute = '/event/admin/update'
        }

        // Registration Types, Only return Ids
        vm.Form.details.type.value.forEach(function(element) {
          data.registrationTypeIds.push(element.id);
        })

        //Check Addons have required Fields
        vm.Form.addOns.value.forEach(function(element) {
          if(element.name){
            //console.log(element.block);
            let tempBlock = [];
            element.block.forEach(function(blockObj) {
              tempBlock.push(blockObj.tempId);
            })
            //console.log(`${element.name} - ${tempBlock}`);
            vm.$set(element,'block',tempBlock);
            data.eventAddons.push(element);
          }
        })

        //Preform Axios Submmision
        this.axios.post(postRoute, data)
        .then( response => {
          window.location.replace('/event/admin/');
          //vm.$set(vm.submitLoader,'isloading', false);
          console.log(`%c Submit`,'color: green')
        }).catch( error => {
          vm.$set(vm.submitLoader,'isloading', false);
          console.log(`%c Failure`,'color: red')
        });
        //console.log(`This Route: ${postRoute}`);
        //console.log(data);
        //vm.$set(vm.submitLoader,'isloading', false);
      },
      /**
      * Handdle Back on Form
      **/
      formBack(){
        /**
        * Step back through form
        **/
        let vm = this

        if(vm.Form.prices.finished){
          vm.$set(vm.Form.prices, 'finished', false);
        }else if(vm.Form.details.finished){
          vm.$set(vm.Form.details, 'finished', false);
        }else{
          history.go(-1);
        }

        vm.$set(vm.options, 'back', false);
      },
    },
    components:{
      'vue-loader':                 require('@vuejs/components/loader/loader.vue').default,
      'event-edit-prep':            require('./form-components/edit-prep.vue').default,
      'event-create-details':       require('./form-components/details.vue').default,
      'event-prices':               require('./form-components/prices.vue').default,
      'event-create-addons':        require('./form-components/addons.vue').default,
    }
}
</script>

<style lang="scss">
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .4s ease;
}
.slide-fade-leave-active {
  transition: all 0s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter{
/* .slide-fade-leave-active below version 2.1.8 */
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-leave-to{
  opacity: 0;
}
@media (max-width: 454px){
  .content-wrapper {
    padding: 30px 10px;
  }
}
label.full-label {
  display: block;
}
label.full-label small{
  font-weight: normal;
}
.mb-2e{
  margin-bottom: 2em;
}
.header-back{
  position: absolute;
  top: 4px;
  left: 4px;
}
</style>
