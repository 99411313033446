<template>
  <div>
    <div style="margin-bottom: 2em;">
      <h4 class="text-center sub-title">Information on where to send your payment will be sent to the email address you provided above.</h4>
    </div>
    <button v-on:click="actions.submit" class="btn btn-primary" :disabled="!actions.canProceed">{{ 'Submit Registration' | translate }}</button>
  </div>
</template>

<script>

export default {
  props: ['actions'],
  data(){
      return{

      }
  },
  mounted: function () {

  },
  computed:{
  /* canProceed: function(){
      let proceed = true;
      return proceed;
    } */
  },
  methods:{

  }
};
</script>

<style lang="scss">

</style>
