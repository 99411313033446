<template>
  <div :class="['modal','fade']">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click.prevent="cancel()"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" >{{ (config.registrant.first_name ? config.registrant.first_name : '') }} {{ (config.registrant.last_name ? config.registrant.last_name : '' ) }}</h4>
        </div>
        <div class="modal-body">
          <p>
            {{ 'Please confirm marking' | translate }}
            {{ (config.registrant.first_name ? config.registrant.first_name : '') }} {{ (config.registrant.last_name ? config.registrant.last_name : '' ) }}
            {{ 'as paid.' | translate }}
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click.prevent="cancel()">{{ 'Close' | translate }}</button>
          <button type="button" class="btn btn-success" @click.prevent="confirm(config.registrant.id)">{{ 'Confirm' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
    props: ['config','actions'],
    data(){
      return{

      }
    },
    created() {

    },
    mounted(){
      this.initilizeModal();
    },
    computed:{

    },
    watch:{

    },
    methods:{
      initilizeModal: function(){
        $(this.$el).modal('show');
      },
      cancel: function(){
        let vm = this;

        $(this.$el).modal('hide');
        vm.actions.close();
      },
      confirm: function(regId){
        let vm = this;

        $(this.$el).modal('hide');
        vm.actions.confirm(regId);
      },
    },
    components:{

    }
}
</script>

<style lang="scss">
.modal {
  overflow-y:auto;
}
.modal-title-highlight{
  color: #26313f;
}
</style>
