<template>
  <div>
    <div class="row">
      <div class="form-group col-md-6">
        <label class="full-label" for="addon-name">
          {{ 'Addon Name' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <input v-model="addon.name" name="addon-name" type="text" class="form-control" placeholder="Addon Name">
      </div>

      <div class="form-group col-md-6">
        <label class="full-label" for="addon-name">
          {{ 'Type' | translate }}
        </label>
        <input v-model="addon.type" name="addon-type" type="text" class="form-control" placeholder="Type (Seminar / Party)">
      </div>
    </div>

    <div class="form-group">
      <label class="full-label" for="addon-start-date">
        {{ 'Start / End Date' | translate }}
        <small class="pull-right text-success">{{ '* required' | translate }}</small>
      </label>
      <div class="row">
        <div class="col-md-6">
          <date-time-combo v-model="addon.startsAt" />
        </div>
        <div class="col-md-6">
          <date-time-combo v-model="addon.endsAt" :after="addon.startsAt" />
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="full-label" for="addon-description">
        {{ 'Description' | translate }}
        <small class="pull-right text-success">{{ '* required' | translate }}</small>
      </label>
      <vue-editor v-model="addon.description" :editor-options="editor.options" :editorToolbar="editor.customToolbar" name="addon-description"></vue-editor>
    </div>

    <div class="form-group">
      <label class="full-label" for="addon-tickets-total">
        {{ 'Tickets Available' | translate }}
        <small class="pull-right text-success">{{ '* required' | translate }}</small>
      </label>
      <datamask-decimal v-model="addon.ticketsTotal" :decimal="0" :classes="'form-control'" name="addon-tickets-total"></datamask-decimal>
    </div>

    <div class="price-bucket">
      <h4>{{ 'Pricing' | translate }}</h4>
      <ul class="items">
        <li v-for="(price, index) in addon.registrationTypeIds" :key="price.tempId" :class="['item']">
          <span class="price-type-name">{{ price.name }}</span>
          <span class="price-type-title">{{ 'Price' | translate }}</span>
          <div class="inline-block">
            <datamask-decimal v-model="price.price" :decimal="2" :classes="'form-control'"></datamask-decimal>
          </div>
          <span class="price-type-title">{{ 'Early Bird Price' | translate }}</span>
          <div class="inline-block">
            <datamask-decimal v-model="price.earlyBirdPrice" :decimal="2" :classes="'form-control inline-block'"></datamask-decimal>
          </div>
        </li>
      </ul>
    </div>

    <div class="block-bucket" v-if="addons.length > 1">
      <h4>{{ 'Block When Selected' | translate }}</h4>
      <div class="blocked-items">
        <span v-for="(blockItem) in addon.block" class="label label-primary large-label">{{ blockItem.name }}</span>
      </div>
      <button @click.prevent="showBlockAddons()" class="btn btn-primary">{{ 'Block / Unblock Addon' | translate }}</button>
    </div>

    <!-- Modals -->
    <template v-if="show.block">
      <addon-block :key="addon.tempId" :config="{addon:addon,addons:addons}" :actions="{close:closeBlockAddons,block:blockAddon,inlist:isInBlockList}" />
    </template>
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";

export default {
  name: 'add-on',
  props: ['addon','index','details','addons'],
  data () {
    return {
      editor: {
        customToolbar: [
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ],
        options: {
          bounds: '.form-group'
        }
      },
      show: {
        block: false
      }
    }
  },
  created() {

  },
  mounted(){
    this.updatePriceFromType();
  },
  computed:{

  },
  watch:{

  },
  methods:{
    /**
    * Set Price Options on Type avalible Change
    * each time type is changed, price needs to be checked and rebuilt, saving any existing values
    */
    updatePriceFromType(){
      let vm = this;

      let tmpPriceArray = [];

      const scaffolding = { // Sample object
        id: null, // id of Type
        name: '', // Name of Type
        price: 0, // base price [float]
        earlyBirdPrice: 0 // Early Bird price [float]
      };

      //console.log( vm.addon.registrationTypeIds );

      vm.details.type.value.forEach(function(type) {
        //console.log(`This ID: ${type.id}`);

        const tempEP = vm.addon.registrationTypeIds.find(function (obj) { return obj.id == type.id }); // Temp existing Price Object

        let tempPrice = {};

        if( tempEP ){
          tempPrice = Object.assign({}, tempEP)
          //console.log(`${type.id} => ${tempEP}`);
        }else{
          tempPrice = Object.assign({}, scaffolding)
          const randomId = Math.floor(Date.now() / 1000) + '-' + Math.random().toString(36).substr(2, 9);

          vm.$set(tempPrice,'id',type.id);
          vm.$set(tempPrice,'name',type.name);
          vm.$set(tempPrice,'tempId',randomId);

          //console.log(`${type.id} => EMPTY`);
        }

        tmpPriceArray.push(tempPrice);
      });

      //console.log(tmpPriceArray);

      vm.$set(vm.addon,'registrationTypeIds',[]);
      vm.addon.registrationTypeIds.push.apply(vm.addon.registrationTypeIds, tmpPriceArray);
    },
    /**
    * Block Addons
    */
    showBlockAddons: function(){
      let vm = this;
      vm.$set(vm.show,'block',true);
    },
    closeBlockAddons: function(){
      let vm = this;
      vm.$set(vm.show,'block',false);
    },
    blockAddon: function(thisAddon){
      let vm = this;
      // Add or Remove addon from Black List
      let thisKey = -1;
      //console.log(`index: ${vm.index}`);
      if(vm.addon.block.length){
        thisKey = vm.addon.block.indexOf( vm.addon.block.find(function (obj) { return obj.tempId == thisAddon.tempId }) );
      }
      //Find the Blocked items key
      let blockedItem = vm.addons.find(function (obj) { return obj.tempId == thisAddon.tempId });

      if(thisKey > -1){
        vm.addon.block.splice(thisKey, 1);
        // Remove this addon from blocks block
        let blockKey = -1;
        if(blockedItem.block.length){
          blockKey = blockedItem.block.indexOf( blockedItem.block.find(function (obj) { return obj.tempId == vm.addon.tempId }) );
        }
        if(blockKey > -1){
          blockedItem.block.splice(blockKey, 1);
        }
      }else{
        let tempBlock = {
          tempId: thisAddon.tempId,
          name: thisAddon.name,
        }
        vm.addon.block.push(tempBlock);

        let tempAddonBlock = {
          tempId: vm.addon.tempId,
          name: vm.addon.name,
        }
        blockedItem.block.push(tempAddonBlock);
      }
    },
    isInBlockList: function(thisAddon){
      let vm = this;
      let thisKey = -1;
      if(vm.addon.block.length){
        thisKey = vm.addon.block.indexOf( vm.addon.block.find(function (obj) { return obj.tempId == thisAddon.tempId }) );
      }
      return (thisKey > -1? true : false);
    }
  },
  components:{
    VueEditor,
    DatamaskDecimal,
    'date-time-combo': require('./date-time-combo.vue').default,
    'addon-block': require('./addon-block.vue').default
  }
}
</script>

<style lang="scss">
.blocked-items{
  margin-bottom: 0.5em;
  .label.large-label{
    font-size: 100%;
    margin-right: 4px;
  }
}
</style>
