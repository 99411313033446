<template>
<div class="row">
  <div class="box-header mb-2e">
    <h1 class="box-title">{{ 'Event Prices' | translate }}</h1>
    <div class="header-back">
      <a @click.prevent="goBack()" type="button" class="btn btn-default">◀ {{ 'Back' | translate }}</a>
    </div>
  </div>

  <div class="col-md-12">
    <!--<p class="text-center page-sub-title">{{ 'price.description' | translate }}</p>-->
    <p class="text-center"><button @click.prevent="newPrice()" type="button" :class="['btn btn-primary']">{{ 'Add Price' | translate }}</button></p>
  </div>

  <div class="col-md-12"><hr></div>

  <div v-if="!processing" class="col-md-12 rule-wrapper">
    <element v-if="prices.value.length">
      <div v-for="(price, index) in prices.value" :key="price.tempId" :class="['row','rule-object','bg-rule-object']">
        <div class="col-md-10 rule-pad">
          <event-price :price="price" :details="details" />
        </div>
        <div class="col-md-2 rule-cancel">
          <a v-if="canRemove" @click.prevent="removePrice(price.tempId)" class="btn btn-primary pull-right showcursor">
            <span class="glyphicon glyphicon-remove-circle glyphicon-push" aria-hidden="true"></span>
          </a>
          <div class="clearfix"></div>
        </div>
      </div>
    </element>
    <div v-else class="no-rules">
      <span class="text-center text-muted">{{ 'Create a New Price.' | translate }}</span>
    </div>
  </div>

  <div class="text-right">
    <button @click.prevent="nextStep()" class="btn btn-primary" :disabled="!canProceed">{{ 'Next add Add-Ons' | translate }}</button>
  </div>
</div>
</template>

<script>
export default{
  template: '<event-prices></event-prices>',
  props: ['prices','details'],
  data(){
      return{
        processing: false
      }
  },
  created() {
    history.pushState(null, null, location.href)
  },
  mounted(){
    this.scrollToTop();
    this.checkPresets();
  },
  computed:{
    canProceed: function(){
      let vm = this;
      let proceed = true;

      vm.prices.value.forEach(function(thisTicket) {
        if(!thisTicket.name){
          proceed = false;
        }
        thisTicket.registrationTypeIds.forEach(function(thisPrice) {
          if(thisPrice.price == null){
            proceed = false;
          }
        })
      })

      return proceed;
    },
    canRemove: function(){
      let vm = this;

      return (vm.prices.value.length > 1 ? true : false );
    }
  },
  watch:{
    'details.type.value': function(val, oldVal){
      //console.log('Change');
    },
  },
  methods:{
    scrollToTop(){
      $('html,body').stop().animate({
        scrollTop: 0
      }, 'slow', 'swing');
    },
    checkPresets(){
      let vm = this;

      if( !vm.prices.value.length ){
        vm.newPrice('Full Event');
      }
    },
    newPrice(thisName){
      let vm = this;
      // Create Key to track for move / deletion
      const randomId = Math.floor(Date.now() / 1000) + '-' + Math.random().toString(36).substr(2, 9);
      // Create copy of template
      let newPrice = Object.assign({}, vm.prices.scaffolding.overview)

      if(thisName){
          vm.$set(newPrice,'name',thisName);
      }

      vm.$set(newPrice,'tempId',randomId)

      vm.prices.value.push(newPrice)
    },
    /**
    * Remove by ID
    */
    removePrice(tempId){
      let vm = this,
          thisPriceKey = vm.prices.value.indexOf( vm.prices.value.find(function (obj) { return obj.tempId == tempId }) );

      vm.prices.value.splice(thisPriceKey, 1);
    },
    goBack(){
      Bus.$emit('back', true);
    },
    nextStep(){
      let vm = this;

      vm.$set(vm.prices,'finished',true);
    }
  },
  components:{
    DatamaskDecimal,
    'date-time-combo':  require('./date-time-combo.vue').default,
    'event-price':      require('./price.vue').default,
  }
}
</script>

<style lang="scss">
.price-bucket{
display: block;
margin-bottom: 2%;
h4 {
  margin:0 0 5px 0;
  padding:0;
  font-size: 12pt;
  text-transform: none;
  font-weight: bold;
  line-height: 1.42857143;
  color: #333333;
}

.items {
  list-style: none;
  display: block;
  margin:0;
  padding:0;
  .item{
    list-style: none;
    display: block;
    padding: 0.5em;
    margin:0;
    background-color: rgba(255,255,255,0.4);
    border: 1px solid #ccc;
    &:nth-child(even){
      background-color: rgba(0,0,0,0.02);
    }
    &:not(:first-child){
      border-top: none;
    }
    .price-type-name {
      font-weight: bold;
      margin-right: 1em;
      display: inline-block;
      width: 100px;
    }
    .price-type-title{

    }
    .inline-block {
      display: inline-block;
      margin-right: 1em;
    }
  }

}
}
</style>
