<template>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="full-label" for="email-title">
          {{ 'Subject' | translate }}
        </label>
        <input v-model="email.subject" name="email-title" type="text" class="form-control" placeholder="Email Title">
      </div>

      <div class="form-group">
        <label for="event-description">
          {{ 'Body' | translate }}
        </label>
        <vue-editor v-model="email.body"
          :editor-options="editor.options"
          :editorToolbar="editor.customToolbar"
          useCustomImageHandler
          @imageAdded="handleImageAdded"
          name="event-description">
        </vue-editor>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <label for="event-description">
          <span class="glyphicon glyphicon-search"></span>
          {{ 'Preview' | translate }}
        </label>
        <div class="preview-wrapper">
          <registrant-template :content="{subject: email.subject, body: email.body, name: name }" :registrar="registrar" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
* Will require css to inline style on submit handdled by laravel backend
* All tool bar options
  var fullToolbar = [
  [{ 'font': [] }],
  [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
  [{ 'size': ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
  [{ 'header': 1 }, { 'header': 2 }],
  ['blockquote', 'code-block'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],
  [{ 'color': [] }, { 'background': [] }],
  ['link', 'image', 'video', 'formula'],
  [{ 'direction': 'rtl' }],
  ['clean'],
]
// Add image storage control
useCustomImageHandler
@imageAdded="handleImageAdded"
*/
import { VueEditor, Quill } from "vue2-editor";

export default{
  props: ['email','name','registrar'],
  data(){
      return{
        editor: {
          customToolbar: [
            [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
            ['bold', 'italic', 'underline'],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link', 'image'],
          ],
          options: {
            bounds: '.form-group',
          }
        },
      }
  },
  created() {

  },
  mounted(){

  },
  computed:{

  },
  watch:{

  },
  methods:{
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      let vm = this;
      // An example of using FormData
       // NOTE: Your key could be different such as:
       // formData.append('file', file)

       var formData = new FormData();
       formData.append('image', file)

       vm.axios({
         url: '/event-mail/admin/store-image',
         method: 'POST',
         data: formData
       })
       .then((response) => {
         let url = response.data // Get url from response
         Editor.insertEmbed(cursorLocation, 'image', url);
         resetUploader();
       })
       .catch((err) => {
         console.log(err);
       })
    }
  },
  components:{
    VueEditor,
    'registrant-template': require('./templates/registrant-template.vue').default,
  }
}
</script>

<style lang="scss">
.quillWrapper{
  background-color: #fff;
}
.preview-wrapper{
  border: 1px solid #ccc;
}
</style>
