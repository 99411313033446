<template>
  <div :class="['modal','fade']">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click.prevent="cancel()"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" >{{ 'Block Addons' | translate }}</h4>
        </div>
        <div class="modal-body">

          <div :class="['list-group','no-mar']">
            <button v-for="(thisAddon, index) in config.addons" v-if="thisAddon.tempId != config.addon.tempId" @click.prevent="actions.block({tempId:thisAddon.tempId,name:thisAddon.name})" type="button" :class="['list-group-item',{'active':actions.inlist({tempId:thisAddon.tempId,name:thisAddon.name})}]">
              {{ (thisAddon.name ? thisAddon.name : thisAddon.tempId) }}
              <span v-if="actions.inlist({tempId:thisAddon.tempId,name:thisAddon.name})" class="pull-right">{{ 'blocked' | translate }}</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click.prevent="cancel()">{{ 'Close' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
* Possible Groups: all-registered,all-paid,all-unpaid,registrant
* 'template' is still pretty bare bones, but accepts one of:
* thank-you,other,sample-template-name
*/
export default{
    props: ['config','actions'],
    data(){
      return{
        message: null,
      }
    },
    created() {

    },
    mounted(){
      this.initilizeModal();
    },
    computed:{

    },
    watch:{

    },
    methods:{
      initilizeModal: function(){
        $(this.$el).modal('show');
      },
      cancel: function(){
        let vm = this;
        $(this.$el).modal('hide');
        vm.actions.close();
      },
    },
    components:{

    }
}
</script>

<style lang="scss">
.report-builder-wrapper .list-group.no-mar .list-group-item{
  margin-bottom: 0;
}

.modal {
  overflow-y:auto;
}
.modal-title-highlight{
  color: #26313f;
}
.m-b-4{
  margin-bottom: 4px;
}
</style>
