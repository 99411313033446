<template>
  <div class="row event-cart-container">
    <h2>{{ 'Registration' | translate }}</h2>

    <!-- Type -->
    <div class="col-md-12 text-center mb-30">
      <h4 class="text-center">{{ 'You are registering as a:' | translate }}</h4>
      <div class="radio">
        <label v-for="(thisType, index) in event.registration_types" v-if="(!member && (thisType.id != 1)) || (member && (thisType.id == 1))" :key="thisType.id" class="radio-inline">
            <input type="radio" name="type" :id="'type-'+thisType.id" :value="thisType" checked v-model="cartForm.type.value" >
            {{ thisType.name }}
        </label>
      </div>
    </div>

    <!-- Student id -->
    <div v-if="cartForm.type.value && (cartForm.type.value.id == 3)" class="form-group col-md-12">
      <label class="full-label" for="student-id">
        {{ 'Student ID#' | translate }}
        <small class="pull-right text-success">{{ '* required' | translate }}</small>
      </label>
      <input v-model="cartForm.student.id" name="student-id" type="text" class="form-control" placeholder="student id number">
    </div>

    <!-- Pass Type -->
    <div v-if="cartForm.type && cartForm.type.value" class="col-md-12 text-center">
      <h2 class="text-center">{{ 'Select Pass Type' | translate }}</h2>

      <div v-if="event.prices && Array.isArray(event.prices)" class="list-group price-group">
        <a v-for="(price, index) in event.prices" :key="price.id" href="#" @click.prevent="choosePrice(price.id)" :class="['list-group-item',{'active' : cartForm.price.value == price.id }]">
          <div class="price-item-container">

              <div class="check">
                <span v-if="cartForm.price.value == price.id" class="glyphicon glyphicon-ok-circle" aria-hidden="true"></span>
                <span v-else class="glyphicon glyphicon-remove-circle" aria-hidden="true"></span>
              </div>
              <div class="details text-left">
                <h3 v-if="price.name">
                  {{ price.name }}
                  <span v-if="index > 0" class="no-weight">{{ dualDateTime(price.starts_at,price.ends_at) }}</span>
                </h3>
                <p class="list-group-item-text">
                  {{ cartForm.type.value.name }}
                  <span v-if="checkEarlyBirdPrice(price)">
                    | <span class="earlybird-note">{{ 'Earlybird price in effect until' | translate }} {{ singleDateTime(event.early_bird_ends_at) }}</span>
                  </span>
                </p>
              </div>
              <div class="price" v-html="displayPrice(price)"></div>

          </div>
        </a>
      </div>
    </div>

    <!-- Addons -->
    <div v-if="cartForm.type && cartForm.type.value" class="col-md-12 text-center">
      <h2 class="text-center">{{ 'Select Add-Ons' | translate }}</h2>

      <div v-if="event.addons && Array.isArray(event.addons)" class="list-group price-group">
        <template v-for="(addon, index) in event.addons">
          <a v-if="addon.tickets_sold >= addon.tickets_total || blockAddon(addon.id)" :key="addon.id" :class="['list-group-item','disabled']">
            <div class="price-item-container">
                <div class="check">
                  <span v-if="addon.inCart" class="glyphicon glyphicon-ok-circle" aria-hidden="true"></span>
                  <span v-else class="glyphicon glyphicon-remove-circle" aria-hidden="true"></span>
                </div>
                <div class="details text-left">
                  <h3 v-if="addon.name">{{ addon.name }}</h3>
                  <p class="list-group-item-text">
                    {{ dualFullDateTime(addon.starts_at,addon.ends_at) }}
                  </p>
                </div>
                <div class="price" v-html="displayPrice(addon,'+')"></div>

            </div>
          </a>
          <a v-else :key="addon.id" href="#" @click.prevent="chooseAddonPrice(addon.id)" :class="['list-group-item',{'active' : addon.inCart }]">
            <div class="price-item-container">

                <div class="check">
                  <span v-if="addon.inCart" class="glyphicon glyphicon-ok-circle" aria-hidden="true"></span>
                  <span v-else class="glyphicon glyphicon-remove-circle" aria-hidden="true"></span>
                </div>
                <div class="details text-left">
                  <h3 v-if="addon.name">{{ addon.name }}</h3>
                  <p class="list-group-item-text">
                    {{ dualFullDateTime(addon.starts_at,addon.ends_at) }}
                  </p>
                </div>
                <div class="price" v-html="displayPrice(addon,'+ ')"></div>

            </div>
          </a>
        </template>
      </div>
    </div>

    <!-- Payment -->
    <div v-if="cartForm.type && cartForm.type.value" class="col-md-12 text-center">
      <h2 class="text-center">{{ 'Payment' | translate }}</h2>

      <div class="list-group price-group">
        <div v-html="cartForm.total.html"></div>

        <div v-if="cartForm.total.calc" class="list-group-item total">
          <div class="price-item-container">
            <div class="details text-left">
              <h3>{{ 'Total Due' | translate }}</h3>
            </div>

            <div v-if="!cartForm.total.retrieving" class="price">${{cartForm.total.calc}}</div>
            <div v-else class="price">
              <div class="price-spinner">
                <div class="dot1"></div>
                <div class="dot2"></div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <!-- Payment Method -->
    <div v-if="cartForm.type && cartForm.type.value && cartForm.price.value" class="col-md-12 text-center">
      <h3 class="text-center sub-title">{{ 'How would you like to pay?' | translate }}</h3>
      <div class="radio mb-30">
        <label v-for="(thisPaymentMethod, index) in cartForm.paymentMethod.options" :key="thisPaymentMethod.id" class="radio-inline">
            <input type="radio" name="paymentMethod" :id="'paymentMethod-'+thisPaymentMethod.id" :value="thisPaymentMethod" checked v-model="cartForm.paymentMethod.value" >
            {{ thisPaymentMethod.name }}
        </label>
      </div>

      <div v-if="cartForm.paymentMethod.value.id == 1" class="card">
        <stripe-payment :stripe-info="cartForm.paymentMethod.stripe" :billing="cartForm.paymentMethod.billing" :actions="{submit:actions.submit,canProceed:actions.canProceed}" />
      </div>
      <div v-else class="card">
        <general-payment :actions="{submit:actions.submit,canProceed:actions.canProceed}" />
      </div>

    </div>

    <div class="col-md-12"><hr></div>
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";

export default{
    template: '<event-contact-form></event-contact-form>',
    props: ['cart-form','event','type-id','actions','member'],
    data(){
        return{
          memberOnly: false
        }
    },
    created() {

    },
    mounted(){
      this.initializeCart();
    },
    computed:{

    },
    watch:{
      'cartForm.type.value': function(){
        let vm = this;
        vm.$set(vm.cartForm.total,'que',(vm.cartForm.total.que + 1) );
        vm.calcTotal();
      },
      'cartForm.total.que': function(val, oldVal){
        let vm = this;
        if(val > 0){
          vm.retrieveTotal(0);
        }
      }
    },
    methods:{
      /**
      * Initilize Cart
      **/
      initializeCart(){
        let vm = this;
        // Set Default Registration Type
        let regType = null;

        if(vm.member){
          regType = vm.event.registration_types.find(function (obj) { return obj.id == 1});
          //console.log(regType);
        }else{
          let tempType = vm.event.registration_types[0];
          if(tempType.id != 1){
            regType = vm.event.registration_types[0];
          }else{
            regType = vm.event.registration_types[1];
          }
        }

        vm.$set(vm.cartForm.type,'value',regType);

        /* if(!vm.typeId){
          vm.$set(vm.cartForm.type,'value',vm.event.registration_types[0]);
        }else{
          vm.$set(vm.cartForm.type,'value', vm.event.registration_types.find(function (obj) { return obj.id == vm.typeId }));
        } */
        // Default Payment Methos
        vm.$set(vm.cartForm.paymentMethod,'value',vm.cartForm.paymentMethod.options[0]);
      },
      /**
      * Set Price Choice on Selection
      */
      choosePrice(id){
        let vm = this;

        vm.$set(vm.cartForm.price,'value',id);
        //console.log(`This Id: ${id}`)
        vm.$set(vm.cartForm.total,'que',(vm.cartForm.total.que + 1) );
        vm.calcTotal();
      },
      /**
      * Mark addon as in cart
      */
      chooseAddonPrice(id){
        let vm = this;

        let thisType = vm.event.addons.find(function (obj) { return obj.id == id });

        if( thisType.inCart ){
          vm.$set(thisType,'inCart',false);
        }else{
          vm.$set(thisType,'inCart',true);
        }
        vm.$set(vm.cartForm.total,'que',(vm.cartForm.total.que + 1) );
        vm.calcTotal();
      },
      /**
      * Should this addon be blocked?
      **/
      blockAddon: function(id){
        let vm = this;
        let blocked = false;
        let thisAddon = vm.event.addons.find(function (obj) { return obj.id == id });

        if(thisAddon.blocked_addons.length){
          thisAddon.blocked_addons.forEach(function(thisBlockedItem) {
            let thisBA = vm.event.addons.find(function (obj) { return obj.id == thisBlockedItem.id });
            if(thisBA.inCart){
              blocked = true;
            }
          });
        }

        return blocked;
      },
      /**
      * Display Price Associated with selected Type
      */
      displayPrice(price,prefix){
        let vm = this;
        let thisPrice = null;

        if( price.registration_types && (price.registration_types.length > 0) ){
          if( vm.cartForm.type.value.id ){
            let thisType = price.registration_types.find(function (obj) { return obj.id == vm.cartForm.type.value.id });

            if( thisType && thisType.pivot ){
              thisPrice = ( vm.event.in_early_bird_period && thisType.pivot.early_bird_price &&  (thisType.pivot.early_bird_price != thisType.pivot.price) ? parseFloat(thisType.pivot.early_bird_price) : parseFloat(thisType.pivot.price));
            }
          }else{
            let thisType = price.registration_types[0];

            if( thisType && thisType.pivot ){
              thisPrice = ( vm.event.in_early_bird_period && thisType.pivot.early_bird_price &&  (thisType.pivot.early_bird_price != thisType.pivot.price) ? parseFloat(thisType.pivot.early_bird_price) : parseFloat(thisType.pivot.price));
            }
          }
          //Do something
        }

        return ( thisPrice > 0 ? (prefix ? prefix : '') + '$' + thisPrice.toFixed(2).replace(/[^\d\.]/g, "") : 'FREE' );
      },
      /**
      * Calculate Total
      **/
      calcTotal(){
        let vm = this;
        let totalHtml = '';
        let totalValue = null;

        // Base Price selected
        if( vm.cartForm.price ){
          let thisPass = vm.event.prices.find(function (obj) { return obj.id == vm.cartForm.price.value });

          if( thisPass && vm.cartForm.type.value ){
            let thisPassPrice = thisPass.registration_types.find(function (obj) { return obj.id == vm.cartForm.type.value.id })

            if( thisPassPrice ){
              let price = 0.00;
              if(vm.event.in_early_bird_period){
                price = ( thisPassPrice.pivot.early_bird_price &&  (thisPassPrice.pivot.early_bird_price != thisPassPrice.pivot.price) && (parseFloat(thisPassPrice.pivot.price) != null) ? '$' +  parseFloat(thisPassPrice.pivot.early_bird_price).toFixed(2).replace(/[^\d\.]/g, "") : '$' +  parseFloat(thisPassPrice.pivot.price).toFixed(2).replace(/[^\d\.]/g, "") );
              }else{
                price = ( parseFloat(thisPassPrice.pivot.price) > 0 ? '$' +  parseFloat(thisPassPrice.pivot.price).toFixed(2).replace(/[^\d\.]/g, "") : 'FREE' );
              }
              //let calcItem = {component: 'pass', id: thisPass.id, type: thisPassPrice.id, price: thisPassPrice.pivot.price };
              totalValue = parseFloat(thisPassPrice.pivot.price);

              totalHtml += `<div class="list-group-item">
                              <div class="price-item-container">
                                <div class="details text-left">
                                  <h3>${thisPass.name} - ${thisPassPrice.name}</h3>`;

                    if(vm.event.in_early_bird_period){
                    totalHtml += `<p class="list-group-item-text">
                                  Earlybird discount rate in effect until ${( Moment(vm.event.early_bird_ends_at).isValid() ? Moment(vm.event.early_bird_ends_at).format('MMMM D, YYYY') : '')}
                                  </p>`;
                    }

                    totalHtml += `</div>
                                <div class="price">${price}</div>
                              </div>
                            </div>`;

            }
          }

          // Calc Addon Price
          for (var i=0; i < vm.event.addons.length; i++) {
            const addon = vm.event.addons[i];

            if( addon.inCart && addon.registration_types && (addon.registration_types.length > 0) ){

              let thisAddonPrice = addon.registration_types.find(function (obj) { return obj.id == vm.cartForm.type.value.id });

              if( thisAddonPrice && thisAddonPrice.pivot ){
                //console.log(thisAddonPrice.pivot);
                let price = null;

                if(vm.event.in_early_bird_period){
                  price = ( thisAddonPrice.pivot.early_bird_price && (thisAddonPrice.pivot.early_bird_price != thisAddonPrice.pivot.price) && (parseFloat(thisAddonPrice.pivot.price) != null) ? '$' +  parseFloat(thisAddonPrice.pivot.early_bird_price).toFixed(2).replace(/[^\d\.]/g, "") : '$' +  parseFloat(thisAddonPrice.pivot.price).toFixed(2).replace(/[^\d\.]/g, "") );
                }else{
                  price = ( parseFloat(thisAddonPrice.pivot.price) > 0 ? '$' +  parseFloat(thisAddonPrice.pivot.price).toFixed(2).replace(/[^\d\.]/g, "") : 'FREE' );
                }
                //let price = ( parseFloat(thisAddonPrice.pivot.price) > 0 ? '$' +  parseFloat(thisAddonPrice.pivot.price).toFixed(2).replace(/[^\d\.]/g, "") : 'FREE' );
                //let calcItem = {component: 'addon', id: addon.id, type: thisAddonPrice.id, price: thisAddonPrice.pivot.price };
                totalValue = parseFloat(totalValue) + parseFloat(thisAddonPrice.pivot.price);

                totalHtml += `<div class="list-group-item">
                                <div class="price-item-container">
                                  <div class="details text-left">
                                    <h3>${addon.name} - ${thisAddonPrice.name}</h3>`;

                                    if(vm.event.in_early_bird_period){
                                      totalHtml += `<p class="list-group-item-text">
                                      Earlybird discount rate in effect until ${( Moment(vm.event.early_bird_ends_at).isValid() ? Moment(vm.event.early_bird_ends_at).format('MMMM D, YYYY') : '')}
                                      </p>`;
                                    }

                                  totalHtml += `</div>
                                  <div class="price">${price}</div>
                                </div>
                              </div>`;
              }

            }
          }
        }

        let emptyValue = `<div class="list-group-item">
                            <div class="price-item-container">
                              <div class="details text-center">
                                <h3 class="mb-0">Please Select a Pass Type</h3>
                              </div>
                            </div>
                          </div>`;

        vm.$set(vm.cartForm.total,'html',(totalHtml ? totalHtml : emptyValue));
      },

      checkEarlyBirdPrice(price){
        let vm = this;

        if( vm.cartForm.type.value.id ){
          let thisType = price.registration_types.find(function (obj) { return obj.id == vm.cartForm.type.value.id });

          if( thisType && thisType.pivot ){
            return ( vm.event.in_early_bird_period && thisType.pivot.early_bird_price &&  (thisType.pivot.early_bird_price != thisType.pivot.price) ? true : false);
          }
        }else{
          let thisType = price.registration_types[0];

          if( thisType && thisType.pivot ){
            thisPrice = ( vm.event.in_early_bird_period && thisType.pivot.early_bird_price &&  (thisType.pivot.early_bird_price != thisType.pivot.price) ? true : false);
          }
        }
      },
      /**
      * API Calculate Price
      * We apply a que to handdle quick user interface request and miss-match on request
      * Calculations made by browser side JS cannot be trusted.
      */
      retrieveTotal(newCount){
        let vm = this;
        let makeRequest = false;

        if(newCount == 0 && !vm.cartForm.total.retrieving){
          makeRequest = true;
        }else if(newCount > 0 && vm.cartForm.total.que >= newCount && vm.cartForm.total.retrieving){
          makeRequest = true;
        }

        //console.log( newCount );

        if(makeRequest){
          let thisCount = (newCount == 0 ? 1 : newCount);

          vm.$set(vm.cartForm.total,'retrieving',true);

          let data = {
            eventId: vm.event.id,
            eventRegistrationTypeId: (vm.cartForm.type.value && vm.cartForm.type.value.id ? vm.cartForm.type.value.id : null),
            eventPriceId: null,
            selectedAddonIds: []
          };

          if( vm.cartForm.price.value ){
            vm.$set(data,'eventPriceId',vm.cartForm.price.value);
          }

          for (var i=0; i < vm.event.addons.length; i++) {
            const addon = vm.event.addons[i];

            if( addon.inCart && addon.registration_types && (addon.registration_types.length > 0) ){
              data.selectedAddonIds.push(addon.id);
            }
          }

          vm.axios.post('/event-registration/price-preview', data)
          .then( response => {
            vm.cartForm.total.calc = parseFloat(response.data).toFixed(2);
          }).catch( error => {
            vm.cartForm.total.calc = parseFloat(0).toFixed(2);
          }).finally(() => {
            vm.$set(vm.cartForm.total,'que',(vm.cartForm.total.que - thisCount));
            if(vm.cartForm.total.que > 0){
              vm.retrieveTotal(vm.cartForm.total.que);
            }else{
              vm.$set(vm.cartForm.total,'retrieving',false);
            }
          });
        }
      },
      /**
      * Format datetime for display on Addons
      */
      dualFullDateTime(firstDate,secondDate){
        let vm = this;
        let sameDay = false;
        // Check if both dates are same day
        //console.log(`Dual Full Datetime: ${firstDate} - ${secondDate}`);
        if( Moment(firstDate).isValid() && Moment(secondDate).isValid()){
            sameDay = ( Moment(firstDate).format('MM-DD-YYYY') == Moment(secondDate).format('MM-DD-YYYY') ? true : false);
        }

        if( sameDay ){
          let thisResult = ( Moment(firstDate).isValid() ? Moment(firstDate).format('MMMM D, YYYY | h:mm a') : '') + ( Moment(secondDate).isValid() ? ' - ' + Moment(secondDate).format('h:mm a') : '');
          return thisResult;
        }else{
          let thisResult = ( Moment(firstDate).isValid() ? Moment(firstDate).format('MMMM D, YYYY') : '')
                          + ( Moment(secondDate).isValid() ? ' - ' + Moment(secondDate).format('MMMM D, YYYY') : '')
                          + ' | '
                          + ( Moment(firstDate).isValid() ? Moment(firstDate).format('h:mm a') : '')
                          + ( Moment(secondDate).isValid() ? ' - ' + Moment(secondDate).format('h:mm a') : '');
          return thisResult;
        }
      },
      dualDateTime(firstDate,secondDate){
        let vm = this;
        let sameDay = false;
        // Check if both dates are same day
        if( Moment(firstDate).isValid() && Moment(secondDate).isValid()){
            sameDay = ( Moment(firstDate).format('MM-DD-YYYY') == Moment(secondDate).format('MM-DD-YYYY') ? true : false);
        }

        if( sameDay ){
          let thisResult = '- ' + ( Moment(firstDate).isValid() ? Moment(firstDate).format('MMMM D, YYYY') : '');
          return thisResult;
        }else{
          let thisResult = '- ' + ( Moment(firstDate).isValid() ? Moment(firstDate).format('MMMM D, YYYY') : '')
                          + ( Moment(secondDate).isValid() ? ' - ' + Moment(secondDate).format('MMMM D, YYYY') : '');
          return thisResult;
        }
      },
      singleDateTime(thisDateTime){
        let vm = this;

        return ( Moment(thisDateTime).isValid() ? Moment(thisDateTime).format('MMMM D, YYYY') : '');
      }
    },
    components:{
      'stripe-payment':    require('./stripe-payment.vue').default,
      'general-payment':   require('./general-payment.vue').default,
    }
}
</script>

<style lang="scss">
$break-small: 991px;
$break-medium: 992px;
.event-cart-container{
  h2 {
    text-align: center;
    text-transform: none;
  }
}

.earlybird-note{
  font-style: italic;
}

.sub-title{
  text-transform: none;
  color: #26313f;
}

.mb-0{
  margin-bottom:0;
}

.no-weight{
  font-weight: normal;
}

.price-group{
  .list-group-item{
    @media screen and (max-width: $break-small) {
      padding: 10px 5px
    }
    .price-item-container{
      display: table;
      width: 100%;
      .check,.details,.price{
        display: table-cell;
      }
      .details{
        padding:10px;
        h3{
          text-transform: none;
          font-weight: bold;
          color: #26313f;
          margin-top:0;
        }
      }
      .check{
        @media screen and (min-width: $break-medium) {
          width: 80px;
          vertical-align: middle;
        }
        @media screen and (max-width: $break-small) {
          vertical-align: top;
        }
        font-size: 1.5em;
      }
      .price {
        @media screen and (min-width: $break-medium) {
          width: 150px;
          vertical-align: middle;
        }
        @media screen and (max-width: $break-small) {
          vertical-align: top;
          text-align: right;
          padding-right: 5px;
        }
        font-size: 1.2em;
      }
    }
    &.active{
      background-color: #4bbca7;
      border-color: #ddd;
      color: #fff;
      .list-group-item-text{
        color: #fff;
      }
      .details{
        h3{
          color: #fff;
        }
      }
      .check,.price {
        @media screen and (min-width: $break-medium) {
          font-size: 2em;
        }
      }
    }
    &.total{
      background-color: #26313f;
      color: #fff;
      .list-group-item-text{
        color: #fff;
      }
      .details{
        h3{
          color: #fff;
          margin:0;
        }
      }
      .check,.price {
        @media screen and (min-width: $break-medium) {
          font-size: 2em;
        }
      }
    }
    &.disabled {
      cursor: not-allowed;
      color: gray;
      .price-item-container{
        .details{
          h3{
            color: #777777;
          }
        }
      }
    }
  }
}

/**
* Price Spinner Credit: http://tobiasahlin.com/spinkit/
**/
.price-spinner {
  margin: 0 auto;
  width: 20px;
  height: 20px;
  position: relative;
  text-align: center;

  -webkit-animation: loader-sk-rotate 2.0s infinite linear;
  animation: loader-sk-rotate 2.0s infinite linear;

  .dot1, .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #4bbca7;
    border-radius: 100%;

    -webkit-animation: loader-sk-bounce 2.0s infinite ease-in-out;
    animation: loader-sk-bounce 2.0s infinite ease-in-out;
  }

  .dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
    background-color: #F8A031;
  }
}
</style>
