class Contact {

  constructor(){
    let scaffolding = this.formScaffolding();
    return scaffolding;
  }

  formScaffolding(){
    const scaffolding = {
      form: {
        firstName: { value: null},
        lastName: {value: null},
        email: {value: null},
        phone: {value: null},
        street1: {value: null},
        city: {value: null},
        state: {value: null},
        zip: {value: null},
      },
      method: {
        value: null,
        options: [
          {id: 1, name: 'Email'},
          // {id: 2, name: 'Text Message'},
        ]
      }
    }

    return scaffolding;
  }

}

export default Contact;
