<template>
  <div :class="['modal','fade']">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click.prevent="cancel()"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" >{{ (config.registrant.first_name ? config.registrant.first_name : '') }} {{ (config.registrant.last_name ? config.registrant.last_name : '' ) }}</h4>
        </div>
        <div class="modal-body">
          <vue-loader :show="loading.isloading" :message="loading.message" :overlay="false"></vue-loader>
          <template v-if="loading.isloading == false">
            <div>
              <strong>{{'Ticket Details' | translate}}</strong>
              <dl class="dl-horizontal">
                <dt>Status:</dt>
                <dd>{{ (config.registrant.paid ? 'paid' : 'awaiting payment') }}</dd>
                <dt>Payment Method:</dt>
                <dd>{{ config.registrant.payment_type_selected }}</dd>
                <dt>Price Total:</dt>
                <dd>${{ convertPrice(config.registrant.total_price) }}</dd>
              </dl>

              <dl v-if="registrant" class="dl-horizontal">
                <dt>Ticket:</dt>
                <dd>{{ getTicketInfo() }}</dd>
                <dt v-if="registrant.addons.length">Addons:</dt>
                <dd>
                  <span v-for="addon in registrant.addons">{{ addon.name }}</span>
                </dd>
              </dl>
            </div>
            <address v-if="config.registrant.phone_number || config.registrant.email">
                <strong>{{ 'Contact Details' }}</strong><br>
                <dl class="dl-horizontal">
                  <template v-if="config.registrant.phone_number">
                    <dt>Phone:</dt>
                    <dd>
                      {{ config.registrant.phone_number }}
                      <span v-if="config.registrant.alert_by != 'email'" class="label label-primary">{{'prefered' | translate}}</span>
                    </dd>
                  </template>

                  <template v-if="config.registrant.email">
                    <dt>Email:</dt>
                    <dd>
                      {{ config.registrant.email }}
                      <span v-if="config.registrant.alert_by == 'email'" class="label label-primary">{{'prefered' | translate}}</span>
                    </dd>
                  </template>
                </dl>
            </address>
          </template>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger pull-left" @click.prevent="removeRegistrant()">{{ 'Cancel Ticket' | translate }}</button>
          <button type="button" class="btn btn-default" @click.prevent="cancel()">{{ 'Close' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
    props: ['config','actions'],
    data(){
      return{
        registrant: null,
        loading: {
          isloading: false,
          message: 'Retriving Registrant Data'
        },
      }
    },
    created() {

    },
    mounted(){
      this.fetchRegistrant();
      //this.initilizeModal();
    },
    computed:{

    },
    watch:{

    },
    methods:{
      fetchRegistrant: _.throttle( function () {
        let vm = this;
        $(this.$el).modal('show');
        vm.$set(vm.loading,'isloading',true);
        // Get Actual data via axios
        vm.axios.get('/event-registration/admin/show-ajax/' + vm.config.registrant.id)
        .then( response => {
          //console.log(response.data);
          vm.registrant = response.data;
          vm.$set(vm.loading,'isloading',false);
        }).catch( error => {
          //console.log('Error')
          // Preform Error Function
          vm.$set(vm.loading,'isloading',false);
        });
      },400 ),
      /* initilizeModal: function(){
        $(this.$el).modal('show');
      }, */
      cancel: function(){
        let vm = this;

        $(this.$el).modal('hide');
        vm.actions.close();
      },
      removeRegistrant: function(){
        let vm = this;
        $(this.$el).modal('hide');
        vm.actions.remove(vm.config.registrant.id);
      },
      convertPrice: function(price){
        return parseFloat(price).toFixed(2);
      },
      getTicketInfo(){
        let vm = this;
        let thisTicket = null;
        if(vm.registrant.event_price_id){
          thisTicket = vm.config.eventPrices.find(function (obj) { return obj.id == vm.registrant.event_price_id });
        }
        return (thisTicket ? thisTicket.name : 'Ticket Not Found');
      }
    },
    components:{
      'vue-loader':                 require('@vuejs/components/loader/loader.vue').default,
    }
}
</script>

<style lang="scss">
.modal {
  overflow-y:auto;
}
.modal-title-highlight{
  color: #26313f;
}
</style>
