<template>
  <table class="table table-striped table-bordered table-condensed dataTable no-footer" style="width: 100%;">
    <thead>
      <tr>
        <th>{{ 'Reg #' | translate }}</th>
        <th>{{ 'Name' | translate }}</th>
        <th>{{ 'Status' | translate }}</th>
        <th>{{ 'Payment Method' | translate }}</th>
        <th>{{ 'Price' | translate }}</th>
        <th>{{ 'Reg Date' | translate }}</th>
        <th class="text-right" style="width: 300px">{{ 'Actions' | translate }}</th>
      </tr>
    </thead>

    <tbody>
        <tr v-for="reg in registrations">
          <td>{{ (reg.id).toString().padStart(6,"0") }}</td>
          <td>{{ reg.first_name }} {{ reg.last_name }}</td>
          <td :class="'paid-'+reg.id">{{ (reg.paid ? 'paid' : 'awaiting payment' ) }}</td>
          <td>{{ (reg.payment_type_selected ? reg.payment_type_selected : '') }}</td>
          <td>{{ parseFloat(reg.total_price).toFixed(2) }}</td>
          <td>{{ reg.created_at }}</td>
          <td class="text-right" style="width: 300px">
            <template v-if="!reg.paid">
              <span :class="'markpaid-'+reg.id"><a @click.prevent="actions.showEditPaid(reg.id)" href="#">{{ 'mark as paid' | translate }}</a> |</span>
            </template>
            <a @click.prevent="actions.showRegistrantMessage(reg.id)" href="#">{{ 'send message' | translate }}</a> |
            <a @click.prevent="actions.showThisReg(reg.id)" href="#">{{ 'view' | translate }}</a>
          </td>
        </tr>
    </tbody>
  </table>
</template>

<script>
/**
* VueJS min boot-strap Wrapper
* in blade, section: after-scripts-end
* load:
* {!! Html::script('/js/datatables.js') !!}
* {!! Html::script('https://cdn.datatables.net/buttons/1.5.1/js/dataTables.buttons.min.js') !!}
* {!! Html::script('https://cdn.datatables.net/buttons/1.5.1/js/buttons.html5.min.js') !!}
* {!! Html::script('https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js') !!}
* {!! Html::script('https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.38/pdfmake.js') !!}
* {!! Html::script('https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.38/vfs_fonts.js') !!}
* {!! Html::script('https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.22.2/moment.min.js') !!}
*/
export default{
  props: ['config','registrations','filters','actions'],
  data(){
      return{
        dataTable: null,
        dateFormat: 'YYYY-MM-DD',
        refresh: {
          state: false,
          key: null,
          id: null,
          row: false,
        },
        total: 0
      }
  },
  created() {
    let vm = this

    Bus.$on('updateRegistrations', function (object) {
      console.log(object);
      vm.$set(vm.refresh,'key',object.key);
      vm.$set(vm.refresh,'id',object.id);
      console.log(vm.refresh.state);
      vm.$set(vm.refresh,'state',true);
    }),
    Bus.$on('removeRow', function (object) {
      vm.$set(vm.refresh,'row',true);
      vm.$set(vm.refresh,'id',object.id);
    })
  },
  mounted(){
    let vm = this;

    /**
    * Wait for external JS components to load
    */
    //window.addEventListener('load', () => {
      vm.initilizeDataTable();
    //});

  },
  beforeDestroy() {
    const vm = this
    if (vm.dataTable) {
      vm.dataTable.destroy(true);
    }
    vm.dataTable = null
  },
  computed:{

  },
  watch:{
    'filters.startDate': function(){
      let vm = this;
      vm.dataTable.draw();
    },
    'filters.endDate': function(){
      let vm = this;
      vm.dataTable.draw();
    },
    'registrations': function(val, oldVal){
      //Do something
      //console.log('A change has Occured');
    },
    'refresh.state': function(val, oldVal){
      let vm = this;
      //Do something
      if(vm.refresh.state){
        let cell = vm.dataTable.cell( vm.dataTable.$(".paid-"+vm.refresh.id) );
        $('.markpaid-'+vm.refresh.id).addClass('hide-paid');
        cell.data('paid').draw();
        vm.$set(vm.refresh,'row',false);
        vm.$set(vm.refresh,'id',null);
        vm.$set(vm.refresh,'key',null);

        // Reset refresh state to false which will re-fire the refresh.state watcher, but will not enter the if.
        // This is done so that next time a registration is marked as paid the refresh.state actually
        // changes from false to true again which fires the watcher and enters the if.
        vm.$set(vm.refresh,'state',false);
      }
    },
    'refresh.row': function(val, oldVal){
      let vm = this;
      //Do something
      if(vm.refresh.row){
        let thisRow = vm.dataTable.row( function ( idx, data, node ) {
                        return data[0] == vm.refresh.id ? true : false;
                      }).index();
        //console.log(`This Row Index ${thisRow}`);
        if(thisRow){
          vm.dataTable.row(thisRow).remove().draw(false);
        }else{
          //console.log(`An error Occured removing the row`);
        }
        vm.$set(vm.refresh,'row',false);
        vm.$set(vm.refresh,'id',null);
      }
    }
  },
  methods:{
    /**
    * Initilize DataTables
    */
    initilizeDataTable: function(){
      let vm = this;

      // Check if We have Registrations
      if(vm.registrations.length){
        vm.dataTable = $(this.$el).DataTable( {
          dom: '<"row rdt"<"inline-block col-md-7 dt-select"<"inline-block"l><"inline-block"><"inline-block"f>><"inline-block text-right col-md-5"B">><"table-responsive"t><"row"<"col-md-6"i><"col-md-6"p>>',
          buttons: [
            {
              extend: 'pdfHtml5',
              className: 'btn btn-primary' ,
              exportOptions: {
                columns: [ 0, 1, 2, 3, 4, 5 ]
              },
              messageBottom: function(){
                return 'Total: $' + vm.calcTotal();
              }
            },
            {
              extend: 'excelHtml5',
              className: 'btn btn-primary' ,
              exportOptions: {
                columns: [ 0, 1, 2, 3, 4, 5 ]
              },
              messageBottom: function(){
                return 'Total: $' + vm.calcTotal();
              }
            },
            {
              extend: 'csvHtml5',
              className: 'btn btn-primary',
              exportOptions: {
                columns: [ 0, 1, 2, 3, 4, 5 ]
              },
              messageBottom: function(){
                return 'Total: $' + vm.calcTotal();
              }
            },
            /* {
              text: 'Send Payment Reminder',
              className: 'btn btn-primary',
              action: vm.sendPaymentReminder
            } */
          ],
          columns: [
            { title: 'Reg #' },
            { title: 'Name' },
            { title: 'Status'},
            { title: 'Payment Method'},
            { title: 'Price',render: vm.formatPrice},
            { title: 'Reg Date',render: vm.formatDate},
            { title: 'Actions', 'searchable': false, 'orderable': false,}
          ],
          stateSave: true,
          language: {
            search: "_INPUT_",
            searchPlaceholder: "Search"
          },
          lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]]
        });

        /**
        * Add title to buttons
        */
        $('.rdt .dt-buttons').prepend('<span class="export">Export to </span>');

        /**
        * Add Button to toolbar to filter
        */
        let toolBarFilterUnpaid = $(`<a class="btn btn-default dt-toolbar-filter"><span class="glyphicon glyphicon-filter"></span> Show Unpaid</a>`);
        $('.rdt .dt-select').append(toolBarFilterUnpaid);
        toolBarFilterUnpaid.click(vm.showNonPaid);

        // Extend dataTables search
        $.fn.dataTable.ext.search.push(
          function(settings, data, dataIndex) {
            let min = Moment(vm.filters.startDate);
            let max = Moment(vm.filters.endDate);
            let createdAt = data[5] || 0; // Our date column in the table
            let show = {
                  after: false,
                  before: false
                }

            //console.log(`min: ${(min ? Moment(min) : '')} max: ${(max ? Moment(max) : '')} created At: ${Moment(createdAt)}`)
            if(!vm.filters.startDate && !vm.filters.endDate){
              show.after = true;
              show.before = true;
            }else{
              if(vm.filters.startDate){
                if(Moment(createdAt).isSameOrAfter(min,'day')){
                  show.after = true;
                }else{
                  show.after = false;
                }
              }else{
                show.after = true;
              }

              if(vm.filters.endDate){
                if(Moment(createdAt).isSameOrBefore(max,'day')){
                  show.before = true;
                }else{
                  show.before = false;
                }
              }else{
                show.before = true;
              }
            }

            return (show.after && show.before ? true : false);
          }
        );

        // Run When initilized
        //vm.calcTotal();
        // Run Whenever updated initilized
        /* vm.dataTable.on( 'draw', function () {
          vm.calcTotal();
        }); */
      }else{
        //Do Nothing;
      }
    },
    /**
    * Format Price
    */
    formatPrice: function(data, type, row){
      let vm = this;

      if(type === 'display'){
        return '$'+data
      }else{
        return data
      }
    },
    /**
    * Format Date
    */
    formatDate: function(data, type, row){
      let vm = this;

      if(type === 'display'){
        switch(vm.dateFormat) {
          case 'RFC1036-MH':
            return Moment(data).format("dddd, Do MMMM YYYY")
            break;
          case 'YYYY-MM-DD':
            return Moment(data).format("YYYY-MM-DD")
            break;
          default:
            return Moment(data).format("YYYY-MM-DD")
        }
      }else{
        return data
      }
    },
    /**
    *
    */
    calcTotal: function(){
      let vm = this;
      let total = vm.dataTable.column(4, {search:'applied'}).data().reduce( function (a,b) {
                    var x = parseFloat(a) || 0;
                    var y = parseFloat(b) || 0;
                    return parseFloat(x + y).toFixed(2);
                  });
      //vm.total = total;
      //console.log(`This Total: ${total}`);
      return total;
    },
    /* editReg: function(thisId){
      console.log(`Edit ${thisId}`);
    },
    viewReg: function(thisId){
      console.log(`View ${thisId}`);
    }, */
    showNonPaid: function(){
      let vm = this;
      let previousSearch = vm.dataTable.search();
      //console.log(`Previous Search: ${previousSearch}`);

      if(previousSearch == 'awaiting payment'){
        vm.dataTable.search('').draw();
      }else{
        vm.dataTable.search('awaiting payment').draw();
      }
    }
  },
  components:{

  }
}
</script>

<style lang="scss">
.hide-paid{
  display: none;
}
.dt-toolbar-filter{
  margin-bottom:0;
  margin-left: 4px;
  padding: 4px 12px;
  cursor: pointer;
}
  .export.mc-export { width: auto; }
  .inline-block { display: inline-block; }
  .inline-block.pr-10 { padding-right: 10px;}
  span.export {
      float: none;
      font-weight: 800;
      padding-top: 7px;
      padding-right: 10px;
      display: inline-block;
      vertical-align: top;
      width: inherit;
  }
  label.dtexternal {
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      padding-left: 5px;
  }
  .dtexternal select {
      margin-left: 5px;
  }
  .dataTables_filter {
      float: none;
  }
  .dt-select{
    padding-top: 0;
  }
</style>
