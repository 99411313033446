<template>
  <div>
    <vue-loader :show="loading.isloading" :message="loading.message" :overlay="true"></vue-loader>

    <div class="text-right">
      <a href="/event-venue/admin" class="btn btn-secondary">Manage Venues</a>
      <a href="/event/admin/create" class="btn btn-primary">{{ 'Create New Event' | translate }}</a>
    </div>
    <div class="box-header">
      <h1 class="box-title">{{ 'Event Listings' | translate }}</h1>
    </div>

    <div v-if="!loading.isloading && config.events && jqLoaded">
      <div class="events-filters">
        <div class="input-group date-filters col-md-6">
          <span class="input-group-addon">From</span>
          <datepicker v-model="filters.startDate" :input-class="'form-control datapicker-bg'" :maximumView="'year'" :initialView="'day'" />
          <span class="input-group-addon">To</span>
          <datepicker v-model="filters.endDate" :input-class="'form-control datapicker-bg'" :maximumView="'year'" :initialView="'day'" />
          <span class="input-group-btn">
            <button @click.prevent="clearFilterDates()" class="btn btn-secondary">{{ 'Clear' | translate }}</button>
          </span>
        </div>
      </div>
      <list-datatables :events="config.events" :filters="filters" :csrf="csrf"></list-datatables>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
/**
* <email-editor />
* <test-component />
*/
import Datepicker from '@vuejs/vendor/vue-datepicker/vuejs-datepicker.min.js';

export default{
  props: ['config'],
  data(){
      return{
        jqLoaded: false,
        loading: {
          isloading: false,
          message: 'Loading Events...'
        },
        filters:{
          startDate: null,
          endDate: null
        },
        csrf: null,
      }
  },
  created() {

  },
  mounted(){
    this.fetchEvents();
    this.setCsrf();
    // Insure Jquery / External Libraries has loaded
    window.addEventListener('load', () => {
      this.jqLoaded = true;
      this.scrollToTop();
    });

  },
  computed:{

  },
  watch:{

  },
  methods:{
    scrollToTop(){
      $('html,body').stop().animate({
        scrollTop: 0
      }, 'slow', 'swing');
      // Set Page Header
      $('.content-wrapper > .content-header > .page-title').text('Admin Event Listings');
    },
/**
* Get all Events
*/
  fetchEvents: _.throttle( function () {
    let vm = this
    vm.$set(vm.loading,'isloading',true);
    // Get Actual data via axios
    vm.axios.get('/event/admin/list-raw')
    .then( response => {
      //console.log(response.data);
      vm.$set(vm.config,'events',response.data);
      vm.$set(vm.loading,'isloading', false);
    }).catch( error => {
      console.log('Error')
      vm.$set(vm.loading,'isloading', false);
      // Preform Error Function
    });
  },400 ),

    setCsrf() {
      let vm = this;
      // vm.$set(vm.config,'csrf',Laravel.csrfToken);
      vm.csrf = Laravel.csrfToken;
    },
/**
* Date Time Format
*/
    clearFilterDates(){
      let vm = this;
      vm.$set(vm.filters,'startDate',null);
      vm.$set(vm.filters,'endDate',null);
    },
  },
  components:{
    Datepicker,
    'vue-loader':                 require('@vuejs/components/loader/loader.vue').default,
    'list-datatables':            require('./list-datatables.vue').default,
  }
}
</script>

<style lang="scss">
$break-small: 991px;
$break-medium: 992px;
.content-wrapper{
  @media screen and (max-width: $break-small) {
    padding: 30px 0;
  }
}
.box-header{
  margin-bottom: 1.5em;
}
/* Doubble Scroll Fix */
body > .wrapper{
  overflow: visible;
}
.events-filters{
  .input-group{
    @media screen and (max-width: $break-small) {
      display: block;
      margin: 0 15px;
    }
    &.date-filters {
      //margin-left: 15px;
      .vdp-datepicker{
        @media screen and (min-width: $break-medium) {
          display: table-cell;
        }
        @media screen and (max-width: $break-small) {
          display: block;
        }
      }
      .input-group-addon{
        @media screen and (max-width: $break-small) {
          text-align: left;
          border: none;
          background: none;
        }
      }
      .input-group-btn{
        @media screen and (max-width: $break-small) {
          display: inline-block;
          width: 100%;
          text-align: right;
        }
      }
    }
  }
}
</style>
