<template>
  <div>
    <vue-loader :show="submitLoader.isloading" :message="submitLoader.message" :overlay="true"></vue-loader>

    <report-edit-prep v-if="preparing && edit.edit" :report="edit.report" :data-models="Form.models" :data-fields="Form.fields" :data-rules="Form.rules" :validation="Form.validation"></report-edit-prep>

    <div v-if="!preparing && Form" class="report-builder-wrapper">
      <form method="post" @keypress.13.prevent @submit.prevent="submit(false)">

        <transition name="slide-fade">
          <report-models v-if="!Form.models.finished || !Form.models.value" :data-models="Form.models" :data-remove="edit.processing" :validation="Form.validation"></report-models>
        </transition>

        <transition name="slide-fade">
          <report-fields v-if="(Form.models.finished && Form.models.value) && (!Form.fields.finished || !Form.fields.value.length)" :data-fields="Form.fields" :model-value="Form.models.value" :validation="Form.validation"></report-fields>
        </transition>

        <transition name="slide-fade">
          <report-rules v-if="(Form.models.finished && Form.models.value && Form.fields.finished && Form.fields.value.length && !Form.rules.finished)" :data-rules="Form.rules" :field-value="Form.fields.value" :model-value="Form.models.value" :data-edit="edit.edit" :validation="Form.validation" :optional-name="optionalName"></report-rules>
        </transition>

        <transition name="slide-fade">
          <div v-if="(Form.models.finished && Form.fields.finished && Form.rules.finished)" class="row" >

            <div class="col-md-12">
              <div class="page-title">
                {{ 'rb-submit-title' | translate }}
              </div>
              <div class="hdlink-tl">
                <a @click.prevent="goBack()" type="button">◀ {{ 'Back to Report Filters' | translate }}</a>
              </div>
            </div>

            <div class="col-md-12"><hr></div>

            <div class="col-md-12">
              <p class="text-center page-sub-title">{{ 'Your ' + Form.models.value.name + ' Report' | translate }}</p>

              <div class="form-group">
                <vue-loader :show="preview.isloading" :message="preview.message"></vue-loader>
                <div v-if="!preview.isloading" class="table-responsive bg-white">
                  <table v-if="preview.results.length" class="table table-striped">
                    <thead v-if="preview.headers">
                      <tr>
                        <th v-for="(optionHeader) in preview.headers">{{ optionHeader }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="preview.results" v-for="(optionRow) in preview.results">
                        <td v-for="(optionCol, key) in optionRow">{{ previewFormat(optionCol,key) }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else class="jumbotron bg-danger">
                    <h1 class="text-danger">{{ 'No Results Found' | translate}}</h1>
                    <p>{{ 'rb-no-results' | translate }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12"><hr></div>

            <div class="col-md-12">
                <p>
                  <div class="pull-right">
                    <button @click.prevent="goBack()" type="button" :class="['btn btn-primary']">◀ {{ 'Back to Report Filters' | translate }}</button>
                    <button type="submit" :class="['btn btn-primary']">{{ 'Generate Report' | translate }}</button>
                  </div>
                  <div class="clearfix"></div>
                </p>
            </div>

          </div>
        </transition>

      </form>
    </div>

    <!-- Delete Modal -->
    <div :class="['modal','fade']" id="removeModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click.prevent="cancelRemove()"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" >{{ 'Confirm Deletion' | translate }}</h4>
          </div>
          <div class="modal-body">
            <p>{{ 'Please confirm deletion of' | translate }} {{ (edit.report ? edit.report.name : '') }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click.prevent="cancelRemove()">{{ 'Cancel' | translate }}</button>
            <button type="button" class="btn btn-primary" @click.prevent="removeReport()">{{ 'Delete' | translate }}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Rename Modal -->
    <div :class="['modal','fade']" id="renameModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click.prevent="cancelRename()"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" >{{ 'Rename Report' | translate }}</h4>
          </div>
          <div class="modal-body">
            <fieldset>
                <div class="text-center">
                  <label for="name" class="control-label">{{ 'Rename Your Report' | translate }}</label>
                </div>
                <input v-model="optionalName" class="form-control" required="" name="name" type="text" @input="renameClearError()">
                <div v-if="optionalNameError" class="text-center text-danger">{{ 'Error: This field is required.' | translate }}</div>
            </fieldset>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click.prevent="cancelRename()">{{ 'Cancel' | translate }}</button>
            <button type="button" class="btn btn-primary" @click.prevent="renameReport()">{{ 'Save' | translate }}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .4s ease;
}
.slide-fade-leave-active {
  transition: all 0s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter{
/* .slide-fade-leave-active below version 2.1.8 */
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-leave-to{
  opacity: 0;
}
</style>

<script>
/**
* --- Notes ---
* *Form* data structure, where it is best practice not to use 2-way binding and avoid mutation of variables,
* in this case however, we will be using a 2 way bind as the parent structure will be a null scaffolding framework object
* and simply a structure holder for child vue data, to facilitate a simpler data submission format
* and avoid any issues with tracking broadcasts for everything.
*
* The nature of this *Form* variable will make use of modular components, mutating parent data is irrelevant.
*
* We will be using broadcast to notify any potental child components of parent changes.
*/

import Form from './classes/form.js' // Call the framework well be using to organize all the sub-components

export default{
    template: '<report-builder></report-builder>',
    props: {
       /**
       * Settings is sent from blade to Configure this component
       * IF more configuration options are required add them here as Options
       * A sample of settings construction can be found in the report-generator views/edit.blade.php
       **/
      settings: { // No Longer being used
        type: Object,
        default: function () {
          return { edit: false, report: null }
        }
      }
    },
    data(){
        return{
            Form: new Form(), // Scaffolding for this form's data
            optionalName: null,
            optionalNameError: false,
            preview: {
              isloading: false,
              message: '...Building Sample Report',
              headers: [],
              results: []
            },
            submitLoader: {
              isloading: false,
              message: 'Processing your Report',
            },
            saveReport: false,
            options: {
              back: false
            },
            preparing: false,
            edit: {
              edit: false,
              remove: false,
              rename: false,
              processing: false,
              report: null
            }
        }
    },
    created() {
      let vm = this
      /**
      * Back History Control
      **/
      history.pushState(null, null, location.href),
      window.onpopstate = function (event) {
        if (event.state) {
          //console.log(`history changed because of pushState/replaceState`)
        } else {
          //console.log(`history changed because of a page load`)
          vm.$set(vm.options, 'back', true)
        }
      },
      Bus.$on('back', function() {
        vm.$set(vm.options, 'back', true)
      }),
      Bus.$on('finishedEditLoading', function(){
        vm.preparing = false
      }),
      /**
      * Edit / Remove Request
      **/
      Bus.$on('editreport', function(data){
        vm.edit = data
      }),
      Bus.$on('removereport', function(data){
        vm.edit = data
      }),
      Bus.$on('renamereport', function(data){
        vm.edit = data
      })
    },
    mounted(){
      //this.testMe()
      //this.initilizeCheck() // Was originally used to get settings passed from blade, specifically used for editting which is now inline vue app
    },
    computed:{
      // Sample Begin
      thisCRFToken(){
        return window.Laravel.csrfToken // Sample csrfToken
      },
      thisAPIToken(){
        return window.Laravel.apiToken // Sample apiToken
      }
      // Sample End
    },
    watch:{
      'Form.rules.finished': function(val, oldVal){
        if(val == true){
          this.submit(true)
        }
      },
      'options.back': function(val, oldVal){
        let vm = this
        // Incase of double trigger of popstate
        if(val !== oldVal && val == true){
          vm.formBack()
        }
      },
      'edit': function(val, oldVal){
        let vm = this

        vm.editSetup()
      }
    },
    methods:{
      // Dev Testing
      testMe(){
        //console.log( Moment().format('MMMM Do YYYY, h:mm:ss a') )
        console.log('Test Me function')
      },
      /**
      * Format Various Preview results, currently date
      **/
      previewFormat(data,key){
        let vm = this

        // Format Dates for cols with format like 2018-12-30 00:00:00
        const formatThese = ['Date of Birth', 'Registration date', 'License Effective Date', 'License Expiry Date', 'License Expiry Date', 'Application Approval Date', 'Application Submission Date']
        if( formatThese.indexOf(vm.preview.headers[key]) > -1 ){
          return Moment(data).format('YYYY-MM-DD')
        }else{
          
          //  "is_active" field in the Employer table represents the status of an Employer.
          // If the value is 1, then it's "Active", else "Inactive"
          if(vm.preview.headers.indexOf('Status') == key){
          
              if(data == 1) {
                  data = "Active"
              }else{
                  data = "Inactive"
              }
          }

          return data
        }
      },
      /**
      * Save simply Toggles save report var then submits
      * Should not be needed as save functionality has been move to blade
      * Leaving for future reference if required.
      **/
      save(){
        let vm = this

        vm.saveReport = true

        vm.submit()
      },
      /**
      * Prepare Form For inline Editing
      **/
      editSetup(){
        let vm = this

        if( vm.edit.edit ){  // Setup Vars | I realise edit.edit.... taking suggestions
          vm.Form = new Form() // Reset The Form incase anyone was editing it
          vm.preparing = true
          vm.optionalName = vm.edit.name
        }else if(vm.edit.remove){
          $('#removeModal').modal('show');
        }else if(vm.edit.rename){
          /* if(vm.edit.report){
            vm.$set(vm.optionalName,vm.edit.report.name)
          } */
          vm.optionalName = vm.edit.report.name
          $('#renameModal').modal('show');
        }else{ // Clear Out Edit
          vm.Form = new Form() // Reset The Form
        }

      },
      /**
      * Unified Submission and Preview request
      */
      submit(isPreview = false){
        let vm = this

        if( isPreview ){
          vm.$set(vm.preview,'isloading',true)
          vm.$set(vm.preview,'headers',[])
          vm.$set(vm.preview,'results',[])
        }else{
          vm.$set(vm.submitLoader,'isloading',true)
        }

        let url = '/api/report-generator/preview'

        if( !isPreview ){
          url = '/api/report-generator/submit'
        }

        // Setup data to be passed
        let data = {
          modelName: vm.Form.models.value.model,
          filters: [],
          save: vm.saveReport
        }

        /* if(vm.edit.edit == true ){
          vm.$set(data,'id',vm.edit.report.id)
        } */

        if(vm.edit.edit == true ){
          vm.$set(data,'parentId',vm.edit.report.id)
        }

        if(vm.optionalName){
          vm.$set(data,'name',vm.optionalName)
        }

        /**
        * Build Filters
        **/
        vm.Form.fields.value.forEach(function(element) {
          let filter = {
            model: element.model,
            field: element.field,
            fullRelationship: (element.fullRelationship? element.fullRelationship : element.model + '.' + element.field),
            rule: null
          }

          const hasRule = vm.Form.rules.value.find(function (rule) { return rule.field == element.field; })

          if( hasRule ){
            // Check rule fields actually have data
            let tmpRuleCheck = true

            // Need to check if each rule component is not null, and if is object first/secnod are not null
            if( !hasRule.operation ){
              tmpRuleCheck = false
              //console.log('No Operation')
            }

            //console.log('typeof: ' + typeof hasRule.input )
            //console.log('Is array? ' + Array.isArray(hasRule.input) )

            if( (typeof hasRule.input === "object") && (hasRule.input !== null) || (typeof hasRule.input !== "object") && hasRule.input || (Array.isArray(hasRule.input)) && (hasRule.input.length) ){
              if( (typeof hasRule.input === "object") && (hasRule.input !== null) && !Array.isArray(hasRule.input) && !hasRule.input.id ){ // If an Object (and not an Array) does it have id?
                if( !(hasRule.input instanceof Date) ){ // If object is a Date
                  if(!(hasRule.input.first instanceof Date) || !(hasRule.input.second instanceof Date)){ // At this point it is a between date, are both values dates?
                    tmpRuleCheck = false
                  }
                }
              }else if( (Array.isArray(hasRule.input)) && !hasRule.input[0].id ){
                //console.log( hasRule.input[0] )
                tmpRuleCheck = false
              }
            }else{ // A null input value
              tmpRuleCheck = false
            }

            if(tmpRuleCheck){
              vm.$set(filter,'rule', {})
              vm.$set(filter.rule,'operation', hasRule.operation )
              if( Array.isArray(hasRule.input) ){
                let idArray = []

                for (var i=0; i < hasRule.input.length; i++) {
                  if( hasRule.input[i].id ){
                    idArray.push(hasRule.input[i].id)
                  }
                }

                vm.$set(filter.rule,'value', idArray)
              }else{
                vm.$set(filter.rule,'value', (hasRule.input.id ? hasRule.input.id : hasRule.input) )
              }
            }else{
              const faildRule = {
                'model': element.model,
                'field': element.field,
                'operation': hasRule.operation,
                'value': hasRule.input
              }

              //console.log('Rule Failure:')
              //console.log(faildRule)
            }
            //console.log( hasRule )
          }

          data.filters.push(filter)
        });

        //Preform Axios Submmision
          this.axios.post(url, data)
          .then( response => {
            // Preform Form Success
            //console.log('%c Success', 'color: green;')

            if( isPreview ){
              vm.preview.headers.push.apply(vm.preview.headers, response.data.columnHeaders)
              vm.preview.results.push.apply(vm.preview.results, response.data.results)
              vm.$set(vm.preview,'isloading',false)
            }else{
              window.location.replace('/report-generator/details/' + response.data.id );
              vm.$set(vm.submitLoader,'message','Loading Report')
            }
          }).catch( error => {
            vm.Form.validation.record(error.response.data) // The user cannot procced to this step without required data, if error backend proccessing issues
            if( isPreview ){
              vm.$set(vm.preview,'isloading',false)
            }else{
              vm.$set(vm.submitLoader,'isloading',false)
            }
          })
      },
      /**
      * Remove simply runs update fuction with save toggled off
      * this will soft-delete (remove from list) and remain avalible for 24hrs via direct url
      **/
      removeReport(){
        let vm = this
        const url = '/api/report-generator/update'
        vm.$set(vm.edit,'remove',false)
        vm.$set(vm.edit,'processing',true)

        const data = {
          id: vm.edit.report.id,
          save: 0
        }

        $('#removeModal').modal('hide');

        vm.axios.post(url, data)
        .then( response => {
          vm.$set(vm.edit,'remove',false)
          vm.$set(vm.edit,'report',null)
          vm.$set(vm.edit,'processing',false)
        }).catch( error => {
          // Do Something
        })

      },
      cancelRemove(){
        let vm = this
        $('#removeModal').modal('hide');
        vm.$set(vm.edit,'remove',false)
        vm.$set(vm.edit,'report',null)
        vm.$set(vm.edit,'processing',false)
      },
      /**
      * Rename Report Functions
      **/
      cancelRename(){
        let vm = this
        $('#renameModal').modal('hide');

        vm.$set(vm.edit,'rename',false)
        vm.$set(vm.edit,'report',null)

        vm.optionalName = null
        //vm.$set(vm.optionalName,null)
      },
      renameReport(){
        let vm = this

        if(vm.optionalName){
          const url = '/api/report-generator/update'
          vm.$set(vm.edit,'rename',false)
          vm.$set(vm.edit,'processing',true)

          //vm.optionalName =

          const data = {
            id: vm.edit.report.id,
            save: 1,
            name: vm.optionalName
          }

          $('#renameModal').modal('hide');
          //console.log(data)
          vm.axios.post(url, data)
          .then( response => {
            vm.$set(vm.edit,'rename',false)
            vm.$set(vm.edit,'report',null)
            vm.$set(vm.edit,'processing',false)
            //vm.$set(vm.optionalName,null)
            vm.optionalName = null
          }).catch( error => {
            // Do Something
          })
        }else{
          vm.optionalNameError = true
        }
      },
      renameClearError(){
        let vm = this
        vm.optionalNameError = false
      },
      /**
      * Handdle Back on Form
      **/
      formBack(){
        /**
        * Step back through form
        **/
        let vm = this

        if(vm.Form.rules.finished){
          vm.$set(vm.Form.rules, 'finished', false)
        }else if(vm.Form.fields.finished && vm.edit.edit == false){
          vm.$set(vm.Form.rules,'value',[])
          vm.$set(vm.Form.rules,'check',[])
          vm.$set(vm.Form.fields, 'finished', false)
        }else if(vm.Form.models.finished){
          vm.$set(vm.edit, 'edit', false)
          vm.$set(vm.edit, 'report', null)
          vm.$set(vm.Form.models, 'value', null)
          vm.$set(vm.Form.fields,'value',[])
          vm.$set(vm.Form.rules,'value',[])
          vm.$set(vm.Form.rules,'check',[])
          vm.$set(vm.Form.rules, 'finished', false)
          vm.$set(vm.Form.fields, 'finished', false)
          vm.$set(vm.Form.models, 'finished', false)
        }else{
          history.go(-1);
        }

        vm.$set(vm.options, 'back', false)
      },
      goBack(){
        Bus.$emit('back', true);
      },
    },
    components:{
      'report-edit-prep': require('./form-components/edit-prep.vue').default,
      'report-models':    require('./form-components/models.vue').default,
      'report-fields':    require('./form-components/fields.vue').default,
      'report-rules':     require('./form-components/rules.vue').default,
      'vue-loader':       require('../loader/loader.vue').default,
    }
}

/**
* OLD ----------------
initilizeCheck(){
  **
  * Setup controls to expand functionality
  * 1) Edit functionality
  * *Keep for future reference if required
  **
  let vm = this

  if( vm.settings.edit == true ){
    vm.preparing = true
    vm.optionalName = vm.settings.report.name
  }
},

// Old inline field for name on save
<div class="inline-block">
  <div class="input-group">
    <input v-model="optionalName" type="text" class="form-control input-lg" :placeholder="'Optional Report Name'">
    <span class="input-group-btn">
      <button :disabled="preview.isloading" @click.prevent="save()" :class="['btn btn-success btn-lg']" type="button">
        {{ 'Save Report' | translate }}
        <span class="glyphicon glyphicon-file bc-pl" aria-hidden="true"></span>
      </button>
    </span>
  </div><!-- /input-group -->
</div>
**/

</script>
