require('./bootstrap');
require('./jquery.dowhen');
require('./jquery.repeater.min');
require('./validator');
require('./jquery.redirect');


jQuery(document).ready(function($){
  $(document).doWhen();
    $('[data-toggle="tooltip"]').tooltip();

  $('[class^="repeater"]').each(function ( index ) {
    $(this).repeater({
        show: function () {
            // $(this).find('.select-without-search-invest').select2({
            $('.select-without-search').select2({
                theme: 'bootstrap',
                minimumResultsForSearch: -1
            });
            $('.select-with-search').select2({
                theme: 'bootstrap',
            });
            $(this).slideDown();
            $(this).each(function(){
                $(this).find(':input').prop('required', true);
            });
            $("input[name$='[fax]']").prop('required', false);
            $("input[name$='[employer_name]']").prop('required', false);
            $("input[name$='[phone]']").prop('required', false);
            $("input[name$='[city]']").prop('required', false);
            $("input[name$='[department]']").prop('required', false);
            $("input[name$='[division]']").prop('required', false);
            $("input[name$='[practice_other_id][]']").prop('required', false);
            $("input[name$='[institution_name]']").prop('required', false);
            $("input[name$='[step_id]']").attr('required', false);
            $("input[name$='[step_video]']").attr('required', false);
            $("input[name$='[step_text]']").attr('required', false);
        },
        hide: function (deleteElement) {
            if(!$(this).hasClass('bridging-course-repeater-panel')){
                if(confirm('Are you sure you want to delete this element?')) {
                    $(this).slideUp(deleteElement);
                }
            } else {
                $(this).slideUp(deleteElement);
            }
      },
      isFirstItemUndeletable: true
    });
  });

    $('.sidebar-collapse').on('show.bs.collapse', function () {
        $(this).parent().find('.sidebar-caret-down').fadeOut('fast', function () {
            $(this).parent().find('.sidebar-caret-up').fadeIn();
        });
        $(this).parents('.sidebar-menu').find('.sidebar-collapse').collapse('hide');
    }).on('hide.bs.collapse', function () {
        $(this).parent().find('.sidebar-caret-up').fadeOut('fast', function () {
            $(this).parent().find('.sidebar-caret-down').fadeIn();
        });
    });
});

/**
 --------------------------------------------------
 VueJs Below
 --------------------------------------------------
 **/
import moment from 'moment'
import 'moment/min/locales.min';
window.Moment = moment;

//let Select2 = require('./components/select2/select2wrapper');
//window.Select2 = Select2.default;

let Multiselect = require('./vendor/vue-multiselect/vue-multiselect.min.js');
window.Multiselect = Multiselect.default;

let DatamaskDecimal = require('./components/data-masks/decimal');
window.DatamaskDecimal = DatamaskDecimal.default;

/**
 * Initilize Filters
 **/
// Translate: usage {{ 'String' | translate }} ,uses files from ./lang.
Vue.filter('translate', function(val){
    const language = (Laravel.language ? require('./lang/' + Laravel.language + '.json') : require('./lang/en.json'));

    if(language[val]) {
        return language[val];
    } else {
        return val
    }
});

/**
 * Create a fresh Vue application instance and attach it to the page.
 **/
var Bus = new Vue({});
window.Bus = Bus; // Now we bind our event bus to window, so its global.

/**
 * Components, only initilize components if they exist on requested page.
 * Good practive if blades are main render component and vuejs augments them.
 */
// Report Builder Index ( Report Format Creation )
if( document.querySelector('[data-vue="report-builder"]') ) {
    const ReportBuilder = new Vue({
        el: '[data-vue="report-builder"]',
        components: {
            'report-builder': require('./components/reportBuilder/index.vue').default,
        }
    });
}

/**
 * Load Modules App.js
 */
require('../../Modules/EventRegistration/Resources/assets/js/app')