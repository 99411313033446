<template>
  <div :class="['modal','fade']">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click.prevent="cancel()"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" >{{ 'View Message' | translate }}</h4>
        </div>
        <div class="modal-body">
          <div class="input-group m-b-4">
            <span class="input-group-addon"><span class="glyphicon glyphicon-envelope"></span></span>
            <p class="form-control false-input">{{ config.message.subject }}</p>
          </div>
          <div class="input-group m-b-4">
            <span class="input-group-addon"><span class="glyphicon glyphicon-user"></span></span>
            <p class="form-control false-input">{{ (config.message.group != 'registrant' ? config.message.group : config.message.registrations[0].first_name+' '+config.message.registrations[0].last_name) }}</p>
          </div>
          <div class="input-group m-b-4">
            <span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
            <p class="form-control false-input">{{ config.message.created_at }}</p>
          </div>

          <template v-if="config.message.group == 'all-unpaid'">
            <payment-reminder-template :content="{subject: config.message.subject, body: config.message.body }" />
          </template>
          <template v-else-if="config.message.group == 'registrant'">
            <registrant-template :content="{subject: config.message.subject, body: config.message.body, name: config.message.registrations[0].first_name+' '+config.message.registrations[0].last_name }" />
          </template>
          <template v-else>
            <all-registrants-template :content="{subject: config.message.subject, body: config.message.body }" />
          </template>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click.prevent="cancel()">{{ 'Close' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
* Possible Groups: all-registered,all-paid,all-unpaid,registrant
* 'template' is still pretty bare bones, but accepts one of:
* thank-you,other,sample-template-name
*/
export default{
    props: ['config','actions'],
    data(){
      return{
        message: null,
      }
    },
    created() {

    },
    mounted(){
      this.initilizeModal();
    },
    computed:{

    },
    watch:{

    },
    methods:{
      initilizeModal: function(){
        $(this.$el).modal('show');
      },
      cancel: function(){
        let vm = this;
        $(this.$el).modal('hide');
        vm.actions.close();
      }
    },
    components:{
      'registrant-template':        require('../../email/templates/registrant-template.vue').default,
      'payment-reminder-template': require('../../email/templates/payment-reminder-template.vue').default,
      'all-registrants-template': require('../../email/templates/all-registrants-template.vue').default,
    }
}
</script>

<style lang="scss">
.modal {
  overflow-y:auto;
}
.modal-title-highlight{
  color: #26313f;
}
.m-b-4{
  margin-bottom: 4px;
}
</style>
