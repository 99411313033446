<template>
  <div class="row" >

    <div class="col-md-12">
      <div class="page-title">
        {{ 'Report Filters' | translate }}
      </div>
      <div class="hdlink-tl">
        <a @click.prevent="goBack()" type="button">◀ {{ (dataEdit ? 'Back' : 'Back to Report Data') | translate }}</a>
      </div>
    </div>

    <div class="col-md-12"><hr></div>

    <div class="col-md-12">
      <p class="text-center page-sub-title">{{ 'addFilter.description' | translate }}</p>
      <p class="text-center"><button @click.prevent="addRule()" type="button" :class="['btn btn-primary']" :disabled="allFieldsUsed">{{ 'Add filter' | translate }}</button></p>
    </div>

    <div class="col-md-12"><hr></div>

    <div class="col-md-12 rule-wrapper">

      <element v-if="dataRules.value.length">
        <div v-for="(rule, index) in dataRules.value" :key="rule.id" :class="['row','rule-object',{ 'bg-rule-object' : rule.state == 'active', 'bg-success' : rule.state == 'success', 'bg-warning' : rule.state == 'warning' }]">
          <div v-if="rule.loading" class="please-wait">
            <h4 class="text-center rule-loading-text">{{ 'Loading Existing Rule' | translate }}</h4>
          </div>
          <div class="col-md-10 rule-pad">
            <report-rule :rule-values="rule" :rule-options="fieldValue" :active-rules="dataRules.value" :rule-check="dataRules.check"></report-rule>
          </div>
          <div class="col-md-2 rule-cancel">
            <a @click.prevent="removeRule(index)" class="btn btn-primary pull-right showcursor">
              <span class="glyphicon glyphicon-remove-circle glyphicon-push" aria-hidden="true"></span>
            </a>
            <div class="clearfix"></div>
          </div>
        </div>
      </element>
      <div v-else class="no-rules">
        <span class="text-center text-muted">{{ 'No filters have been applied yet. You can also run a report without filters.' | translate }}</span>
      </div>

    </div>

    <div class="col-md-12"><hr></div>

    <div class="col-md-12">
      <div class="pull-right">
        <button @click.prevent="dataRules.finished = 1" type="button" :class="['btn btn-primary']">{{ 'Preview' | translate }}</button>
        <button type="submit" :class="['btn btn-primary bc-pl']">{{ 'Generate Report' | translate }}</button>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>

</template>

<style>

</style>

<script>

export default{
    template: '<report-rules></report-rules>',
    props: ['dataRules','fieldValue','modelValue','data-edit','validation','optional-name'],
    data(){
        return{

        }
    },
    created(){
      history.pushState(null, null, location.href)
    },
    mounted(){
      this.scrollToTop()
      this.clearCheck()
    },
    computed:{
      allFieldsUsed(){
        let vm = this
        return ( vm.fieldValue.length ==  vm.dataRules.value.length ? true : false)
      }
    },
    watch:{

    },
    methods:{
      scrollToTop(){
        $('html,body').stop().animate({
          scrollTop: 0
        }, 'slow', 'swing');
      },
      clearCheck(){
        let vm = this

        vm.$set(vm.dataRules,'check',[])
      },
      addRule(){
        let vm = this

        const randomId = Math.floor(Date.now() / 1000) + '-' + Math.random().toString(36).substr(2, 9); // Need key for splice issue

        const ruleCount = vm.dataRules.value.length > 0 ? vm.dataRules.value.length : 0,
              newRule = { id: randomId, model: null, field: null, fullRelationship: null, operation: null, input: null, state: 'active', loading: false}

        vm.dataRules.value.push(newRule)
        //console.log(" Rule Added: " + vm.dataRules.value.length ? 0 : vm.dataRules.value.length + " | " + ruleCount)
      },
      removeRule(index){
        let vm = this

        const checkKey = vm.dataRules.check.indexOf(vm.dataRules.value[index].fullRelationship)

        if(checkKey > -1){
          vm.dataRules.check.splice(checkKey, 1);
        }

        // Remove selected option from value
        vm.dataRules.value.splice(index, 1);
      },
      goBack(){
        Bus.$emit('back', true);
      }
    },
    components:{
      'report-rule':     require('./rule.vue').default,
    }
}
</script>
