<template>
  <div>
    <h1>I am a Vuejs component from a Module</h1>
    <!-- Default to 24-Hour format HH:mm -->
    <vue-timepicker></vue-timepicker>

    <!-- Show seconds picker -->
    <vue-timepicker format="HH:mm:ss"></vue-timepicker>

    <!-- 12-hour format, with AM/PM picker -->
    <vue-timepicker v-model="time" format="hh:mm A"></vue-timepicker>

    <!-- 12-hour format, with seconds picker and am/pm picker -->
    <vue-timepicker format="hh:mm:ss a"></vue-timepicker>
  </div>
</template>

<style>

</style>

<script>
/**
// https://github.com/mengxiong10/vue2-datepicker
// https://www.jsdelivr.com/package/npm/vue2-datepicker
import Datepicker from '../../vendor/vue2-datepicker@2.6.2.min.js';
<date-picker v-model="time1" :lang="lang" :first-day-of-week="1"></date-picker>
<date-picker v-model="time2" type="datetime" :lang="lang" :time-picker-options="timePickerOptions"></date-picker>
<date-picker v-model="time3" :lang="lang" range :shortcuts="shortcuts"></date-picker>
**/
//const DateTime = require("../../vendor/luxon.js");
//const { DateTime } = require("../../vendor/luxon.min.js");
//require("../../vendor/luxon@3.4.4.min.js");
//const thisTime = luxon.DateTime.local();
//const thisTime = DateTime.local();
//import luxon from '../../vendor/luxon.min.js'
//const DateTime = luxon.DateTime;
//const { DateTime } = require("../../vendor/luxon.min.js");
//import { DateTime } from '../../vendor/luxon@3.4.4.min.js';
//console.log( DateTime.local() );
//require("../../vendor/luxon@3.4.4.min.js");
//require("../../vendor/weekstart@1.0.0.min.js");

//import { DateTime } from '../../vendor/vue-datetime@1.0.0-beta.8.min.js';
import Datepicker from '../../../../../../../resources/js/vendor/vue-datepicker/vuejs-datepicker.min.js';

export default{
    template: '<module-test></module-test>',
    props: [],
    data(){
      return {
        date: null,
        datetime12: null,
        time: {},
      }
    },
    created() {

    },
    mounted(){

    },
    computed:{
      test(){
        //return DateTime.local()
        return 'Hello World';
      }
    },
    watch:{

    },
    methods:{

    },
    components:{
      'vue-timepicker': require('../time-picker/vue-timepicker.vue').default,
    }
}

</script>
