<template>
  <div class="stripe-wrapper">

    <div class="form-wrapper match-stripe-padding">
      <div class="form-group">
        <label for="cc-card-holder-name">{{ 'Name on Card' | translate }}</label>
        <span class="glyphicon glyphicon-user"></span>
        <input v-model="stripeInfo.cardName" type="text" class="form-control" name="cc-card-holder-name" placeholder="Name as it appears on Credit Card">
      </div>
    </div>

    <div class="form-wrapper">
      <div ref="card"></div>
    </div>

    <!--<div class="dev-info">-->
      <!--<div class="detail text-left">4242 4242 4242 4242</div>-->
      <!--<div class="detail small">10/22</div>-->
      <!--<div class="detail small">123</div>-->
      <!--<div class="detail small">12345</div>-->
    <!--</div>-->

    <div>
      <h4>{{ 'Billing Address' | translate }}</h4>
      <div class="checkbox">
        <label>
          <input type="checkbox" v-model="billing.value"> {{ 'Same as Contact Details' | translate }}
        </label>
      </div>

      <div :class="['row','billing-form','collapse']" style="height: 0px;">
        <div class="form-group col-md-6">
          <label class="full-label" for="phone">
            {{ 'Phone' | translate }}
            <small class="pull-right text-success">{{ '* required' | translate }}</small>
          </label>
          <input v-model="billing.address.phone.value" name="phone" type="text" class="form-control" placeholder="Phone" v-on:blur="validate" v-on:keyup="validate">
        </div>

        <div class="form-group col-md-6">
          <label class="full-label" for="street1">
            {{ 'Street Address' | translate }}
            <small class="pull-right text-success">{{ '* required' | translate }}</small>
          </label>
          <input v-model="billing.address.street1.value" name="street1" type="text" class="form-control" placeholder="Street Address" v-on:blur="validate" v-on:keyup="validate">
        </div>
        <div class="form-group col-md-6">
          <label class="full-label" for="city">
            {{ 'City' | translate }}
            <small class="pull-right text-success">{{ '* required' | translate }}</small>
          </label>
          <input v-model="billing.address.city.value" name="city" type="text" class="form-control" placeholder="City" v-on:blur="validate" v-on:keyup="validate">
        </div>

        <div class="form-group col-md-6">
          <label class="full-label" for="state">
            {{ 'Province' | translate }}
            <small class="pull-right text-success">{{ '* required' | translate }}</small>
          </label>
          <input v-model="billing.address.state.value" name="state" type="text" class="form-control" placeholder="Province" v-on:blur="validate" v-on:keyup="validate">
        </div>
        <div class="form-group col-md-6">
          <label class="full-label" for="zip">
            {{ 'Postal Code' | translate }}
            <small class="pull-right text-success">{{ '* required' | translate }}</small>
          </label>
          <input v-model="billing.address.zip.value" name="zip" type="text" class="form-control" placeholder="Postal Code" v-on:blur="validate" v-on:keyup="validate">
        </div>
      </div>
    </div>

    <div class="mb-30"></div>

    <button v-on:click="purchase" class="btn btn-primary" :disabled="!actions.canProceed">{{ 'Submit Registration' | translate }}</button>
  </div>
</template>

<script>
let stripe = Stripe(StripeKey),
    elements = stripe.elements(),
    card = undefined;

export default {
  props: ['stripe-info','billing','actions'],
  data(){
      return{

      }
  },
  mounted: function () {
    this.initilizeStripe();
  },
  computed:{
    /* canProceed: function(){
      let proceed = true;
      return proceed;
    } */
  },
  watch:{
    'billing.value': function(){
      if(this.billing.value == true){
        $('.billing-form').collapse('hide');
      }else{
        $('.billing-form').collapse('show');
      }
    },
  },

  methods:{
    initilizeStripe: function(){
      let style = {
        base: {
          color: '#32325d',
          lineHeight: '18px',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },

        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }

      };

      //card = elements.create('card', {style: style});
      card = elements.create('card');
      card.mount(this.$refs.card);
    },

    purchase: function () {
      let vm = this;

      stripe.createToken(card, {name: vm.stripeInfo.cardName}).then(function(result) {
        if (result.error) {
          vm.$set(vm.stripeInfo,'token',null);
          self.hasCardErrors = true;
          return;
        }else{
          vm.$set(vm.stripeInfo,'token',result.token);
          vm.actions.submit();
        }
      });
    },

    validate: function (event) {
      switch (event.target.getAttribute('name')) {
        case 'first-name':
        case 'last-name':
        case 'street1':
        case 'city':
        case 'state':
        case 'zip':
          if(event.target.value != ''){
            event.target.parentElement.classList.remove('has-error')
          } else if(event.type !== 'keyup') {
            event.target.parentElement.classList.add('has-error');
          }
          break;
        case 'email':
          if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)){
            event.target.parentElement.classList.remove('has-error')
          } else if(event.type !== 'keyup') {
            event.target.parentElement.classList.add('has-error');
          }
          break;
        case 'phone':
          if(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(event.target.value)){
            event.target.parentElement.classList.remove('has-error')
          } else if(event.type !== 'keyup') {
            event.target.parentElement.classList.add('has-error');
          }
          break;
      }
    }

  },

  beforeDestroy() {
    /**
    * Destroy Stripe Card Instance
    */
    card.destroy();
    //console.log('Stripe destroyed');
  }
};
</script>

<style lang="scss">
.stripe-wrapper{

  .form-wrapper{
    margin-bottom: 1em;
    padding: 1em;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #ddd;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);

    &.match-stripe-padding{
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }

    .form-group{
      margin:0;
      padding:0;
      display: table;
      width: 100%;
      span{
        display: table-cell;
        width: 1em;
        color: #DCDFE5;
      }
      label{
        display: none;
      }
      input{
        display: table-cell;
        border: none;
        box-shadow: none;
        margin: 0;
        /* background: url(images/comment-author.gif) no-repeat scroll 7px 7px;
        padding-left:30px; */
      }
    }
  }

  .billing-form{
    text-align: left;
    //height: 250px;
    /* overflow: visible;
    transform:scaleY(1);
    transform-origin:top;
    transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out; */
    /* &.collapsed{
      //height: 0px;
      transform:scaleY(0);
      overflow: hidden;
      transition: all 0.5s ease-out;
      -webkit-transition: all 0.5s ease-out;
    } */
    label{
      text-align: left;
    }
  }
}

.dev-info{
  display: table;
  width: 100%;
  .detail{
    display: table-cell;
    color: #999;
    &.small{
      width: 100px;
    }
  }
}
</style>
