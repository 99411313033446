<template>
  <div class="panel panel-default event-panel">
    <div class="panel-body">
      <div>
        <div>
          <div class="cal-item">
            <div class="cal-item-header">{{ displayYearMonth }}</div>
            <div class="cal-item-body">{{ displayDay }}</div>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-md-10">
              <h1>{{ event.name }}</h1>
              <h3 v-if="event.starts_at">
                {{ returnDate(event.starts_at) }}
                {{ (event.ends_at && (event.ends_at !== event.starts_at) ? 'to ' + returnDate(event.ends_at) : '' ) }}
              </h3>
              <h3 v-if="event.venue && event.venue.name">{{ event.venue.name }}</h3>
              <address v-if="event.venue" :class="['event-extra-details',{'collapsed':!details.show}]">
                  {{ event.venue.street1 }}{{ (event.venue.street2 ? ', ' + event.venue.street2 : '') }}<br>
                  {{ event.venue.city }} {{ event.venue.state }}, {{ (event.venue.country ? event.venue.country.name : '' ) }} {{ event.venue.zip }}
              </address>
              <div :class="['event-details',{'collapsed':!details.show}]" v-html="event.description"></div>
            </div>
            <div class="col-md-2">
              <div class="panel-actions">
                <button @click.prevent="details.show = !details.show" class="btn btn-primary">{{ 'Details' | translate }}</button>
                <button v-if="isRegistrationOpen" @click.prevent="actions.showEvent(event.id)" class="btn btn-primary">{{ 'Register' | translate }}</button>
                <div v-else class="registration-closed-btn">{{ 'Registration Closed' | translate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  props: ['event','typeId','actions'],
  data(){
    return{
      details: {
        show: false,
        event: {}
      }
    }
  },
  created() {

  },
  mounted(){

  },
  computed:{
    /**
    * is Registration open?
    */
    isRegistrationOpen: function(){
      let vm = this;
      return (Moment().isSameOrAfter(vm.event.registration_opens_at) && Moment().isSameOrBefore(vm.event.registration_closes_at) && !vm.event.is_sold_out ? true : false);
    },
    displayYearMonth(){
      return ( Moment(this.event.starts_at).isValid() ? Moment(this.event.starts_at).format('MMM YYYY') : '');
    },
    displayDay(){
      return ( Moment(this.event.starts_at).isValid() ? Moment(this.event.starts_at).format('Do') : '')
    },
    displayPrice(){
      let vm = this;
      let thisPrice = null;

      if( vm.event.registration_types && (vm.event.registration_types.length > 0) ){
        if( vm.typeId ){
          let thisType = vm.event.registration_types.find(function (obj) { return obj.id == vm.typeId });

          //console.log(thisType);

          if( thisType && thisType.pivot ){
            thisPrice = parseFloat(thisType.pivot.price);
          }
        }else{
          let thisType = vm.event.registration_types[0];

          if( thisType && thisType.pivot ){
            thisPrice = parseFloat(thisType.pivot.price);
          }
        }
        //Do something
      }

      return ( thisPrice > 0 ? '$' + thisPrice.toFixed(2).replace(/[^\d\.]/g, "") : 'FREE' );
    }
  },
  watch:{

  },
  methods:{
    returnDate(thisDate){
      return ( Moment(thisDate).isValid() ? Moment(thisDate).format('MMM Do YYYY - h:mm a') : '');
    },
  },
  components:{

  }
}
</script>

<style lang="scss">
.registration-closed-btn{
  text-align: center;
  text-transform: uppercase;
  color: #ccc;
}
</style>
