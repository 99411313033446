<template>
  <div :class="['modal','fade']">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click.prevent="cancel()"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" >{{ 'Send Payment Reminder' | translate }}</h4>
        </div>
        <div class="modal-body">
          <payment-reminder-editor :email="email" :event-id="config.eventId" :registrar="registrar" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click.prevent="cancel()">{{ 'Close' | translate }}</button>
          <button type="button" class="btn btn-success" @click.prevent="send()">{{ 'Send Message' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
    props: ['config','actions', 'registrar'],
    data(){
      return{
        email: {
          subject: '',
          body: '',
          loremIpsum: `<p>Start typing to see the body of your email here...</p>`
        },
      }
    },
    created() {

    },
    mounted(){
      this.initilizeModal();
    },
    computed:{

    },
    watch:{

    },
    methods:{
      initilizeModal: function(){
        $(this.$el).modal('show');
      },
      cancel: function(){
        let vm = this;

        $(this.$el).modal('hide');
        vm.actions.close();
      },
      send: function(){
        let vm = this;

        let data = {
          group: 'all-unpaid',
          eventId: vm.config.eventId,
          subject: vm.email.subject,
          body: vm.email.body,
          template: 'payment-reminder',
        }

        console.log(data);

        //Preform Axios Submmision
        this.axios.post('/event-mail/admin/store', data)
        .then( response => {
          console.log(response);
        }).catch( error => {
          console.log(error);
          console.log(`%c Paid Failed`,'color: red')
        });

        console.log('Message Sent');
        $(this.$el).modal('hide');
        vm.actions.close();
      }
    },
    components:{
      'payment-reminder-editor': require('../../email/payment-reminder-editor.vue').default,
    }
}
</script>

<style lang="scss">
.modal {
  overflow-y:auto;
}
.modal-lg {
  @media (min-width: 992px){
    width: 1300px;
  }
}
.modal-title-highlight{
  color: #26313f;
}
</style>
