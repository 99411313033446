<template>
  <div class="input-group date-time-combo">
    <span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>
    <datepicker v-if="!combo.updating" v-model="combo.date" :input-class="'form-control datapicker-bg'" :maximumView="'year'" :initialView="'day'" :disabledDates="combo.disabledDates" />
    <span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
    <vue-timepicker v-if="!combo.updating" v-model="combo.time" format="hh:mm A"></vue-timepicker>
  </div>
</template>

<style>
.input-group.date-time-combo .vdp-datepicker, .input-group.date-time-combo .time-picker{
  display: table-cell;
}
</style>

<script>
import Datepicker from '@vuejs/vendor/vue-datepicker/vuejs-datepicker.min.js';

export default{
  template: '<date-time-combo></date-time-combo>',
  props: ['value','before','after'],
  data(){
      return{
        combo: {
          date: '',
          time: '',
          updating: true,
          disabledDates: {}
        }
      }
  },
  created() {

  },
  mounted(){
    console.log('test1');
    this.initilizeDateTime();
  },
  computed:{

  },
  watch:{
    'combo.date': function(){
      console.log('test2');
      let vm = this;
      if(!vm.combo.updating){
        vm.setDateTime();
      }
    },
    'combo.time': function(){
      console.log('test3');
      let vm = this;
      if(!vm.combo.updating){
        vm.setDateTime();
      }
    },
    'before': function(val, oldVal){
      console.log('test4');
      let vm = this;

      if(vm.before && Moment(val).format() !== Moment(oldVal).format()){
        vm.$set(vm.combo,'date', '');
        vm.$set(vm.combo,'time', '');

        //console.log(`update OldVal = ${oldVal}, NewVal = ${val}`);

        vm.initilizeDateTime();
      }
    },
    'after': function(val, oldVal){
      console.log('test5');
      let vm = this;

      if(vm.after && Moment(val).format() !== Moment(oldVal).format() ){
        vm.$set(vm.combo,'date', '');
        vm.$set(vm.combo,'time', '');

        //console.log(`update OldVal = ${oldVal}, NewVal = ${val}`);
        vm.initilizeDateTime();
      }
    }
  },
  methods:{
    initilizeDateTime(){
      // console.log('initializing datetimes');
      let vm = this;

      vm.$set(vm.combo,'updating',true);
      // Is the datetime valid, if not create new datetime
      if( !Moment(vm.value).isValid ){
        // console.log(`Not valid ${vm.value}`);
        const thisDate = new Date();
        vm.$emit('input', thisDate );
      }
      console.log(vm.value);

      //Before date check
      if(vm.before && !Moment(vm.value).isSameOrBefore(vm.before) ){
        //console.log(`before ${vm.before}`);
        vm.$emit('input', Moment(vm.before).format() );
      }

      if(vm.before){
        vm.$set(vm.combo.disabledDates,'from', new Date(Moment(vm.before).format("YYYY-MM-DD")) );
      }

      //After date check
      if(vm.after && !Moment(vm.value).isSameOrAfter(vm.after) ){
        //console.log(`after ${vm.after}`);
        vm.$emit('input', Moment(vm.after).format() );
      }

      if(vm.after){
        vm.$set(vm.combo.disabledDates,'to', new Date(Moment(vm.after).format("YYYY-MM-DD")) );
      }


      vm.$nextTick(() => {
        if( Moment(vm.value).isValid ){
          console.log('is valid!!');
          vm.$set(vm.combo,'date', vm.value );
          vm.$set(vm.combo,'time', vm.value );
          vm.$set(vm.combo,'updating',false);
        }
      })

    },
    setDateTime(){
      let vm = this;

      if(!vm.combo.updating){
        let hour = Moment(vm.combo.time).format("H");
        let minute = Moment(vm.combo.time).format("m");
        const setDate = Moment(vm.combo.date).set({ 'hour' : hour, 'minute'  : minute }).format();

        vm.$emit('input', setDate );
      }
      vm.initilizeDateTime();
    }
  },
  components:{
    Datepicker,
    'vue-timepicker': require('../../time-picker/vue-timepicker.vue').default,
  }
}
</script>
