<template>
  <div class="pie-chart-wrapper">

    <div class="details">
      <dl class="dl-horizontal">
        <template v-for="slice in slices">
          <dt :class="[{'active':slice.active}]">{{ slice.name }}</dt>
          <dd :class="[{'active':slice.active}]">{{ slice.value }}%</dd>
        </template>
      </dl>
    </div>

    <div class="pie-chart" :style="{width: '100%'}">
      <div class="flex-wrapper">
        <div class="pie" :style="{width: '100%'}">
          <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
              <circle id="graph" r="15.9154943092" cx="16" cy="16" transform="rotate(-90 16 16)" />
              <mask id="clip"><use xlink:href="#graph" fill="#FFF" /></mask>
            </defs>
            <g v-for="(slice, index) in allSlices" class="graph" mask="url(#clip)" stroke-width="32">
              <use :class="'graph__percent graph__percent--' + slice.count" @mouseover="mouseEvent(true,slice.id)" @mouseout="mouseEvent(false,slice.id)" xlink:href="#graph" fill="none" :stroke="slice.color" :stroke-dasharray="`0 ${slice.offset} ${slice.value} 100`" />
              <text id="button_label" x="55" y="30" text-anchor="middle" font-size="5" fill="black" font-family="Verdana" content-value="param(label)">button</text>
            </g>
          </svg>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default{
  /**
  * reference: https://codepen.io/shshaw/pen/KpyNQq?editors=1111
  */
  //props: ['config'],
  data(){
      return{
        allSlices: [],
        size: 400,
        slices: [
          {id: 1, value: 60,color: '#ff9f00', name: 'Full Event', total: '600', active: false},
          {id: 2, value: 20,color: '#4CC790', name: 'Day 1', total: '200', active: false},
          {id: 3, value: 5,color: '#3c9ee5', name: 'Day 2', total: '50', active: false},
          {id: 4, value: 15,color: '#26313f', name: 'Day 3', total: '150', active: false},
        ],
      }
  },
  created() {

  },
  mounted(){
    this.createAllSlices();
  },
  computed:{

  },
  watch:{

  },
  methods:{
    mouseEvent(state,thisId){
      let vm = this;

      let key = vm.slices.indexOf( vm.slices.find(function (obj) { return obj.id == thisId }) );

      vm.$set(vm.slices[key],'active',state);
    },
    createAllSlices: function(){
      let vm = this;
      let offset = 0;

      for (var i=0; i < vm.slices.length; i++) {
        let thisSlice = vm.slices[i];
        let tempSlice = {};

        vm.$set(tempSlice,'id',thisSlice.id);
        vm.$set(tempSlice,'count',i);
        vm.$set(tempSlice,'color',thisSlice.color);
        vm.$set(tempSlice,'offset',offset);
        vm.$set(tempSlice,'value',thisSlice.value);

        vm.allSlices.push(tempSlice);

        offset = offset + parseFloat(thisSlice.value);
      }
    },
  },
  components:{

  }
}
</script>

<style lang="scss">
.pie-chart-wrapper{
  display: table;
  .details,.pie-chart{
    display: table-cell;
    vertical-align: middle;
  }
  .details{
    dt,dd{
      &.active{
        font-weight: bold;
        color: #5cb85c;
      }
    }
  }
  .pie-chart{
    max-width: 150px;
    .flex-wrapper {
      display: flex;
      flex-flow: row nowrap;
      .pie{
        width: 33%;
        justify-content: space-around;
        svg {
          display: block;
          position: relative;
          margin: 10px auto;
          width: 200px;
          .graph { visibility: hidden; }

          .graph > * {
            visibility: visible;
            transition: all 200ms ease-in-out;
          }

          .graph:hover > * { opacity: 0.5; }

          .graph__percent:hover {
            stroke: #5cb85c;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
