class Cart {

  constructor(){
    let scaffolding = this.formScaffolding();
    return scaffolding;
  }

  formScaffolding(){
    const scaffolding = {
      price: {
        value: null,
      },
      type: {
        value: null, // Default For Development
      },
      student:{
        id: null,
      },
      total: {
        html: 'Empty',
        calc: 0.00,
        retrieving: false,
        que: 0
      },
      paymentMethod: {
        value: null,
        stripe: {
          cardName: null,
          token: null,
        },
        options: [
          { id: 1, name: 'Credit Card'},
          { id: 2, name: 'Cheque'},
          { id: 3, name: 'Money Order'},
          // { id: 4, name: 'eTransfer'},
        ],
        billing: {
          value: true, // Use Contact Information
          address: {
            phone: {value: null},
            street1: {value: null},
            city: {value: null},
            state: {value: null},
            zip: {value: null},
          },
        }
      }
    }

    return scaffolding;
  }

}

export default Cart;
