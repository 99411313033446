<template>
  <div>
    <transition name="slide-fade">
      <template v-if="!thisEvent.eventId">

        <div class="row">
          <div class="page-title">
            {{ 'Upcoming Events' | translate }}
          </div>

          <div class="col-md-12"><hr></div>

          <template v-if="events.length">
            <div v-for="event in events" class="col-md-12">
              <event-listing :event="event" :typeId="typeId" :actions="{showEvent}" />
            </div>
            <vue-loader :show="settings.loading" :message="settings.message" :overlay="false"></vue-loader>
          </template>
          <template v-else>
            <vue-loader :show="settings.loading" :message="settings.message" :overlay="true"></vue-loader>
            <template v-if="!settings.loading">
              <div class="col-md-12">
                {{ 'No Events' | translate }}
              </div>
            </template>
          </template>
        </div>

      </template>
    </transition>

    <transition name="slide-fade">
      <template v-if="thisEvent.eventId">
        <event-full :configuration="{eventId:thisEvent.eventId,closeEvent:closeEvent,userId:(configuration.userId ? configuration.userId : ''),member:(configuration.member ? configuration.member : '')}"></event-full>
      </template>
    </transition>

  </div>
</template>

<script>
export default{
  template: '<event-public-listing></event-public-listing>',
  props: ['configuration'],
  data(){
      return{
        loading: {
          isloading: false,
          message: 'Loading Events'
        },
        events: [],
        thisEvent: {
          eventId: null,
          close: false,
          last: null,
        },
        settings: {
          loading: false,
          message: 'Loading Events',
          stop: false,
          page: 1,
          order: {
            value: 'starts_at',
            options: [
              'name','description','venue_id,price','tickets_total','tickets_sold','registration_opens_at','starts_at','ends_at','publish_at','created_at'
            ],
          },
          sort: {
            value: 'desc',
            options: ['asc','desc']
          }
        },
        typeId: null,
        userId: null,
      }
  },
  created() {
    let vm = this;
    /**
    * Back History Control
    **/
    window.onpopstate = function (event) {

      if (event.state) {
        console.log(`history changed because of pushState/replaceState`)
      } else {
        console.log(`history changed because of a page load`)
        vm.$set(vm.thisEvent,'close',true)
      }
    }

  },
  mounted(){
    this.fetch();
    window.addEventListener('scroll', this.infinity)
  },
  computed:{

  },
  watch:{
    /**
    * Back Button Pressed
    */
    'thisEvent.close': function(val, oldVal){
      let vm = this
      // Incase of double trigger of popstate
      if(val !== oldVal && val == true){
        vm.closeEvent()
      }
    },
  },
  methods:{
    /**
    * Get
    */
    fetch: _.throttle(function (reset = false) {
        let vm = this

        vm.settings.loading = true;

        let data = {
            page: vm.settings.page,
            orderBy: vm.settings.order.value,
            sort: vm.settings.sort.value,
        }

        vm.axios.post('/event/list', data)
        .then( response => {
          //console.log( response.data );
          if( response.data.data.length > 0 ){
              if(reset) {
                  vm.events = null

                  vm.$nextTick(function () {
                      vm.events = []
                  })
              }
              vm.$nextTick(function () {
                  vm.$nextTick(function () {
                      vm.events.push.apply(vm.events, response.data.data)
                      vm.settings.page ++
                      /* if( vm.settings.page == response.data.last_page){
                          vm.settings.stop = true
                      } */
                  })
              })
          } else {
              vm.settings.stop = true
          }

          vm.settings.loading = false
        })

        if( vm.configuration && vm.configuration.typeId ){
          vm.typeId = vm.configuration.typeId;
        }
    },1500 ),
    /**
    * Infinity Scroll
    */
    infinity() {
      let vm = this;
      let container = document.body;
      let scrollPercentage = (((container.scrollHeight - window.scrollY) / container.scrollHeight) * 100);

      if( scrollPercentage < 60 && !vm.thisEvent.eventId){
        if(!vm.settings.loading && !vm.settings.stop){
          vm.fetch();
        }
      }

    },
    /**
    * Display this event
    */
    showEvent(eventId){
      let vm = this;

      var stateObj = { foo: '/event/show/' + eventId };
      history.pushState(stateObj, 'Event', '/event/show/' + eventId);

      vm.$set(vm.thisEvent,'eventId',eventId)

      console.log(`Show Event ${eventId}`);
    },
    closeEvent(){
      let vm = this;
      vm.$set(vm.thisEvent,'close',false);
      vm.$set(vm.thisEvent,'eventId',null);
    }
  },
  components:{
    'vue-loader':     require('@vuejs/components/loader/loader.vue').default,
    'event-listing':  require('./event.vue').default,
    'event-full':     require('../event/index.vue').default,
  }
}
</script>

<style lang="scss">
$break-small: 991px;
$break-medium: 992px;
.content-wrapper{
  @media screen and (max-width: $break-small) {
    padding: 30px 0;
  }
}
.event-panel{

  .panel-heading {
    background-color: #26313f;
    border-color: #26313f;
  }

  .panel-body{
    .panel-actions {
        margin-top: 20px;
        .btn {
          display: block;
          width: 100%;
          margin-bottom: 0.5em;
        }
    }

    .event-details{
      @media screen and (min-width: $break-medium) {
        min-height: 150px;
      }
      p{
        line-height: 1.5;
        transition: all .5s ease-in-out;
        -webkit-transition: all .5s ease-in-out;
      }

      &.collapsed{
        p{
          opacity: 0;
          line-height: 0;
          transition: all .5s ease-in-out;
          -webkit-transition: all .5s ease-in-out;
          overflow: hidden;

          &:first-child{
            opacity: 1;
            line-height: 1.5;
            margin-bottom: 0;
            overflow: visable;
          }
        }

      }
    }

    .event-extra-details{
      min-height: 1px;
      line-height: 1.5;
      transition: all .5s ease-in-out;
      -webkit-transition: all .5s ease-in-out;
      &.collapsed{
        opacity: 0;
        line-height: 0;
        padding:0;
        margin:0;
        transition: all .5s ease-in-out;
        -webkit-transition: all .5s ease-in-out;
        overflow: hidden;
      }
    }

    .media-left{
      @media screen and (max-width: $break-small) {
        display: none;
      }
    }

  }
}

.cal-item{
  width: 100px;
  border: 1px solid #ddd;
  margin-top: 20px;
  margin-right: 1em;
  .cal-item-header {
    text-align: center;
    padding: 5px;
    color: #fff;
    background-color: #4bbca7;
  }
  .cal-item-body {
    text-align: center;
    padding: 10px 2px;
    font-size: 1.7em;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .4s ease;
}
.slide-fade-leave-active {
  transition: all 0s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter{
/* .slide-fade-leave-active below version 2.1.8 */
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-leave-to{
  opacity: 0;
}
</style>
