if(typeof Laravel !== 'undefined' && Laravel && Laravel.stripeKey){
  window.StripeKey = Laravel.stripeKey;
}else{
  window.StripeKey = null;
}

// Test
if( document.querySelector('[data-vue="module-test"]') ) {
    const moduleTest = new Vue({
        el: '[data-vue="module-test"]',
        components: {
            'module-test': require('./components/test/test.vue').default,
        }
    });
}

// Event Admin Registration [Create / Edit]
if( document.querySelector('[data-vue="event-admin-registration"]') ) {
    const EventAdminRegistration = new Vue({
        el: '[data-vue="event-admin-registration"]',
        components: {
            'event-admin-registration': require('./components/eventRegistration/index.vue').default,
        }
    });
}

// Event Registration Create
if( document.querySelector('[data-vue="event-public-listing"]') ) {
    const EventPublicListing = new Vue({
        el: '[data-vue="event-public-listing"]',
        components: {
            'event-public-listing': require('./components/eventListings/index.vue').default,
        }
    });
}

// Event Public
if( document.querySelector('[data-vue="event-public"]') ) {
    const EventPublic = new Vue({
        el: '[data-vue="event-public"]',
        components: {
            'event-public': require('./components/event/index.vue').default,
        }
    });
}

// Event Admin Overview
if( document.querySelector('[data-vue="event-admin-overview"]') ) {
    const EventAdminShow = new Vue({
        el: '[data-vue="event-admin-overview"]',
        components: {
            'event-admin-overview': require('./components/event-admin/admin-overview.vue').default,
        }
    });
}

// Event Admin List
if( document.querySelector('[data-vue="event-admin-list"]') ) {
    const EventsAdminList = new Vue({
        el: '[data-vue="event-admin-list"]',
        components: {
            'event-admin-list': require('./components/event-admin-list/index.vue').default,
        }
    });
}

// Event My Tickets
if( document.querySelector('[data-vue="event-my-tickets"]') ) {
    const EventMyTickets = new Vue({
        el: '[data-vue="event-my-tickets"]',
        components: {
            'event-my-tickets': require('./components/event-my-tickets/index.vue').default,
        }
    });
}
