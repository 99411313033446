<template>
  <div class="row event-contact-container">
    <h2>{{ 'Contact Details' | translate }}</h2>

    <div v-if="configuration.member" class="col-md-12">
      <address>
        <strong>{{ 'Member Address' | translate }}</strong>
        <dl>
          <dt v-if="configuration.member.street1">{{ configuration.member.street1 }}</dt>
          <dt v-if="configuration.member.street2">{{ configuration.member.street2 }}</dt>
          <dt>
            <span v-if="configuration.member.city" v-html="configuration.member.city + ','"></span>
            <span v-if="configuration.member.state && configuration.member.state.name_short" v-html="configuration.member.state.name_short + ','"></span>
            <span v-if="configuration.member.country && configuration.member.country.name_short" v-html="configuration.member.country.name_short"></span>
            <span v-if="configuration.member.zip" v-html="configuration.member.zip + '<br>'"></span>
          </dt>
          <dt v-if="configuration.member.phone">Phone: {{ configuration.member.phone }}</dt>
          <dt v-if="configuration.member.email">Email: {{ configuration.member.email }}</dt>
        </dl>
      </address>
    </div>
    <template v-else>
      <div class="form-group col-md-6">
        <label class="full-label" for="first-name">
          {{ 'First Name' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <input v-model="contactForm.form.firstName.value" name="first-name" type="text" class="form-control" placeholder="First Name" v-on:blur="validate" v-on:keyup="validate">
      </div>
      <div class="form-group col-md-6">
        <label class="full-label" for="last-name">
          {{ 'Last Name' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <input v-model="contactForm.form.lastName.value" name="last-name" type="text" class="form-control" placeholder="Last Name" v-on:blur="validate" v-on:keyup="validate">
      </div>

      <div class="form-group col-md-6">
        <label class="full-label" for="email">
          {{ 'Email' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <input v-model="contactForm.form.email.value" name="email" type="text" class="form-control" placeholder="Email" v-on:blur="validate" v-on:keyup="validate">
      </div>
      <div class="form-group col-md-6">
        <label class="full-label" for="phone">
          {{ 'Phone' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <input v-model="contactForm.form.phone.value" name="phone" type="text" class="form-control" placeholder="Phone" v-on:blur="validate" v-on:keyup="validate">
      </div>

      <div class="form-group col-md-6">
        <label class="full-label" for="street1">
          {{ 'Street Address' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <input v-model="contactForm.form.street1.value" name="street1" type="text" class="form-control" placeholder="Street Address" v-on:blur="validate" v-on:keyup="validate">
      </div>
      <div class="form-group col-md-6">
        <label class="full-label" for="city">
          {{ 'City' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <input v-model="contactForm.form.city.value" name="city" type="text" class="form-control" placeholder="City" v-on:blur="validate" v-on:keyup="validate">
      </div>

      <div class="form-group col-md-6">
        <label class="full-label" for="state">
          {{ 'Province' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <input v-model="contactForm.form.state.value" name="state" type="text" class="form-control" placeholder="Province" v-on:blur="validate" v-on:keyup="validate">
      </div>
      <div class="form-group col-md-6">
        <label class="full-label" for="zip">
          {{ 'Postal Code' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <input v-model="contactForm.form.zip.value" name="zip" type="text" class="form-control" placeholder="Postal Code" v-on:blur="validate" v-on:keyup="validate">
      </div>


    </template>

    <div class="form-group col-md-6 alert-label">
      <label class="standalone-label">{{ 'I want to receive event alerts by:' | translate }}</label>
    </div>
    <div class="col-md-6 alert-options">
      <div class="radio">
        <label v-for="(cm, index) in contactForm.method.options" :key="cm.id" class="radio-inline">
            <input type="radio" name="contactMethod" :id="'contactMethod-'+cm.id" :value="cm.id" checked v-model="contactForm.method.value" >
            {{ cm.name }}
        </label>
      </div>
    </div>

    <div class="col-md-12"><hr></div>
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";

export default{
    template: '<event-contact-form></event-contact-form>',
    props: ['contact-form','configuration'],
    data(){
        return{

        }
    },
    created() {

    },
    mounted(){
      this.initilize();
    },
    computed:{

    },
    watch:{

    },
    methods:{
      /**
      * Initilize Components
      */
      initilize(){
        let vm = this;
        vm.$set(vm.contactForm.method,'value',1);
      },
      validate: function (event) {
        switch (event.target.getAttribute('name')) {
          case 'first-name':
          case 'last-name':
          case 'street1':
          case 'city':
          case 'state':
          case 'zip':
            if(event.target.value != ''){
              event.target.parentElement.classList.remove('has-error')
            } else if(event.type !== 'keyup') {
              event.target.parentElement.classList.add('has-error');
            }
            break;
          case 'email':
            if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)){
              event.target.parentElement.classList.remove('has-error')
            } else if(event.type !== 'keyup') {
              event.target.parentElement.classList.add('has-error');
            }
            break;
          case 'phone':
            if(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(event.target.value)){
              event.target.parentElement.classList.remove('has-error')
            } else if(event.type !== 'keyup') {
              event.target.parentElement.classList.add('has-error');
            }
            break;
        }
      }
    },
    components:{

    }
}
</script>

<style lang="scss">
$break-small: 991px;
$break-medium: 992px;
.alert-label{
  @media screen and (min-width: $break-medium) {
    text-align: right;
  }
  @media screen and (max-width: $break-small) {
    text-align: center;
  }
}
.alert-options{
  @media screen and (max-width: $break-small) {
    text-align: center;
  }
}
.event-contact-container{
  h2 {
    text-align: center;
    text-transform: none;
  }

  address{
    display: block;
    padding: 1em;
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ddd;
    strong{
      font-size: 1.5em;
    }
    dl{
      margin: 0;
    }
  }
}
.error-message{
  font-style: italic;
  font-size: smaller;
}
</style>
