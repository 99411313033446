<template>
  <div :class="['modal','fade']">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click.prevent="cancel()"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" >{{ 'Cancel ticket' | translate }}</h4>
        </div>
        <div class="modal-body">
          <p>{{ 'You are about to cancel' | translate }} <b>{{ config.registrant.first_name }} {{ config.registrant.last_name }} {{ 'ticket.' | translate}}</b></p>
          <p>{{ 'This ticket will be returned to ticket pool.' | translate }}</p>
          <p>{{ 'Please confirm cancelation of this ticket.' | translate }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click.prevent="cancel()">{{ 'Cancel' | translate }}</button>
          <button type="button" class="btn btn-danger" @click.prevent="confirmCancelation()">{{ 'Confirm Cancelation' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.modal {
  overflow-y:auto;
}
</style>

<script>
export default{
    props: ['config','actions'],
    data(){
      return{

      }
    },
    created() {

    },
    mounted(){
      this.initilizeModal();
    },
    computed:{

    },
    watch:{

    },
    methods:{
      initilizeModal: function(){
        $(this.$el).modal('show');
      },
      cancel: function(){
        let vm = this;

        $(this.$el).modal('hide');
        vm.actions.close();
      },
      confirmCancelation: function(){
        let vm = this;
        $(this.$el).modal('hide');
        vm.actions.remove(vm.config.registrant.id);
      },
    },
    components:{

    }
}
</script>
