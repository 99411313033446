<template>
  <div>
    <div class="box-header mb-2e">
      <h1 class="box-title">{{ 'Event Details' | translate }}</h1>
    </div>
    <div class="row">
      <div class="form-group col-md-8">
        <label class="full-label" for="event-name">
          {{ 'Event Name' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <input v-model="details.name" name="event-name" type="text" class="form-control" placeholder="Event Name">
      </div>

      <div class="form-group col-md-4">
        <label  class="full-label"  for="type">
          {{ 'Type' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <multiselect v-model="details.type.value" :options="details.type.options" track-by="id" label="name" :multiple="true" :show-labels="false" :searchable="true"></multiselect>
      </div>
    </div>

    <div class="form-group">
      <label class="full-label" for="venue">
        {{ 'Venue' | translate }}
        <small class="pull-right text-success">{{ '* required' | translate }}</small>
      </label>
      <div class="row">
        <div class="col-md-10">
          <multiselect v-model="details.venue.value" :options="details.venue.options" track-by="id" label="name" :show-labels="false" :searchable="true" :taggable="true" @tag="venueModel" tag-placeholder="Add a Venue">

            <template slot="singleLabel" slot-scope="props">
              <div v-if="props.option && props.option.name" class="w-100">
                  <h5 class="mb-3">{{ (props.option.name ? props.option.name : '') }}</h5>
                  <div class="row">
                    <div class="col-md-6">
                      <dl class="dl-horizontal">
                        <dt v-if="props.option.street1">{{ 'Street Address:' | translate }}</dt>
                        <dd v-if="props.option.street1">{{ props.option.street1 }}</dd>
                        <dt v-if="props.option.street2">{{ 'Street Extended:' | translate }}</dt>
                        <dd v-if="props.option.street2">{{ props.option.street2 }}</dd>
                        <dt v-if="props.option.city">{{ 'City:' | translate }}</dt>
                        <dd v-if="props.option.city">{{ props.option.city }}</dd>
                        <dt v-if="props.option.country && props.option.country.name">{{ 'Country:' | translate }}</dt>
                        <dd v-if="props.option.country && props.option.country.name">{{ props.option.country.name }}</dd>
                        <dt v-if="props.option.state">{{ 'Province:' | translate }}</dt>
                        <dd v-if="props.option.state">{{ props.option.state }}</dd>
                        <dt v-if="props.option.zip">{{ 'Postal Code:' | translate }}</dt>
                        <dd v-if="props.option.zip">{{ props.option.zip }}</dd>
                      </dl>
                    </div>
                    <div class="col-md-6">
                      <div v-html="props.option.description"></div>
                    </div>
                  </div>
              </div>
            </template>

            <template slot="option" slot-scope="props">
              <div v-if="props.option.name" class="w-100">
                  <h5 class="mb-3">{{ (props.option.name ? props.option.name : '') }}</h5>
                  <div class="row">
                    <div class="col-md-6">
                      <dl class="dl-horizontal">
                        <dt v-if="props.option.street1">{{ 'Street Address:' | translate }}</dt>
                        <dd v-if="props.option.street1">{{ props.option.street1 }}</dd>
                        <dt v-if="props.option.street2">{{ 'Street Extended:' | translate }}</dt>
                        <dd v-if="props.option.street2">{{ props.option.street2 }}</dd>
                        <dt v-if="props.option.city">{{ 'City:' | translate }}</dt>
                        <dd v-if="props.option.city">{{ props.option.city }}</dd>
                        <dt v-if="props.option.country && props.option.country.name">{{ 'Country:' | translate }}</dt>
                        <dd v-if="props.option.country && props.option.country.name">{{ props.option.country.name }}</dd>
                        <dt v-if="props.option.state">{{ 'Province:' | translate }}</dt>
                        <dd v-if="props.option.state">{{ props.option.state }}</dd>
                        <dt v-if="props.option.zip">{{ 'Postal Code:' | translate }}</dt>
                        <dd v-if="props.option.zip">{{ props.option.zip }}</dd>
                      </dl>
                    </div>
                    <div class="col-md-6">
                      <div v-html="props.option.description"></div>
                    </div>
                  </div>
              </div>
              <span v-else slot="noResult">{{ 'Oops! No venue found. Press Enter to add one.' | translate }}</span>
            </template>

          </multiselect>
        </div>
        <div class="col-md-2">
          <button @click.prevent="venueModel()" class="btn btn-primary btn-fill">{{ 'Add A Venue' | translate }}</button>
        </div>
      </div>
      <element v-if="newVenue.modal">
          <add-venue :venue="details.venue" :scaffolding="venuScaffolding" :new-venue="newVenue" />
      </element>
    </div>

    <div class="form-group">
      <label for="event-description" class="full-label">
        {{ 'Description' | translate }}
        <small class="pull-right text-success">{{ '* required' | translate }}</small>
      </label>
      <vue-editor v-model="details.description" :editor-options="editor.options" :editorToolbar="editor.customToolbar" name="event-description"></vue-editor>
    </div>

    <div class="form-group">
      <label for="event-start-date" class="full-label">
        {{ 'Start / End Date' | translate }}
        <small class="pull-right text-success">{{ '* required' | translate }}</small>
      </label>
      <div class="row">
        <div class="col-md-6">
          <date-time-combo v-model="details.startDate" />
        </div>
        <div class="col-md-6">
          <date-time-combo v-model="details.endDate" :after="details.startDate" />
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="event-register-start-date" class="full-label">
        {{ 'Register Start / End Date' | translate }}
        <small class="pull-right text-success">{{ '* required' | translate }}</small>
      </label>
      <div class="row">
        <div class="col-md-6">
          <date-time-combo v-model="details.registerDateStart" />
        </div>
        <div class="col-md-6">
          <date-time-combo v-model="details.registerDateEnd" :after="details.registerDateStart" />
        </div>
      </div>
    </div>

    <div class="row">

      <div class="form-group col-md-6">
        <label for="event-publish-date" class="full-label">
          {{ 'Publish Date' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <date-time-combo v-model="details.publishDate" />
      </div>

      <div class="col-md-6">
      </div>

    </div>

    <div class="row">
      <div class="form-group col-md-6">
        <label class="full-label" for="price">
          {{ 'Tickets' | translate }}
          <small class="pull-right text-success">{{ '* required' | translate }}</small>
        </label>
        <datamask-decimal v-model="details.ticketsTotal" :decimal="0" :classes="'form-control'"></datamask-decimal>
      </div>

      <div class="form-group col-md-6">
        <label for="event-publish-date">{{ 'Early Bird' | translate }}</label>
        <date-time-combo v-model="details.earlyBirdDate" />
      </div>

    </div>

    <div class="text-right">
      <button @click.prevent="nextStep()" class="btn btn-primary" :disabled="!canProceed">{{ 'Next add Prices' | translate }}</button>
    </div>
  </div>
</template>

<script>
/** OLD Price
<label class="full-label" for="price">
  {{ 'Price' | translate }}
  <small class="pull-right text-success">{{ '* required' | translate }}</small>
</label>
<div class="input-group">
  <span class="input-group-addon">$</span>
  <datamask-decimal v-model="details.price" :decimal="2" :classes="'form-control'"></datamask-decimal>
</div>
*/

import { VueEditor, Quill } from "vue2-editor";

export default{
    template: '<event-create-details></event-create-details>',
    props: ['details','prices','venu-scaffolding'],
    data(){
        return{
          editor: {
            customToolbar: [
              ['bold', 'italic', 'underline'],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ],
            options: {
              bounds: '.form-group'
            }
          },
          newVenue: {
              modal: false,
              name: '',
          },
        }
    },
    created() {
      history.pushState(null, null, location.href)
    },
    mounted(){
      this.scrollToTop();
      //this.fetchVenues();
    },
    computed:{
      canProceed(){
        let proceed = true;
        if( !this.details.name || !this.details.type.value.length || !this.details.venue.value || !this.details.description
          || !this.details.ticketsTotal || !this.details.startDate || !this.details.endDate || !this.details.registerDateStart
          || !this.details.registerDateEnd || !this.details.publishDate ){
          proceed = false
        }

        return proceed;
      }
    },
    watch:{

    },
    methods:{
      scrollToTop(){
        $('html,body').stop().animate({
          scrollTop: 0
        }, 'slow', 'swing');
      },
      /**
      * Get Venues
      */
      fetchVenues: _.throttle( function () {
        let vm = this

        //Clear Data
        vm.$set(vm.details.venue.options,'options',[])
        // Get Actual data via axios
        vm.axios.get('/event-venue/admin/list')
        .then( response => {
          vm.details.venue.options.push.apply(vm.details.venue.options, response.data);

          if(vm.details.preset && vm.details.preset.venueId){
            vm.$set(vm.details.venue,'value', vm.details.venue.options.find(function (obj) { return obj.id == vm.details.preset.venueId }) );
          }
          //console.log(response.data)
        }).catch( error => {
          console.log('Error Fetch Venue')
          // Preform Error Function
        });

        vm.fetchType();
      },400 ),
      /**
      * Get Types
      */
      fetchType: _.throttle( function () {
        let vm = this

        //Clear Data
        vm.$set(vm.details.type,'options',[])
        vm.$set(vm.details.type,'value',[])
        // Get Actual data via axios
        vm.axios.get('/registration-type/list')
        .then( response => {
          vm.details.type.options.push.apply(vm.details.type.options, response.data)
          // Select Pre-sets
          if(vm.details.preset && vm.details.preset.registration_types){
            let typeArray = [];
            vm.details.preset.registration_types.forEach(function(element) {
              //typeArray.push(element.id);
              vm.details.type.value.push.apply(vm.details.type.value, _.filter(vm.details.type.options, function(obj) { return element.id == obj.id } ));
            })

            //console.log(typeArray);

            //vm.details.type.value.push.apply(vm.details.type.value, _.filter(vm.details.type.options, function(obj) { return typeArray.indexOf(obj.id) } ));
            //vm.details.type.value.push.apply(vm.details.type.value, _.filter(vm.details.type.options, function(obj) { return typeArray.indexOf(obj.id) } ));
          }
          //console.log(response.data)
        }).catch( error => {
          // Preform Error Function
        });
      },400 ),
      venueModel(value){
        this.newVenue.modal = true;
        this.newVenue.name = value;
      },
      nextStep(){
        let vm = this;

        vm.$set(vm.details,'finished',true);
      }
    },
    components:{
      Multiselect,
      DatamaskDecimal,
      VueEditor,
      'add-venue': require('./add-venue.vue').default,
      'date-time-combo': require('./date-time-combo.vue').default,
      'vue-timepicker': require('../../time-picker/vue-timepicker.vue').default,
    }
}
</script>

<style lang="scss">
.btn-fill{
  width: 100%;
}
.vdp-datepicker {
  display: inline-block;
  .datapicker-bg{
    background: #fff;
  }
}
.quillWrapper {
  background: #fff;
}
.multiselect__option--highlight h5{
  color: #fff;
}
.multiselect:hover {
  z-index: 9;
}
</style>
