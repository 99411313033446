  <template>
  <div class="row">
    <div class="box-header mb-2e">
      <h1 class="box-title">
        {{ 'Event Add-Ons' | translate }}
      </h1>
      <div class="header-back">
        <a @click.prevent="goBack()" type="button" class="btn btn-default">◀ {{ 'Back' | translate }}</a>
      </div>
    </div>

    <div class="col-md-12">
      <!--<p class="text-center page-sub-title">{{ 'addons.description' | translate }}</p>-->
      <p class="text-center"><button @click.prevent="newAddon()" type="button" :class="['btn btn-primary']">{{ 'Add Add-On' | translate }}</button></p>
    </div>

    <div class="col-md-12"><hr></div>

    <div v-if="!processing" class="col-md-12 rule-wrapper">
      <element v-if="addons.value.length">
        <div v-for="(addon, index) in addons.value" :key="addon.tempId" :class="['row','rule-object','bg-rule-object']">
          <div class="col-md-10 rule-pad">
            <add-on :addon="addon" :index="index" :details="details" :addons="addons.value" :key="addon.tempId" />
          </div>
          <div class="col-md-2 rule-cancel">
            <a @click.prevent="removeAddon(addon.tempId)" class="btn btn-primary pull-right showcursor">
              <span class="glyphicon glyphicon-remove-circle glyphicon-push" aria-hidden="true"></span>
            </a>
            <div class="clearfix"></div>
          </div>
        </div>
      </element>
      <div v-else class="no-rules">
        <span class="text-center text-muted">{{ 'Create a New Add-On.' | translate }}</span>
      </div>
    </div>

    <div>
      <button type="submitFunction" :class="['btn btn-primary pull-right']" :disabled="!canProceed">{{ 'Save Event' | translate }}</button>
    </div>

  </div>
</template>

<script>
export default{
    template: '<event-create-details></event-create-details>',
    props: ['addons','submit-function','details'],
    data(){
        return{
          processing: false
        }
    },
    created() {
      history.pushState(null, null, location.href)
    },
    mounted(){
      this.scrollToTop();
      //this.checkPresets();
    },
    computed:{
      canProceed: function(){
        let vm = this;
        let proceed = true;

        vm.addons.value.forEach(function(thisAddon) {
          if(!thisAddon.name || !thisAddon.description || !thisAddon.ticketsTotal){
            proceed = false;
          }
          thisAddon.registrationTypeIds.forEach(function(thisPrice) {
            if(thisPrice.price == null){
              proceed = false;
            }
          })
        })

        return proceed;
      },
    },
    watch:{

    },
    methods:{
      scrollToTop(){
        $('html,body').stop().animate({
          scrollTop: 0
        }, 'slow', 'swing');
      },
      newAddon(){
        let vm = this;
        // Create Key to track for move / deletion
        const randomId = Math.floor(Date.now() / 1000) + '-' + Math.random().toString(36).substr(2, 9);
        // Create copy of template
        let newAddon = Object.assign({}, vm.addons.scaffolding.overview);
        vm.$set(newAddon,'block',[]);
        vm.$set(newAddon,'tempId',randomId);

        vm.addons.value.push(newAddon)
      },
      /**
      * Remove by ID
      */
      removeAddon(tempId){
        let vm = this,
            thisAddonKey = vm.addons.value.indexOf( vm.addons.value.find(function (obj) { return obj.tempId == tempId }) );
        //clear Blocks
        vm.addons.value.forEach(function(thisAddon) {
          let blockKey = -1
          if(thisAddon.block.length){
            blockKey = thisAddon.block.indexOf(thisAddon.block.find(function (obj) { return obj.tempId == tempId }));
          }
          if(blockKey > -1){
            thisAddon.block.splice(blockKey, 1);
          }
        })

        vm.addons.value.splice(thisAddonKey, 1);
      },
      goBack(){
        Bus.$emit('back', true);
      }
    },
    components:{
      'add-on': require('./addon.vue').default,
    }
}
</script>

<style lang="scss">
.price-bucket, .block-bucket{
  display: block;
  margin-bottom: 2%;
  h4 {
    margin:0 0 5px 0;
    padding:0;
    font-size: 12pt;
    text-transform: none;
    font-weight: bold;
    line-height: 1.42857143;
    color: #333333;
  }

  .items {
    list-style: none;
    display: block;
    margin:0;
    padding:0;
    .item{
      list-style: none;
      display: block;
      padding: 0.5em;
      margin:0;
      background-color: rgba(255,255,255,0.4);
      border: 1px solid #ccc;
      &:nth-child(even){
        background-color: rgba(0,0,0,0.02);
      }
      &:not(:first-child){
        border-top: none;
      }
      .price-type-name {
        font-weight: bold;
        margin-right: 1em;
        display: inline-block;
        width: 100px;
      }
      .price-type-title{

      }
      .inline-block {
        display: inline-block;
        margin-right: 1em;
      }
    }

  }
}
</style>
