<template>

      <div>
        <h2 class="m-t-0">{{ addon.name }}</h2>

        <div class="media">
          <div class="media-left">
            <div class="cal-item">
              <div class="cal-item-header">{{ returnYearMonth(addon.starts_at) }}</div>
              <div class="cal-item-body">{{ returnDay(addon.starts_at) }}</div>
            </div>
          </div>
          <div class="media-body">
            <div class="label label-primary time-stamp">
              <span class="glyphicon glyphicon-time" aria-hidden="true"></span>
              {{ returnStart(addon.starts_at) }}
            </div>
            <span class="badge">to</span>
            <div class="label label-primary time-stamp">{{ returnEnd(addon.ends_at) }}</div>
            <div v-html="addon.description"></div>
          </div>
        </div>

        <div>
          <button v-if="!addon.inCart" @click.prevent="actions.handdleCartAddons(addon.id)" class="btn btn-primary pull-right">
            Add to Cart
            <span class="glyphicon glyphicon-shopping-cart" aria-hidden="true"></span>
            <span class="badge" v-html="displayPrice"></span>
          </button>
          <div v-else class="text-right">
            <span class="label label-success">in cart</span>
          </div>

          <div class="clearfix"></div>
        </div>

      </div>

</template>

<script>
export default{
  props: ['addon','typeId','actions'],
  data(){
      return{

      }
  },
  created() {

  },
  mounted(){

  },
  computed:{
    displayPrice(){
      let vm = this;
      let thisPrice = null;

      if( vm.addon.registration_types && (vm.addon.registration_types.length > 0) ){
        if( vm.typeId ){
          let thisType = vm.addon.registration_types.find(function (obj) { return obj.id == vm.typeId });

          //console.log(thisType);

          if( thisType && thisType.pivot ){
            thisPrice = parseFloat(thisType.pivot.price);
          }
        }else{
          let thisType = vm.addon.registration_types[0];

          if( thisType && thisType.pivot ){
            thisPrice = parseFloat(thisType.pivot.price);
          }
        }
        //Do something
      }

      return ( thisPrice > 0 ? '$' + thisPrice.toFixed(2).replace(/[^\d\.]/g, "") : 'FREE' );
    }
  },
  watch:{

  },
  methods:{
    returnYearMonth(thisDate){
      return ( Moment(thisDate).isValid() ? Moment(thisDate).format('MMM YYYY') : '');
    },
    returnDay(thisDate){
      return ( Moment(thisDate).isValid() ? Moment(thisDate).format('Do') : '')
    },
    returnStart(thisDate){
      return ( Moment(thisDate).isValid() ? Moment(thisDate).format('MMM Do YYYY - h:mm a') : '');
    },
    returnEnd(thisDate){
      return ( Moment(thisDate).isValid() ? Moment(thisDate).format('MMM Do YYYY - h:mm a ') : '');
    },
  },
  components:{

  }
}
</script>
