<template>
  <div>
    <vue-loader :show="loading.isloading" :message="loading.message" :overlay="true"></vue-loader>

    <div v-if="event" class="row event-details-wrapper">
      <div class="row event-header add-padding">
        <div class="col-md-6">
          <h1>{{ event.name }}</h1>
        </div>
        <div class="col-md-6 btns">
          <div class="btn-group" role="group">
            <button class="btn btn-primary" @click.prevent="show.details = !show.details" type="button">{{ 'Show Details' | translate }}</button>
            <a :href="'/event/admin/edit/' + event.id" class="btn btn-primary">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
              {{ 'Edit' | translate }}
            </a>
            <button @click.prevent="modals.confirmDelete = !modals.confirmDelete" type="button" class="btn btn-danger">
              <span class="glyphicon glyphicon-ban-circle" aria-hidden="true"></span>
              {{ 'Delete' | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-8">

        <div class="media">
          <div class="media-left">
            <div class="cal-item">
              <div class="cal-item-header">{{ displayYearMonth }}</div>
              <div class="cal-item-body">{{ displayDay }}</div>
            </div>
          </div>
          <div class="media-body">

            <!-- Time Overview Begin -->
            <div>
              <h3 class="darker">
                <span class="glyphicon glyphicon-time" aria-hidden="true"></span>
                {{ 'Time Overview' | translate }}
              </h3>
              <dl class="dl-horizontal">
                <dt>{{ 'Start / End' | translate }}</dt>
                <dd>{{ dualFullDateTime(event.starts_at,event.ends_at) }}</dd>

                <dt>{{ 'Registration' | translate }}</dt>
                <dd>{{ dualFullDateTime(event.registration_opens_at,event.registration_closes_at) }}</dd>

                <dt>{{ 'Early Bird Registration' | translate }}</dt>
                <dd>{{ dualFullDateTime(event.registration_opens_at,event.early_bird_ends_at) }}</dd>
              </dl>
            </div>
            <!-- Time Overview End -->

            <!-- Pass Types Begin -->
            <div>
              <h3 class="darker">{{ 'Pass Types' | translate }}</h3>
              <div v-if="event.prices" v-for="thisPrice in event.prices">
                <h4>{{ thisPrice.name }}</h4>
                <dl class="dl-horizontal">
                  <template v-for="regType in thisPrice.registration_types">
                    <dt>
                      {{ regType.name }}
                    </dt>
                    <dd>
                      <b style="margin-right: 0.5em;">{{ (regType.pivot && regType.pivot.price ? '$' + regType.pivot.price : 'FREE' ) }}</b>
                      {{ (regType.pivot && regType.pivot.early_bird_price ? '[$' + regType.pivot.early_bird_price + ']' : '' ) }}
                    </dd>
                  </template>
                  <dt>{{ 'Start / End' | translate }}</dt>
                  <dd>{{ dualFullDateTime(thisPrice.starts_at,thisPrice.ends_at) }}</dd>
                </dl>
              </div>
            </div>
            <!-- Pass Types End -->


            <div :class="['details-container']" style="height: 0px;">
              <div>
                <h3 class="darker">{{ 'Venue' | translate }}</h3>
                <address v-if="event.venue.street1">
                    <strong>{{ event.venue.name }}</strong><br>
                    {{ event.venue.street1 }}{{ (event.venue.street2 ? ', ' + event.venue.street2 : '') }}<br>
                    {{ event.venue.city }} {{ event.venue.state }}, {{ (event.venue.country ? event.venue.country.name : '' ) }} {{ event.venue.zip }}
                </address>
                <address v-else>
                  <strong>{{ event.venue.name }}</strong><br>
                  hosted online
                </address>
              </div>

              <div>
                <h3 class="darker">{{ 'Description' | translate }}</h3>
                <div v-html="event.description"></div>
              </div>

              <div>
                <h3 class="darker">{{ 'Addons' | translate }}</h3>
                <div v-if="event.addons" class="list-group">
                  <div v-for="addon in event.addons" class="list-group-item">
                    <h2 class="m-t-0">{{ addon.name }}</h2>

                    <div class="media">
                      <div class="media-left">
                        <div class="cal-item">
                          <div class="cal-item-header">{{ returnYearMonth(addon.starts_at) }}</div>
                          <div class="cal-item-body">{{ returnDay(addon.starts_at) }}</div>
                        </div>
                      </div>
                      <div class="media-body">
                        <div>
                          <div class="label addon-header label-primary">
                            <span class="glyphicon glyphicon-time" aria-hidden="true"></span>
                            {{ returnDateTime(addon.starts_at) }}
                          </div>
                          <span class="badge">to</span>
                          <div class="label addon-header label-primary">
                            {{ returnDateTime(addon.ends_at) }}
                          </div>
                        </div>
                        <div v-html="addon.description"></div>
                      </div>
                    </div>

                    <div>
                      <dl class="dl-horizontal">
                        <template v-for="addonRegType in addon.registration_types">
                          <dt>
                            {{ addonRegType.name }}
                          </dt>
                          <dd>
                            <b style="margin-right: 0.5em;">{{ (addonRegType.pivot && addonRegType.pivot.price ? '$' + addonRegType.pivot.price : 'FREE' ) }}</b>
                            {{ (addonRegType.pivot && addonRegType.pivot.early_bird_price ? '[$' + addonRegType.pivot.early_bird_price + ']' : '' ) }}
                          </dd>
                        </template>
                      </dl>
                    </div>

                    <div>
                      <span class="text-bold">Addon Tickets Sold:</span> <span class="badge">{{ addon.tickets_sold }}</span> of <span class="badge">{{ addon.tickets_total }}</span>
                    </div>

                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>


      </div>

      <div class="col-md-4">
        <div class="price-widget-wrapper">
          <div class="total">
            <h4 class="text-right">{{ 'Total' | translate }}</h4>
            <div class="dollar-value">${{ parseFloat(event.total_registration_value).toFixed(2) }}</div>
            <div class="actual">
              <span class="title">{{ 'Paid' | translate }}</span>
              <span class="paid-value">${{ parseFloat(event.total_ticket_value).toFixed(2) }}</span>
            </div>
          </div>
        </div>

        <div class="ticket-sales-wrpper">
          <div class="details">
            <dl class="dl-horizontal">
              <dt>{{ 'Total Tickets' | translate }}</dt>
              <dd>{{ event.tickets_total }}</dd>
              <dt>{{ 'Tickets Sold' | translate }}</dt>
              <dd>{{ event.tickets_sold }}</dd>
              <dt>{{ 'Tickets Remaining' | translate }}</dt>
              <dd>{{ event.tickets_left }}</dd>
            </dl>
          </div>
          <div class="chart">
            <percentage-circle :config="{color: config.color, width: '100%', total: event.tickets_total, count: event.tickets_sold}" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loading.isloading && config.eventRegistrations && jqLoaded" class="data-card">
      <div class="box-header mb-1e">
        <h1 class="box-title">{{ 'Registrations' | translate }}</h1>
      </div>
      <div class="registrants-filters row">
        <div class="col-md-6">
          <div class="input-group date-filters">
            <span class="input-group-addon">From</span>
            <datepicker v-model="filters.startDate" :input-class="'form-control datapicker-bg'" :maximumView="'year'" :initialView="'day'" />
            <span class="input-group-addon">To</span>
            <datepicker v-model="filters.endDate" :input-class="'form-control datapicker-bg'" :maximumView="'year'" :initialView="'day'" />
            <span class="input-group-btn">
              <button @click.prevent="clearFilterDates()" class="btn btn-primary">{{ 'Clear' | translate }}</button>
            </span>
          </div>
        </div>
        <div class="col-md-6 text-right">
          <div role="group" class="btn-group pull-right">
            <button @click.prevent="paymentReminderShow()" type="button" class="btn btn-primary">
              <span class="glyphicon glyphicon-usd"></span>
              {{ 'Send Payment Reminder' | translate }}
            </button>
            <button @click.prevent="notificationMesasgeShow()" type="button" class="btn btn-success">
              <span class="glyphicon glyphicon-comment"></span>
              {{ 'Send Notification' | translate }}
            </button>
          </div>
        </div>
      </div>
      <registrations-dataTables :config="{eventId: config.eventId}" :registrations="config.eventRegistrations" :filters="filters" :actions="{showRegistrantMessage: showRegistrantMessage, showEditPaid: showEditPaid, showThisReg: showThisReg }" />
      <div class="clearfix"></div>
    </div>

    <div v-if="!loading.isloading && emails && jqLoaded" class="data-card">
      <div class="box-header mb-1e">
        <h1 class="box-title">{{ 'Sent Messages' | translate }}</h1>
      </div>
      <messages-datatable :config="{eventId: config.eventId}" :emails="emails" :actions="{showThisMessege: showThisMessege}" />
      <div class="clearfix"></div>
    </div>

    <!-- Modals -->
    <template v-if="modals.confirmDelete">
      <confirm-delete :config="{eventName: event.name }" :actions="{close: closeDelete,delete: deleteEvent}" />
    </template>

    <template v-if="modals.message.show">
      <registrant-message :config="{eventId: config.eventId, registrant: modals.message.registrant}" :actions="{close: registrantMesasgeClose}" :registrar="registrar" />
    </template>

    <template v-if="modals.notification">
      <send-notification :config="{eventId: config.eventId}" :actions="{close: notificationMesasgeClose}" :registrar="registrar" />
    </template>

    <template v-if="modals.paymentreminder">
      <send-payment-reminder :config="{eventId: config.eventId}" :actions="{close: paymentReminderClose}" :registrar="registrar" />
    </template>

    <template v-if="modals.viewReg.show">
      <registrant-view :config="{eventId: config.eventId, registrant: modals.viewReg.registrant, eventPrices: event.prices}" :actions="{close:closeThisReg,remove:showRemoveThisReg}" />
    </template>

    <template v-if="modals.removeReg.show">
      <registrant-remove :config="{eventId: config.eventId, registrant: modals.removeReg.registrant}" :actions="{close:closeRemoveReg,remove:removeThisReg}" />
    </template>

    <template v-if="modals.paid.show">
      <edit-paid :config="{eventId: config.eventId, registrant: modals.paid.registrant}" :actions="{close:closeEditPaid,confirm:confirmEditPaid}" />
    </template>

    <template v-if="modals.archivedMessage.show">
      <view-message :config="{eventId: config.eventId, messageId: modals.archivedMessage.messageId, message:modals.archivedMessage.message}" :actions="{close:closeThisMessage}" />
    </template>
  </div>
</template>

<script>
/**
* <email-editor />
* <test-component />
*/
import Datepicker from '@vuejs/vendor/vue-datepicker/vuejs-datepicker.min.js';

export default{
  props: ['config', 'registrar'],
  data(){
      return{
        event: null,
        emails: null,
        jqLoaded: false,
        modals: {
          confirmDelete: false,
          archivedMessage: {
            show: false,
            messageId: null,
            message: null,
          },
          paid: {
            show: false,
            regId: null,
            registrant: null,
          },
          message: {
            show: false,
            regId: null,
            registrant: null,
          },
          viewReg: {
            show: false,
            regId: null,
            registrant: null,
          },
          removeReg: {
            show: false,
            regId: null,
            registrant: null,
          },
          notification: false,
          paymentreminder: false
        },
        show: {
          details: false
        },
        loading: {
          isloading: true,
          message: 'Loading Event...'
        },
        filters:{
          startDate: null,
          endDate: null
        },
      }
  },
  created() {

  },
  mounted(){
    // Insure Jquery / External Libraries has loaded
    window.addEventListener('load', () => {
      this.jqLoaded = true;
    });
    // Load everything
    this.fetchEvent();
  },
  computed:{
    displayYearMonth(){
      return ( Moment(this.event.starts_at).isValid() ? Moment(this.event.starts_at).format('MMM YYYY') : '');
    },
    displayDay(){
      return ( Moment(this.event.starts_at).isValid() ? Moment(this.event.starts_at).format('Do') : '')
    },
    displayEventStart(){
      return ( Moment(this.event.starts_at).isValid() ? Moment(this.event.starts_at).format('MMM Do YYYY - h:mm a') : '');
    },
    displayEventEnd(){
      return ( Moment(this.event.ends_at).isValid() ? Moment(this.event.ends_at).format('MMM Do YYYY - h:mm a ') : '');
    }
  },
  watch:{
    'show.details': function(){
      if(this.show.details == true){
        $('.details-container').collapse('show');
      }else{
        $('.details-container').collapse('hide');
      }
    },
  },
  methods:{
    scrollToTop(){
      $('html,body').stop().animate({
        scrollTop: 0
      }, 'slow', 'swing');
      // Set Page Header
      $('.content-wrapper > .content-header > .page-title').text('Admin Manage Event');
    },
    fetchEvent: _.throttle( function () {
      let vm = this
      vm.$set(vm.loading,'isloading',true);
      // Move to top of Page
      vm.scrollToTop();
      // Get Actual data via axios
      vm.axios.get('/event/admin/show-ajax/' + vm.config.eventId)
      .then( response => {
        //console.log(response.data);
        vm.event = response.data;
        //vm.$set(vm.loading,'isloading', false);
        vm.fetchMessages();
      }).catch( error => {
        console.log('Error')
        vm.$set(vm.loading,'isloading', false);
        // Preform Error Function
      });
    },400 ),
    fetchMessages: _.throttle( function () {
      let vm = this
      vm.$set(vm.loading,'isloading',true);
      // Get Actual data via axios
      vm.axios.get('/event-mail/admin/list-ajax/' + vm.config.eventId)
      .then( response => {
        //console.log(response.data);
        vm.$set(vm.loading,'isloading', false);
        vm.emails = response.data;
        //vm.$set(vm.loading,'isloading', false);
      }).catch( error => {
        vm.$set(vm.loading,'isloading', false);
        console.log('Error')
        //vm.$set(vm.loading,'isloading', false);
        // Preform Error Function
      });
    },400 ),
/**
* Delete Event
*/
    showDelete(){
      let vm = this;
      vm.$set(vm.modals,'confirmDelete',true);
    },
    closeDelete(){
      let vm = this;
      vm.$set(vm.modals,'confirmDelete',false);
    },
    deleteEvent(){
      let vm = this;

      let data = {
        eventId: vm.event.id
      }

      console.log('event Deleted');

      this.axios.post('/event/admin/destroy', data)
      .then( response => {
        vm.closeDelete();
        window.location.replace('/event/admin/');
        //console.log(`%c Submit`,'color: green')
      }).catch( error => {
        //console.log(`%c Failure`,'color: red')
      });
    },
/**
* View Registrant
*/
    showThisReg: function(regId){
      let vm = this;
      if(regId){
        let thisRegistrant = vm.config.eventRegistrations.find(function (obj) { return obj.id == regId });
        if(thisRegistrant){
          vm.$set(vm.modals.viewReg,'registrant',thisRegistrant);
          vm.$set(vm.modals.viewReg,'regId',regId);
          vm.$set(vm.modals.viewReg,'show',true);
        }
      }
    },
    closeThisReg(){
      let vm = this;
      vm.$set(vm.modals.viewReg,'show',false);
      vm.$set(vm.modals.viewReg,'regId',null);
      vm.$set(vm.modals.viewReg,'registrant',null);
    },
    showRemoveThisReg: function(regId){
      let vm = this;
      vm.closeThisReg();
      if(regId){
        let thisRegistrant = vm.config.eventRegistrations.find(function (obj) { return obj.id == regId });
        if(thisRegistrant){
          vm.$set(vm.modals.removeReg,'registrant',thisRegistrant);
          vm.$set(vm.modals.removeReg,'regId',regId);
          vm.$set(vm.modals.removeReg,'show',true);
        }
      }
    },
    closeRemoveReg(){
      let vm = this;
      vm.$set(vm.modals.removeReg,'show',false);
      vm.$set(vm.modals.removeReg,'regId',null);
      vm.$set(vm.modals.removeReg,'registrant',null);
    },
// Working Here
    removeThisReg(regId){
      let vm = this;
      let thisKey = vm.config.eventRegistrations.indexOf( vm.config.eventRegistrations.find(function (obj) { return obj.id == regId }) );
      let thisRegistrant = vm.config.eventRegistrations[thisKey];

      let data = {
        eventRegistrationId: regId,
      }

      this.axios.post('/event-registration/admin/destroy', data)
      .then( response => {
        if(thisRegistrant){
          vm.config.eventRegistrations.splice(thisKey, 1);
          let updateThis = {
            id: regId
          }
          Bus.$emit('removeRow', updateThis);
        }else{
          console.log(`Failed`)
        }
      }).catch( error => {
        console.log(error);
        console.log(`%c Cancel Failed`,'color: red')
      });

      /* let updateThis = {
        id: regId
      }
      console.log(`Remove Registrant id: ${regId}, key: ${thisKey}`);
      Bus.$emit('removeRow', updateThis);
      vm.config.eventRegistrations.splice(thisKey, 1); */

      vm.closeRemoveReg();
    },
/**
* Edit Registrations Paid status
*/
    showEditPaid: function(regId){
      let vm = this;
      if(regId){
        let thisRegistrant = vm.config.eventRegistrations.find(function (obj) { return obj.id == regId });
        if(thisRegistrant){
          vm.$set(vm.modals.paid,'registrant',thisRegistrant);
          vm.$set(vm.modals.paid,'regId',regId);
          vm.$set(vm.modals.paid,'show',true);
        }
      }
    },
    closeEditPaid: function(){
      let vm = this;
      vm.$set(vm.modals.paid,'show',false);
      vm.$set(vm.modals.paid,'registrant',null);
      vm.$set(vm.modals.paid,'regId',null);
    },
    confirmEditPaid: function(regId){
      let vm = this;
      // Mark as Paid
      //console.log(`Confirm Paid: ${regId}`);
      // Update Registrant in registrations
      let thisKey = vm.config.eventRegistrations.indexOf( vm.config.eventRegistrations.find(function (obj) { return obj.id == regId }) );
      let thisRegistrant = vm.config.eventRegistrations[thisKey];

      let data = {
        eventRegistrationId: regId,
        paymentType: thisRegistrant.payment_type_selected,
        amount: thisRegistrant.total_price
      }
      //console.log(thisRegistrant);
      //Preform Axios Submmision
      this.axios.post('/event-registration/admin/mark-paid', data)
      .then( response => {
        //console.log(response);
        //console.log(`%c Paid Updated`,'color: green');
        if(thisRegistrant){
          vm.$set(thisRegistrant,'paid',1);
          let updateThis = {
            key: thisKey,
            id: vm.config.eventRegistrations[thisKey].id
          }
          console.log(updateThis);
          Bus.$emit('updateRegistrations', updateThis);
        }else{
            console.log(`Failed`)
        }
      }).catch( error => {
        console.log(error);
        console.log(`%c Paid Failed`,'color: red')
      });
      vm.closeEditPaid();
    },
/**
* Send Payment Reminder
*/
    paymentReminderShow: function(){
      let vm = this;
      vm.$set(vm.modals,'paymentreminder',true);
    },
    paymentReminderClose: function(){
      let vm = this;
      vm.$set(vm.modals,'paymentreminder',false);
    },
/**
* Message all Registrants
*/
    notificationMesasgeShow: function(){
      let vm = this;
      vm.$set(vm.modals,'notification',true);
    },
    notificationMesasgeClose: function(){
      let vm = this;
      vm.$set(vm.modals,'notification',false);
    },
/**
* Message single Registrant
*/
    showRegistrantMessage: function(regId){
      let vm = this;

      console.log(`Contact ${regId}`);
      if(regId){
        let thisRegistrant = vm.config.eventRegistrations.find(function (obj) { return obj.id == regId });
        //console.log(thisRegistrant);
        if(thisRegistrant){
          vm.$set(vm.modals.message,'registrant',thisRegistrant);
          vm.$set(vm.modals.message,'regId',regId);
          vm.$set(vm.modals.message,'show',true);
        }
      }
    },
    registrantMesasgeClose: function(){
      let vm = this;

      vm.$set(vm.modals.message,'show',false);
      vm.$set(vm.modals.message,'regId',null);
      vm.$set(vm.modals.message,'registrant',null);
    },
/**
* Archived Messages
**/
    showThisMessege: function(messageId){
      let vm = this;
      if(messageId){
        let thisMessage = vm.emails.find(function (obj) { return obj.id == messageId });
        if(thisMessage){
          vm.$set(vm.modals.archivedMessage,'message',thisMessage);
          vm.$set(vm.modals.archivedMessage,'messageId',messageId);
          vm.$set(vm.modals.archivedMessage,'show',true);
        }
      }

    },
    closeThisMessage: function(){
      let vm = this;
      vm.$set(vm.modals.archivedMessage,'show',false);
      vm.$set(vm.modals.archivedMessage,'messageId',null);
      vm.$set(vm.modals.archivedMessage,'message',null);
    },
/**
* Date Time Format
*/
    clearFilterDates(){
      let vm = this;
      vm.$set(vm.filters,'startDate',null);
      vm.$set(vm.filters,'endDate',null);
    },
    returnYearMonth(thisDate){
      return ( Moment(thisDate).isValid() ? Moment(thisDate).format('MMM YYYY') : '');
    },
    returnDay(thisDate){
      return ( Moment(thisDate).isValid() ? Moment(thisDate).format('Do') : '')
    },
    returnDateTime(thisDate){
      return ( Moment(thisDate).isValid() ? Moment(thisDate).format('MMM Do YYYY - h:mm a') : '');
    },

    dualFullDateTime(firstDate,secondDate){
      let vm = this;
      let sameDay = false;
      // Check if both dates are same day
      //console.log(`Dual Full Datetime: ${firstDate} - ${secondDate}`);
      if( Moment(firstDate).isValid() && Moment(secondDate).isValid()){
          sameDay = ( Moment(firstDate).format('MM-DD-YYYY') == Moment(secondDate).format('MM-DD-YYYY') ? true : false);
      }

      if( sameDay ){
        let thisResult = ( Moment(firstDate).isValid() ? Moment(firstDate).format('MMMM D, YYYY | h:mm a') : '') + ( Moment(secondDate).isValid() ? ' - ' + Moment(secondDate).format('h:mm a') : '');
        return thisResult;
      }else{
        let thisResult = ( Moment(firstDate).isValid() ? Moment(firstDate).format('MMMM D, YYYY') : '')
                        + ( Moment(secondDate).isValid() ? ' - ' + Moment(secondDate).format('MMMM D, YYYY') : '')
                        + ' | '
                        + ( Moment(firstDate).isValid() ? Moment(firstDate).format('h:mm a') : '')
                        + ( Moment(secondDate).isValid() ? ' - ' + Moment(secondDate).format('h:mm a') : '');
        return thisResult;
      }
    },
    dualDateTime(firstDate,secondDate){
      let vm = this;
      let sameDay = false;
      // Check if both dates are same day
      if( Moment(firstDate).isValid() && Moment(secondDate).isValid()){
          sameDay = ( Moment(firstDate).format('MM-DD-YYYY') == Moment(secondDate).format('MM-DD-YYYY') ? true : false);
      }

      if( sameDay ){
        let thisResult = '- ' + ( Moment(firstDate).isValid() ? Moment(firstDate).format('MMMM D, YYYY') : '');
        return thisResult;
      }else{
        let thisResult = '- ' + ( Moment(firstDate).isValid() ? Moment(firstDate).format('MMMM D, YYYY') : '')
                        + ( Moment(secondDate).isValid() ? ' - ' + Moment(secondDate).format('MMMM D, YYYY') : '');
        return thisResult;
      }
    },
    singleDateTime(thisDateTime){
      let vm = this;

      return ( Moment(thisDateTime).isValid() ? Moment(thisDateTime).format('MMMM D, YYYY') : '');
    },
    singleFullDateTime(thisDateTime){
      let vm = this;

      return ( Moment(thisDateTime).isValid() ? Moment(thisDateTime).format('MMMM D, YYYY | h:mm a') : '');
    }
  },
  components:{
    Datepicker,
    'vue-loader':                 require('@vuejs/components/loader/loader.vue').default,
    'percentage-circle':          require('../widgets/percentage-circle.vue').default,
    'test-component':             require('../widgets/test-component.vue').default,
    'registrations-dataTables':   require('./registrations-datatables.vue').default,
    'messages-datatable':         require('./messages-datatable.vue').default,
    'confirm-delete':             require('./modals/confirm-delete.vue').default,
    'registrant-message':         require('./modals/send-registrant-message.vue').default,
    'send-notification':          require('./modals/send-notification.vue').default,
    'send-payment-reminder':      require('./modals/send-payment-reminder.vue').default,
    'registrant-view':            require('./modals/view-registrant.vue').default,
    'registrant-remove':          require('./modals/remove-registrant.vue').default,
    'edit-paid':                  require('./modals/view-edit-paid.vue').default,
    'view-message':               require('./modals/view-message.vue').default,
  }
}
</script>

<style lang="scss">
$break-small: 991px;
$break-medium: 992px;
.content-wrapper{
  @media screen and (max-width: $break-small) {
    padding: 30px 0;
  }
}
.event-header{
  margin-bottom: 2em;
  h1{
    margin: 0;
    @media screen and (max-width: $break-small) {
      text-align: center;
      margin-bottom: 5px;
    }
  }
  .btns{
    @media screen and (min-width: $break-medium) {
      text-align: right;
    }
    @media screen and (max-width: $break-small) {
      text-align: center;
    }
    .btn-group{
      padding-top: 2px;
      .btn{
        margin-bottom:0;
      }
    }
  }
}

.price-widget-wrapper{
  /* padding: 1em;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); */
  margin-bottom: 2em;
  .total {
    h4{
      font-size: 2em;
      margin-bottom:0;
      margin-top: 0;
      color: #26313f;
    }
    .dollar-value{
      line-height: 1em;
      text-align: right;
      font-size: 4em;
      font-weight: bold;
    }
    .actual{
      text-align: right;
      .title{
        font-size: 1.5em;
        color: #4bbca7;
        text-transform: uppercase;
      }
      .paid-value{
        text-align: right;
        font-size: 1.5em;
      }
    }
  }
}
body > .wrapper{
  overflow: visible;
}
.input-group{
  &.date-filters {
    //margin-left: 15px;
    .vdp-datepicker{
      display: table-cell;
    }
  }
}

.data-card{
  /* padding: 1em;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); */
  margin-bottom: 2em;
}
.event-details-wrapper{
  margin-bottom: 2em;
  div.add-padding {
    padding: 0 15px;
  }
  .media{
    @media screen and (max-width: $break-small) {
      display: block;
      width: 100%;
    }
    .media-left{
      @media screen and (max-width: $break-small) {
        display: none;
      }
    }
    .media-body{
      @media screen and (max-width: $break-small) {
        display: block;
        width: 100%;
      }
    }
  }
}
.reg-list-header{
  display: table;
  .sub-header,.reg-actions{
    display: table-column;
  }
}
.ticket-sales-wrpper{
  display: table;
  @media screen and (max-width: $break-small) {
    width: 100%;
  }
  .chart, .details{
    display: table-cell;
    vertical-align: middle;
  }
  .chart{
    @media screen and (min-width: $break-medium) {
      max-width: 150px;
    }
    @media screen and (max-width: $break-small) {
      width: 150px;
    }
  }
}

h3 {
  &.darker{
    color: #26313f;
  }
}

.bg-white{
  background-color: #fff;
}
.event-price {
  font-size: 1.7em;
}
.cal-item{
  width: 100px;
  border: 1px solid #ddd;
  .cal-item-header {
    text-align: center;
    padding: 5px;
    color: #fff;
    background-color: #4bbca7;
  }
  .cal-item-body {
    text-align: center;
    padding: 10px 2px;
    font-size: 1.7em;
    background-color: #fff;
  }
}
.label{
  &.event-header {
    font-size: 100%;
    line-height: 2;
  }
  &.addon-header {
    font-size: 75%;
    line-height: 2;
  }
}
.btn-group > .btn:not(:last-child){
  margin-right: 4px;
}
.m-l-2,.btn.m-l-2{
  margin-left: 2px;
}
.m-t-0{
  margin-top: 0;
}
.m-b-2e{
  margin-bottom: 2em;
}
.mb-1e{
  margin-bottom: 1em;
}

.registrants-filters{
  .input-group{
    @media screen and (max-width: $break-small) {
      display: block;
      margin: 0 15px;
    }
    &.date-filters {
      //margin-left: 15px;
      .vdp-datepicker{
        @media screen and (min-width: $break-medium) {
          display: table-cell;
        }
        @media screen and (max-width: $break-small) {
          display: block;
        }
      }
      .input-group-addon{
        @media screen and (max-width: $break-small) {
          text-align: left;
          border: none;
          background: none;
        }
      }
      .input-group-btn{
        @media screen and (max-width: $break-small) {
          display: inline-block;
          width: 100%;
          text-align: right;
        }
      }
    }
  }
}
</style>
