<template>
  <table class="table table-striped table-bordered table-condensed dataTable no-footer" style="width: 100%;">
    <thead>
      <tr>
        <th>{{ 'ID' | translate }}</th>
        <th>{{ 'Subject' | translate }}</th>
        <th>{{ 'Recipiant' | translate }}</th>
        <th>{{ 'Date Sent' | translate }}</th>
        <th class="text-right" style="width: 300px">{{ 'Actions' | translate }}</th>
      </tr>
    </thead>

    <tbody>
        <tr v-for="email in emails">
          <td>{{ email.id }}</td>
          <td>{{ email.subject }}</td>
          <td>
            <template v-if="email.group == 'registrant'">
              {{ email.registrations[0].first_name }} {{ email.registrations[0].last_name }}
            </template>
            <template v-else>
              {{ email.group }}
            </template>
          </td>
          <td>{{ email.created_at }}</td>
          <td class="text-right" style="width: 300px">
            <a @click.prevent="actions.showThisMessege(email.id)" href="#">{{ 'View Details' | translate }}</a>
          </td>
        </tr>
    </tbody>
  </table>
</template>

<script>
/**
* VueJS min boot-strap Wrapper
* in blade, section: after-scripts-end
* load:
* {!! Html::script('/js/datatables.js') !!}
* {!! Html::script('https://cdn.datatables.net/buttons/1.5.1/js/dataTables.buttons.min.js') !!}
* {!! Html::script('https://cdn.datatables.net/buttons/1.5.1/js/buttons.html5.min.js') !!}
* {!! Html::script('https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js') !!}
* {!! Html::script('https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.38/pdfmake.js') !!}
* {!! Html::script('https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.38/vfs_fonts.js') !!}
* {!! Html::script('https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.22.2/moment.min.js') !!}
*/
export default{
  props: ['config','emails','actions'],
  data(){
      return{
        dataTable: null,
        dateFormat: 'YYYY-MM-DD',
        refresh: {
          state: false,
          key: null,
          id: null
        },
        total: 0
      }
  },
  created() {

  },
  mounted(){
    let vm = this;

    /**
    * Wait for external JS components to load
    */
    //window.addEventListener('load', () => {
      vm.initilizeDataTable();
    //});
  },
  beforeDestroy() {
    const vm = this
    if (vm.dataTable) {
      vm.dataTable.destroy(true);
    }
    vm.dataTable = null
  },
  computed:{

  },
  watch:{

  },
  methods:{
    /**
    * Initilize DataTables
    */
    initilizeDataTable: function(){
      let vm = this;

      // Check if We have Registrations
      if(vm.emails.length){
        vm.dataTable = $(this.$el).DataTable( {
          dom: '<"row mdt"<"inline-block col-md-7 dt-select"<"inline-block"l><"inline-block"><"inline-block"f>><"inline-block text-right col-md-5"B">><"table-responsive"t><"row"<"col-md-6"i><"col-md-6"p>>',
          buttons: [
            {
              extend: 'pdfHtml5',
              className: 'btn btn-primary' ,
              exportOptions: {
                columns: [ 0, 1, 2, 3 ]
              },
            },
            {
              extend: 'excelHtml5',
              className: 'btn btn-primary' ,
              exportOptions: {
                columns: [ 0, 1, 2, 3 ]
              }
            },
            {
              extend: 'csvHtml5',
              className: 'btn btn-primary',
              exportOptions: {
                columns: [ 0, 1, 2, 3 ]
              },
            },
          ],
          columns: [
            { title: 'ID' },
            { title: 'Subject' },
            { title: 'Recipiant'},
            { title: 'Date Sent',render: vm.formatDate},
            { title: 'Actions', 'searchable': false, 'orderable': false,}
          ],
          stateSave: true,
          language: {
            search: "_INPUT_",
            searchPlaceholder: "Search"
          },
          lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]]
        });

        /**
        * Add title to buttons
        */
        $('.mdt .dt-buttons').prepend('<span class="export">Export to </span>');
      }else{
        //Do Nothing;
      }
    },
    /**
    * Format Date
    */
    formatDate: function(data, type, row){
      let vm = this;

      if(type === 'display'){
        switch(vm.dateFormat) {
          case 'RFC1036-MH':
            return Moment(data).format("dddd, Do MMMM YYYY")
            break;
          case 'YYYY-MM-DD':
            return Moment(data).format("YYYY-MM-DD")
            break;
          default:
            return Moment(data).format("YYYY-MM-DD")
        }
      }else{
        return data
      }
    },
  },
  components:{

  }
}
</script>

<style lang="scss">
.hide-paid{
  display: none;
}
.dt-toolbar-filter{
  margin-bottom:0;
  margin-left: 4px;
  padding: 4px 12px;
  cursor: pointer;
}
  .export.mc-export { width: auto; }
  .inline-block { display: inline-block; }
  .inline-block.pr-10 { padding-right: 10px;}
  span.export {
      float: none;
      font-weight: 800;
      padding-top: 7px;
      padding-right: 10px;
      display: inline-block;
      vertical-align: top;
      width: inherit;
  }
  label.dtexternal {
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      padding-left: 5px;
  }
  .dtexternal select {
      margin-left: 5px;
  }
  .dataTables_filter {
      float: none;
  }
  .dt-select{
    padding-top: 0;
  }
</style>
