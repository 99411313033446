/**
* Decimal data-mask
* reference: https://jsfiddle.net/mani04/bgzhw68m/
* sample usage: <datamask-decimal v-model="ruleValues.input" :decimal='2' :classes="'form-control input-lg'"></datamask-decimal>
**/
const datamaskDecimal = ('datamask-decimal', {
    props: {
      value: {
        type: [String, Number],
        default: 0
      },
      decimal: {
        type: Number,
        default: 2
      },
      classes: {
        type: String,
        default: 'form-control'
      },
    },
    template: `<input type="text" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true" :class="classes">`,
    data: function() {
        return {
            isInputActive: false
        }
    },
    computed: {
        displayValue: {
            get: function() {
                let vm = this

                if (vm.isInputActive) {
                    // Cursor is inside the input field. unformat display value for user
                    if ( vm.value ) {
                      return vm.value.toString()
                    }
                } else {
                    // User is not modifying now. Format display value for user interface
                    // if ( vm.value ) {
                        return vm.value.toFixed(vm.decimal).replace(/[^\d\.]/g, "")
                    // }
                }
            },
            set: function(modifiedValue) {
                let vm = this

                let fixedValue = parseFloat(modifiedValue).toFixed(vm.decimal)

                let tmpValue = parseFloat(fixedValue.replace(/[^\d\.]/g, ""))
                //let tmpValue = modifiedValue.parseFloat().replace(/[^\d\.]/g, "")

                // Check is not NAN
                if (isNaN(tmpValue)) {
                    tmpValue = 0
                }
                vm.$emit('input', tmpValue)
            }
        }
    }
});

export default datamaskDecimal;
