<template>
<div class="panel panel-default">
  <div class="panel-heading">{{ 'My Tickets' | translate }}</div>
  <div class="panel-body my-tickets">
    <vue-loader :show="loading.isloading" :message="loading.message" :overlay="false"></vue-loader>
    <div v-if="!loading.isloading && tickets.length" class="list-group">
      <a v-for="ticket in tickets" v-on:click.stop.prevent href="#" class="list-group-item">
        <h3 class="list-group-item-heading">{{ ticket.event.name }}</h3>
        <h5 v-if="ticket.event.venue && ticket.event.venue.name">{{ ticket.event.venue.name }}</h5>
        <address v-if="ticket.event.venue">
            {{ ticket.event.venue.street1 }}{{ (ticket.event.venue.street2 ? ', ' + ticket.event.venue.street2 : '') }}<br>
            {{ ticket.event.venue.city }} {{ ticket.event.venue.state }}, {{ (ticket.event.venue.country ? ticket.event.venue.country.name : '' ) }} {{ ticket.event.venue.zip }}
        </address>
        <h5>{{ 'Ticket Details' | translate }}</h5>
        <p class="list-group-item-text">
          <dl class="dl-horizontal">
            <dt>{{ ticket.price.name }}</dt>
            <dd>
              {{ returnDate(ticket.price.starts_at) }}
              {{ (ticket.price.ends_at && (ticket.price.ends_at !== ticket.price.starts_at) ? 'to ' + returnDate(ticket.price.ends_at) : '' ) }}
            </dd>
          </dl>
          <dl v-for="addon in ticket.addons" class="dl-horizontal">
            <dt>{{ addon.name }}</dt>
            <dd>
              {{ returnDate(addon.starts_at) }}
              {{ (addon.ends_at && (addon.ends_at !== addon.starts_at) ? 'to ' + returnDate(addon.ends_at) : '' ) }}
            </dd>
          </dl>
        </p>
      </a>
    </div>
    <p v-if="!loading.isloading && !tickets.length">
      {{ 'No tickets found' | translate }}
    </p>
  </div>
</div>
</template>

<script>
/**
* Add anywhere to logged in page, will return member tickets in panel
<div data-vue="event-my-tickets">
    <event-my-tickets></event-my-tickets>
</div>
*/
export default{
  //props: [],
  data(){
      return{
        tickets: [],
        loading: {
          isloading: false,
          message: 'Loading My Tickets...'
        },
      }
  },
  created() {

  },
  mounted(){
    this.fetchMyTickets();
  },
  computed:{

  },
  watch:{

  },
  methods:{
  /**
  * Get my Tickets
  */
    fetchMyTickets: _.throttle( function () {
      let vm = this
      vm.$set(vm.loading,'isloading',true);
      // Get Actual data via axios
      vm.axios.get('/event/my-tickets')
      .then( response => {
        console.log(response.data);
        vm.tickets = response.data;
        vm.$set(vm.loading,'isloading', false);
      }).catch( error => {
        console.log('Error')
        vm.$set(vm.loading,'isloading', false);
        // Preform Error Function
      });
    },400 ),
  /**
  * Date
  */
    returnDate(thisDate){
      return ( Moment(thisDate).isValid() ? Moment(thisDate).format('MMM Do YYYY - h:mm a') : '');
    },
  },
  components:{
    'vue-loader':                 require('@vuejs/components/loader/loader.vue').default,
  }
}
</script>

<style lang="scss">
.panel-body{
  &.my-tickets {
    padding:0;
    .list-group{
      margin-bottom:0;
    }
  }
}
</style>
