<template>
    <object id="parent-element" class="email-edit-display" type="html">
      <html>
        <head>
          <meta name="viewport" content="width=device-width">
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
          <title>{{ content.subject }}</title>
        </head>
        <body class="" style="background-color: #f6f6f6; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
          <table border="0" cellpadding="0" cellspacing="0" class="body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; background-color: #f6f6f6;">
            <tr>
              <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;">&nbsp;</td>
              <td class="container" style="font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; Margin: 0 auto; max-width: 580px; padding: 10px; width: 580px;">
                <div class="content" style="box-sizing: border-box; display: block; Margin: 0 auto; max-width: 580px; padding: 10px;">

                  <!-- START CENTERED WHITE CONTAINER -->
                  <span class="preheader" style="color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;">This is preheader text. Some clients will show this text as a preview.</span>
                  <table class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; background: #ffffff; border-radius: 3px;">
                    <tr>
                      <td>
                        <div style="text-align: center; background-color: #4bbca7; width: 100%; height: 50px; padding-top: 10px;">
                          <img src="/images/nscrt-logo.png" alt="NSCRT logo" />
                        </div>
                      </td>
                    </tr>
                    <!-- START MAIN CONTENT AREA -->
                    <tr>
                      <td class="wrapper" style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;">
                        <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;">
                          <tr>
                            <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;">
                              <p style="font-family: sans-serif; font-size: 18px; font-weight: normal; margin: 0; margin-bottom: 15px;">Hi { registrant name },</p>
                              <br>
                              <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 50px;" v-html="(content.body ? content.body : loremIpsum )"></p>
                              <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">
                                <hr style="border-bottom: 1px solid #999999;">
                                <div style="margin-bottom: 15px;">
                                  <h2 style="font-weight: bold;">Pay By Cheque</h2>
                                  <p>Please make your cheque out to <strong style="font-weight: bold;">{{ registrar['long_name'] }}</strong> and <strong style="font-weight: bold;">include your event registration number</strong>.</p>
                                  <p>
                                    Mail to:<br>
                                    {{ registrar['address'] }},
                                    <br/>{{ registrar['city'] }}, {{ registrar['province'] }}
                                    <br/>{{ registrar['postal_code'] }}
                                  </p>
                                </div>
                                <div style="margin-bottom: 15px;">
                                  <h2 style="font-weight: bold;">Pay By Money Order</h2>
                                  <p>Please send your money order to the following address and <strong style="font-weight: bold;">include your event registration number</strong>:</p>
                                  <p>
                                    {{ registrar['long_name'] }}<br>
                                    {{ registrar['address'] }},
                                    <br/>{{ registrar['city'] }}, {{ registrar['province'] }}
                                    <br/>{{ registrar['postal_code'] }}
                                  </p>
                                </div>
                                <div style="margin-bottom: 15px;">
                                  <h2 style="font-weight: bold;">Pay By eTransfer</h2>
                                  <p>
                                    Please send your eTransfer to <strong style="font-weight: bold;">{{ registrar['admin_email'] }}</strong> and use the password: <strong style="font-weight: bold;">{{ registrar['eTransfer_password'] }}</strong><br/>
                                    Please <strong style="font-weight: bold;">include your event registration number</strong> in the message box if available.
                                  </p>
                                </div>
                              </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                  <!-- END MAIN CONTENT AREA -->
                  </table>

                  <!-- START FOOTER -->
                  <div class="footer" style="clear: both; Margin-top: 10px; text-align: center; width: 100%;">
                    <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;">
                      <tr>
                        <td class="content-block" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; font-size: 12px; color: #999999; text-align: center;">
                          <span class="apple-link" style="color: #999999; font-size: 12px; text-align: center;">{{ registrar['long_name'] }}</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!-- END FOOTER -->

                <!-- END CENTERED WHITE CONTAINER -->
                </div>
              </td>
              <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;">&nbsp;</td>
            </tr>
          </table>
        </body>
      </html>
    </object>
</template>

<script>
export default{
  /**
  * Media types: http://www.iana.org/assignments/media-types/media-types.xhtml
  */
  props: ['content', 'registrar'],
  data(){
      return{
          loremIpsum: `<p>Start typing to see the body of your email here...</p>`
      }
  },
  created() {

  },
  mounted(){

  },
  computed:{

  },
  watch:{

  },
  methods:{

  },
  components:{

  }
}
</script>

<style lang="scss">
/* selector for element and children */
#parent-element,#parent-element *,#parent-element a:hover,#parent-element a:visited,#parent-element a:active{
	background:none;
	border:none;
	bottom:auto;
	clear:none;
	cursor:default;
	/* didn't really know what the default for display should be*/
	/*display:inline;*/
	float:none;
	font-family:Arial, Helvetica, sans-serif;
	font-size:medium;
	font-style:normal;
	font-weight:normal;
	height:auto;
	left:auto;
	letter-spacing:normal;
	line-height:normal;
	max-height:none;
	max-width:none;
	min-height:0;
	min-width:0;
	overflow:visible;
	position:static;
	right:auto;
	text-align:left;
	text-decoration:none;
	text-indent:0;
	text-transform:none;
	top:auto;
	visibility:visible;
	white-space:normal;
	width:auto;
	z-index:auto;
  html{
    /* -------------------------------------
        INLINED WITH htmlemail.io/inline
    ------------------------------------- */
    /* -------------------------------------
        RESPONSIVE AND MOBILE FRIENDLY STYLES
    ------------------------------------- */
    @media only screen and (max-width: 620px) {
      table[class=body] h1 {
        font-size: 28px !important;
        margin-bottom: 10px !important;
      }
      table[class=body] p,
            table[class=body] ul,
            table[class=body] ol,
            table[class=body] td,
            table[class=body] span,
            table[class=body] a {
        font-size: 16px !important;
      }
      table[class=body] .wrapper,
            table[class=body] .article {
        padding: 10px !important;
      }
      table[class=body] .content {
        padding: 0 !important;
      }
      table[class=body] .container {
        padding: 0 !important;
        width: 100% !important;
      }
      table[class=body] .main {
        border-left-width: 0 !important;
        border-radius: 0 !important;
        border-right-width: 0 !important;
      }
      table[class=body] .btn table {
        width: 100% !important;
      }
      table[class=body] .btn a {
        width: 100% !important;
      }
      table[class=body] .img-responsive {
        height: auto !important;
        max-width: 100% !important;
        width: auto !important;
      }
    }
    /* -------------------------------------
        PRESERVE THESE STYLES IN THE HEAD
    ------------------------------------- */
    @media all {
      .ExternalClass {
        width: 100%;
      }
      .ExternalClass,
            .ExternalClass p,
            .ExternalClass span,
            .ExternalClass font,
            .ExternalClass td,
            .ExternalClass div {
        line-height: 100%;
      }
      .apple-link a {
        color: inherit !important;
        font-family: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
        text-decoration: none !important;
      }
      .btn-primary table td:hover {
        background-color: #34495e !important;
      }
      .btn-primary a:hover {
        background-color: #34495e !important;
        border-color: #34495e !important;
      }
    }
  }

  // Editor Things
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-justify{
    text-align: justify;
  }
}

.email-edit-display{
  all: initial;
  display: block;
  width: 100%;
  //min-height: 500px;
  background: #fff;
  // Content

}
</style>
